import { authStatusAction, forgotPasswordAction } from '../actions';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import authService from '../../../services/auth.service';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';

interface ForgotPasswordThunkPayload {
  email: string;
}

const forgotPasswordThunk = (payload: ForgotPasswordThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        authStatusAction({
          forgotPasswordStatus: StatusEnum.running,
          error: null,
        })
      );
      const response = await authService.forgotPassword(payload.email);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          authStatusAction({ forgotPasswordStatus: StatusEnum.success })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        authStatusAction({
          forgotPasswordStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default forgotPasswordThunk;
