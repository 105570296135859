import axiosInstance from "../api";
import { GetTechStacks } from "../constants/api";

class TechStackService {
    getTechStacks = async () => {
      return await axiosInstance.get(GetTechStacks());
    }
    
  }
  
  export default new TechStackService();