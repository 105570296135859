import React from 'react';
import { Icon } from '@chakra-ui/react';

export const DashboardIcon = (props: any) => (
  <Icon
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    fill='#758391'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.083 20.82C11.083 21.46 10.493 21.94 9.87301 21.8C5.40301 20.8 2.08301 16.79 2.08301 12C2.08301 7.20995 5.40301 3.19995 9.87301 2.19995C10.493 2.05995 11.083 2.53995 11.083 3.17995V20.82ZM13.113 3.17995V9.98995C13.113 10.54 13.563 10.99 14.113 10.99H20.903C21.543 10.99 22.023 10.4 21.883 9.76995C21.033 6.00995 18.083 3.04995 14.333 2.19995C13.703 2.05995 13.113 2.53995 13.113 3.17995ZM13.113 20.82V14.01C13.113 13.46 13.563 13.01 14.123 13H20.913C21.543 13 22.033 13.6 21.893 14.22C21.043 17.98 18.093 20.95 14.333 21.8C13.703 21.94 13.113 21.46 13.113 20.82Z'
    />
    <defs>
      <rect width='24' height='24' />
    </defs>
  </Icon>
);
