export interface ResetPasswordRouteParams {
  token: string;
  email: string;
}

export interface SuccessForgotRouteParams {
  email: string;
}

// HOME
export const HomeRoute: string = '/';
export const DashboardRoute: string = '/dashboard/new';
export const DashboardNewRoute: string = '/dashboard/new';
export const DashboardOldRoute: string = '/dashboard/old';
export const DashboardPotentialTechnologiesRoute: string =
  '/dashboard/potential-technologies';
export const DashboardBiddedRoute: string = '/dashboard/bidded';
export const DashboardDeclinedRoute: string = '/dashboard/declined';
export const DashboardTechSupportRoute: string = '/dashboard/tech-support';
export const DashboardSqlRoute: string = '/dashboard/sql';
export const DashboardAnswersRoute: string = '/dashboard/answer-received';
export const UserManagementRoute: string = '/user-management';
export const StatisticRoute: string = '/statistic';

// AUTH
export const SignInRoute: string = '/signin';
export const ForgotPasswordRoute: string = '/forgot-password';
export const SuccessForgotPasswordRoute = {
  template: `/success-forgot-password/:email`,
  format: ({ email }: SuccessForgotRouteParams): string =>
    `/success-forgot-password/${email}`,
};
export const SuccessResetPasswordRoute: string = '/success-reset-password';
export const ResetPasswordRoute = {
  template: `/reset-password`,
  format: ({ token, email }: ResetPasswordRouteParams): string =>
    `/reset-password/${token}/${email}`,
};
// PROFILE
export const ProfileRoute: string = '/profile';
// SETTINGS
export const SettingsRoute: string = '/settings';
export const SettingsChannelsRoute: string = '/settings/channels';
