import { authStatusAction, signInAction } from '../actions';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import AuthService from '../../../services/auth.service';
import LocalStorageService from '../../../services/local-storage.service';
import { UserRoleEnum } from '../../../core/enums';

const signOutThunk = (): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    LocalStorageService.clearLocalStorage();
    dispatch(
      authStatusAction({
        signInStatus: 'initial',
        forgotPasswordStatus: 'initial',
        resetPasswordStatus: 'initial',
      })
    );
  };
};

export default signOutThunk;
