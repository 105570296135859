import { chakra, useCheckbox, Flex, Text, Box } from '@chakra-ui/react';
import React from 'react';

const CustomCheckbox = (props: any): JSX.Element => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);

  return (
    <chakra.label
      display='flex'
      flexDirection='row'
      alignItems='center'
      gridColumnGap={2}
      maxW='40'
      // px={3}
      // py={1}
      cursor='pointer'
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        alignItems='center'
        justifyContent='center'
        // border='2px solid'
        // borderColor='green.500'
        w={4}
        h={4}
        {...getCheckboxProps()}
      >
        <Box
          w='15px'
          h='15px'
          borderRadius='50%'
          border={`4px solid ${state.isChecked ? props.color : '#758391'}`}
          // bg={state.isChecked ? props.color : 'gray.200'}
        />
      </Flex>
      {props.children}
    </chakra.label>
  );
};

export default CustomCheckbox;
