import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import DashboardPotentialTechnologiesContainer from '../containers/dashboard-potential-technologies';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const DashboardPotentialTechnologiesPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <DashboardPotentialTechnologiesContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default DashboardPotentialTechnologiesPage;
