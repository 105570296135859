import { StatusEnum } from '../../core/enums';

export const SIGN_IN: string = 'SIGN_IN';
export const FORGOT_PASSWORD: string = 'FORGOT_PASSWORD';
export const RESET_PASSWORD: string = 'RESET_PASSWORD';
export const AUTH_STATUS: string = 'AUTH_STATUS';

export interface AuthState {
  signInStatus: StatusEnum;
  forgotPasswordStatus: StatusEnum;
  resetPasswordStatus: StatusEnum;
  error: any;
}

export interface AuthStatusAction {
  type: typeof AUTH_STATUS;
  payload: AuthStatusActionPayload;
}
export interface AuthStatusActionPayload {
  signInStatus?: any;
  forgotPasswordStatus?: any;
  resetPasswordStatus?: any;
  error?: any;
}

export interface SignInAction {
  type: typeof SIGN_IN;
  payload: SignInActionPayload;
}
export interface SignInActionPayload {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface ResetPasswordAction {
  type: typeof RESET_PASSWORD;
  payload: ResetPasswordActionPayload;
}
export interface ResetPasswordActionPayload {
  password: string;
  token: string;
}

export interface ForgotPasswordAction {
  type: typeof FORGOT_PASSWORD;
  payload: ForgotPasswordActionPayload;
}
export interface ForgotPasswordActionPayload {
  email: string;
}
