import {
  getChannelsAction,
  setChannelStoreStatusAction,
  updateChannelAction,
  updateChannelStatusAction,
} from '../actions';
import ChannelService from '../../../services/channel.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { ChannelModel } from '../../../models/channel';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';
import { sleep } from '../../../utils';

const updateChannelThunk = (channel: ChannelModel): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setChannelStoreStatusAction({
          updateChannelStatus: StatusEnum.running,
          error: null,
        })
      );
      const response = await ChannelService.updateChannel(channel);
      if (response.status === 200 || response.status === 201) {
        const newChannels = getState().channelStore.channels.map((ch: any) => {
          if (ch.id === channel.id) {
            return { ...ch, ...channel };
          }
          return ch;
        });
        dispatch(getChannelsAction({ channels: newChannels }));
        dispatch(
          setChannelStoreStatusAction({
            updateChannelStatus: StatusEnum.success,
          })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setChannelStoreStatusAction({
          updateChannelStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default updateChannelThunk;
