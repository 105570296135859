import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import ProfileContainer from '../containers/profile';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const ProfilePage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <ProfileContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default ProfilePage;
