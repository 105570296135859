import React from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useColorModeValue,
} from '@chakra-ui/react';
import { getFormatedDate, getTimeColor, getTimeOrDate } from '../utils';
import { ReceivedStatisticModel, StatisticModel } from '../models/statistic';

interface StatisticTableProps {
  stats: ReceivedStatisticModel[];
}

const StatisticReceivedTable = ({
  stats,
}: StatisticTableProps): JSX.Element => {
  const bg = useColorModeValue('#FFFFFF', '#252525');
  const otherColor = useColorModeValue('#5755FF', '#9b99ff');
  const headBg = useColorModeValue('#F5F5FF', '#222222');
  const textColor = useColorModeValue('#354052', '#FFFFFF');
  const borderColor = useColorModeValue('#e4dfdf', '#1a1818');
  return (
    <TableContainer>
      <Table variant='unstyled'>
        <Thead backgroundColor={headBg} height='56px'>
          <Tr color={otherColor} fontSize='16px' fontWeight='400'>
            <Th textAlign='left'></Th>
            <Th>Data</Th>
            <Th>Total</Th>
            <Th>Viewed Posts</Th>
            <Th>Bidded</Th>
            <Th>Declined</Th>
            <Th>Tech Support</Th>
            <Th>Answer Received</Th>
          </Tr>
        </Thead>
        <Tbody>
          {stats.map((stat) => {
            return (
              <Tr
                key={stat.date}
                fontSize='14px'
                lineHeight='18px'
                fontWeight='400'
                color={textColor}
                backgroundColor={bg}
                borderBottom={`1px solid ${borderColor}`}
              >
                <Td></Td>
                <Td>{getFormatedDate(stat?.date)}</Td>
                <Td>{stat?.total}</Td>
                <Td>{stat?.viewedPosts}</Td>
                <Td>{stat?.bidded}</Td>
                <Td>{stat?.declined}</Td>
                <Td>{stat?.techSupport}</Td>
                <Td>{stat?.answerRecieved}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default StatisticReceivedTable;
