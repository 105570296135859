import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  useColorModeValue,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Badge,
  useDisclosure,
  Box,
  Tfoot,
  Text,
} from '@chakra-ui/react';
import { ActiveIcon, EditIcon, VerticalDotsIcon } from '../../icons';
import { ChannelModel } from '../../models/channel';
import EditChannelPopup from './../EditChannelPopup';
import { useDispatch, useSelector } from 'react-redux';
import updateChannelThunk from '../../store/channel/thunk/update-channel';
import { getChannelStoreSelector } from '../../store/channel/selector';
import { StatusEnum } from '../../core/enums';
import { setChannelStoreStatusAction } from '../../store/channel/actions';
import Loading from '../Loading';

interface UsersTableProps {
  channels: ChannelModel[];
  isLoading: boolean;
}

const ChannelTable = ({
  channels,
  isLoading,
}: UsersTableProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState<any>(null);
  const bg = useColorModeValue('#FFFFFF', '#252525');
  const headBg = useColorModeValue('#F5F5FF', '#222222');
  const otherColor = useColorModeValue('#5755FF', '#9b99ff');
  const textColor = useColorModeValue('#354052', '#FFFFFF');
  const borderColor = useColorModeValue('#E6EAEE', '#1A202C');

  const { addChannelStatus, updateChannelStatus } = useSelector(
    getChannelStoreSelector
  );

  const handleEditUser = (channel: any) => (event: any) => {
    event.stopPropagation();
    dispatch(
      setChannelStoreStatusAction({ updateChannelStatus: StatusEnum.initial })
    );
    setSelected(channel);
    onOpen();
  };

  const handleCancelEdit = () => {
    setSelected(null);
    onClose();
  };
  const handleChangeChannelStatus = (channel: any) => (event: any) => {
    event.stopPropagation();
    dispatch(updateChannelThunk({ ...channel, isActive: !channel.isActive }));
  };

  const loading =
    addChannelStatus === StatusEnum.running ||
    updateChannelStatus === StatusEnum.running;

  return (
    <Box position='relative'>
      <Box h='calc(100vh - 60px - 120px )' overflowY='auto'>
        <TableContainer>
          <Table size='sm' variant='unstyled' backgroundColor={bg}>
            <Thead backgroundColor={headBg} height='56px'>
              <Tr color={otherColor}>
                <Th>No</Th>
                <Th>Title</Th>
                <Th textAlign='center'>Status</Th>
                <Th textAlign='right'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && (
                <Tr>
                  <Td colSpan={4}>
                    <Loading />
                  </Td>
                </Tr>
              )}
              {!isLoading && (
                <>
                  {channels.map((channel, index) => {
                    return (
                      <Tr
                        key={channel.id}
                        fontSize='14px'
                        lineHeight='18px'
                        fontWeight='400'
                        color={textColor}
                        borderBottom={`2px solid ${borderColor}`}
                        cursor='pointer'
                      >
                        <Td>{index + 1}</Td>
                        <Td>{channel.title}</Td>
                        <Td textAlign='center'>
                          <Badge
                            borderRadius='10px'
                            p='2px 8px'
                            colorScheme={channel.isActive ? 'green' : 'red'}
                          >
                            {channel.isActive ? 'Active' : 'Inactive'}
                          </Badge>
                        </Td>
                        <Td textAlign='right'>
                          <Menu id={channel.id}>
                            <MenuButton
                              size='sm'
                              as={IconButton}
                              disabled={loading}
                              icon={<VerticalDotsIcon />}
                              bg='transparent'
                            />
                            <MenuList>
                              <MenuItem
                                icon={<EditIcon />}
                                onClick={handleEditUser(channel)}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                icon={
                                  <ActiveIcon
                                    fill={
                                      channel.isActive ? '#feb2b2' : '#9ae6b4'
                                    }
                                  />
                                }
                                onClick={handleChangeChannelStatus(channel)}
                              >
                                {channel.isActive ? 'Inactive' : 'Active'}
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </Tbody>
            {!isLoading && channels.length === 0 && (
              <Tfoot>
                <Tr>
                  <Td colSpan={4} textAlign='center'>
                    <Text>Not found</Text>
                  </Td>
                </Tr>
              </Tfoot>
            )}
          </Table>
          <EditChannelPopup
            isOpen={isOpen}
            onClose={handleCancelEdit}
            channel={selected}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ChannelTable;
