import React from 'react';
import { Icon } from '@chakra-ui/react';

export const AccountIcon = (props: any) => (
  <Icon
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    fill='#758391'
    {...props}
  >
    <mask
      id='mask0_75_1316'
      maskUnits='userSpaceOnUse'
      x='2'
      y='2'
      width='20'
      height='20'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM6 15.98C7.29 17.92 9.5 19.2 12 19.2C14.5 19.2 16.71 17.92 18 15.98C17.97 13.99 13.99 12.9 12 12.9C10 12.9 6.03 13.99 6 15.98Z'
      />
    </mask>
    <g mask='url(#mask0_75_1316)'>
      <rect width='24' height='24' />
    </g>
  </Icon>
);
