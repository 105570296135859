// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  fonts: {
    heading: `'Encode Sans', sans-serif`,
    body: `'Encode Sans', sans-serif`,
  },
  components: {
    MultiSelect: MultiSelectTheme
  }
};

// 3. extend the theme
const theme = extendTheme(config);

export default theme;
