import React from 'react';
import { Button, FormControl, Input, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthStoreSelector } from '../store/auth/selector';
import { StatusEnum } from '../core/enums';
import forgotPasswordThunk from '../store/auth/thunk/forgot-password';
import { Field, Form, Formik } from 'formik';
import { validateEmail } from '../validations';
import FormErrorMessage from './FormErrorMessage';

interface ForgotPasswordFormProps {
  setForgotPasswordEmail: (value: any) => void;
}

const ForgotPasswordForm = ({
  setForgotPasswordEmail,
}: ForgotPasswordFormProps): JSX.Element => {
  const dispatch = useDispatch();

  const { forgotPasswordStatus } = useSelector(getAuthStoreSelector);

  const handleSubmit = (values: any) => {
    dispatch(forgotPasswordThunk({ email: values.email }));
    setForgotPasswordEmail(values.email);
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      <Form>
        <Field name='email' validate={validateEmail}>
          {({ field, form }: any) => (
            <FormControl
              id='newPassword'
              isInvalid={form.errors.email && form.touched.email}
              marginBottom='30px'
            >
              <Input
                {...field}
                placeholder='your-email@example.com'
                _placeholder={{ color: '#758391' }}
                type='email'
              />

              {form.errors.email && (
                <FormErrorMessage message={form.errors.email} />
              )}
            </FormControl>
          )}
        </Field>

        <Stack spacing={6}>
          <Button
            type='submit'
            disabled={forgotPasswordStatus === StatusEnum.running}
            isLoading={forgotPasswordStatus === StatusEnum.running}
            loadingText='Wait...'
            size='lg'
            bg={'#5755FF'}
            color={'white'}
            _hover={{
              bg: '#3e3dd2',
            }}
          >
            Request
          </Button>
        </Stack>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
