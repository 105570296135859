import { getChannelsAction, setChannelStoreStatusAction } from '../actions';
import ChannelService from '../../../services/channel.service';
import { channels } from '../../../lib/channels';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { sleep } from '../../../utils';

const getAllChannelsThunk = (): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(setChannelStoreStatusAction({ status: 'running', error: null }));
      const response = await ChannelService.getAllChannels();
      if (response.status === 200 || response.status === 201) {
        dispatch(getChannelsAction({ channels: response.data.channels }));
        dispatch(setChannelStoreStatusAction({ status: 'success' }));
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setChannelStoreStatusAction({
          status: 'error',
          error: error.response.data,
        })
      );
    }
  };
};

export default getAllChannelsThunk;
