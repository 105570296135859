import { StatusEnum } from '../../core/enums';
import { ChannelModel, CreateChannelModel } from '../../models/channel';

export const SET_COMMENT_STORE_STATUS: string = 'SET_COMMENT_STORE_STATUS';
export const ADD_COMMENT: string = 'ADD_COMMENT';
export const GET_COMMENTS: string = 'GET_COMMENTS';

export interface CommentsState {
  addCommentStatus: StatusEnum;
  getCommentsStatus: StatusEnum;
  error: null;
  comments: any[];
}

export interface SetCommentStoreStatusAction {
  type: typeof SET_COMMENT_STORE_STATUS;
  payload: SetCommentStoreStatusActionPayload;
}

export interface SetCommentStoreStatusActionPayload {
  addCommentStatus?: string;
  getCommentsStatus?: StatusEnum;
  error?: any;
}

export interface AddCommentAction {
  type: typeof ADD_COMMENT;
  payload: AddCommentActionPayload;
}
export interface AddCommentActionPayload {
  comment: any;
}

export interface GetCommentsAction {
  type: typeof GET_COMMENTS;
  payload: GetCommentsActionPayload;
}
export interface GetCommentsActionPayload {
  comments: any[];
}
