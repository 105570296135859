
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootStore } from "../.."
import { StatusEnum } from "../../../core/enums";
import { PostModel } from "../../../models/post";
import PostService from '../../../services/post.service';
import { setGeneratedAnswerPostStoreAction, setGeneratedAnswerPostStoreStatusAction } from "../actions";

const generateAnswerThunk = (postId: string): any => {
    return async (dispatch: Dispatch, getState: () => RootStore) => {
        try {
            dispatch(
                setGeneratedAnswerPostStoreStatusAction({
                    generateAnswerStatus: StatusEnum.running,
                    error: null,
                })
            );
            const response = await PostService.getJobResponseMessage(postId);
            if (response.status === 200 || response.status === 201) {
                dispatch(
                    setGeneratedAnswerPostStoreStatusAction({ generateAnswerStatus: StatusEnum.success })
                );
                const posts = (getState().postStore.posts as PostModel[]);

                const postIndex = posts.findIndex(post => post.id === postId);
                if (postIndex !== -1) {
                    posts[postIndex].generatedAnswer = response.data;
                    dispatch(
                        setGeneratedAnswerPostStoreAction({
                            posts: posts
                        })
                    )
                }
            }

        } catch (error: AxiosError | any) {
            dispatch(
                setGeneratedAnswerPostStoreStatusAction({
                    generateAnswerStatus: StatusEnum.error,
                    error: error.response.data,
                })
            );
        }
    }
}

export default generateAnswerThunk;