import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../core/enums';
import { setCommentStoreStatusAction } from '../store/comment/actions';
import { getCommentStoreSelector } from '../store/comment/selector';
import { removeQuotesFromString } from '../utils';

const useCommentsToast = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { addCommentStatus, error } = useSelector(getCommentStoreSelector);

  useEffect(() => {
    if (addCommentStatus === StatusEnum.error) {
      if (error) {
        toast({
          position: 'top-right',
          status: 'error',
          title: 'Action error',
          description: removeQuotesFromString(error),
        });
      } else {
        toast({
          position: 'top-right',
          status: 'error',
          title: 'Action error',
          description: 'Tranfer error',
        });
      }
    }
    if (addCommentStatus === StatusEnum.running) {
      toast({
        position: 'top-right',
        status: 'info',
        description: 'Adding comment...',
      });
    }
    if (addCommentStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Added comment!',
      });
    }
    return () => {
      dispatch(
        setCommentStoreStatusAction({
          addCommentStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [error, toast, addCommentStatus, dispatch]);

  useEffect(() => {
    dispatch(
      setCommentStoreStatusAction({
        addCommentStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        setCommentStoreStatusAction({
          addCommentStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useCommentsToast;
