import React from 'react';
import { Flex, Stack, Box, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { SignInRoute } from '../constants/routes';
import Footer from '../components/Footer';
import SuccessResetPassword from '../components/SuccessResetPassword';

const SuccessResetPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <Flex width='100%' height='40px' align={'center'} justify={'end'}>
        <Link
          pr='25px'
          color={'#5755FF'}
          onClick={() => {
            navigate(SignInRoute);
          }}
        >
          Sign in
        </Link>
      </Flex>
      <Flex
        minH={'calc(100vh - 96px - 40px)'}
        align={'center'}
        justify={'center'}
      >
        <Stack spacing={8} mx={'auto'} width='100%' maxW='410px' py={12} px={6}>
          <Box rounded={'lg'} width='100%' maxW='600px'>
            <SuccessResetPassword />
          </Box>
        </Stack>
      </Flex>
      <Footer />
    </>
  );
};

export default SuccessResetPasswordContainer;
