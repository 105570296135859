import {
  getAllLeadGensAction,
  setLeadGenAction,
  setUserStoreStatusAction,
} from '../actions';
import UserService from '../../../services/user.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { StatusEnum } from '../../../core/enums';

const getAllLeadGensThunk = (): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({
          getAllLeadGensStatus: StatusEnum.running,
          error: null,
        })
      );

      const response = await UserService.getAllLeadGens();
      if (response.status === 200 || response.status === 201) {
        const ids = response.data.leadGens.map((lg: any) => lg.id);

        dispatch(setLeadGenAction({ leadgenIds: ids }));
        dispatch(getAllLeadGensAction({ leadgens: response.data.leadGens }));
        dispatch(
          setUserStoreStatusAction({ getAllLeadGensStatus: StatusEnum.success })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          getAllLeadGensStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getAllLeadGensThunk;
