import { StatusEnum } from '../../core/enums';

export const initialState = {
  status: StatusEnum.initial,
  channels: [],
  groupChannels: [],
  groupChannelsStatus: StatusEnum.initial,
  groupChannelsTotal: 0,
  statusGroups: [],
  differences: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  addChannelStatus: StatusEnum.initial,
  updateChannelStatus: StatusEnum.initial,
  error: null,
};
