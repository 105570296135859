import { isValidEmail, isValidUrl } from '../utils';

export function validateFirstName(value: any) {
  let error;
  if (!value) {
    error = 'Name is required';
  } else if (value.trim().length < 2) {
    error = 'Too short!';
  }
  return error;
}
export function validateLastName(value: any) {
  let error;
  if (!value) {
    error = 'Last Name is required';
  } else if (value.trim().length < 2) {
    error = 'Too short!';
  }
  return error;
}

export function validateEmail(value: any) {
  let error;
  if (!value) {
    error = 'Email is required';
  } else if (!isValidEmail(value.trim())) {
    error = 'Invalid email format';
  }
  return error;
}
export function validatePassword(value: any) {
  let error;
  if (!value) {
    error = 'Password is required';
  } else if (value.trim().length < 8) {
    error = 'Password length must be atleast 8 characters';
  } else if (
    !value.match(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    )
  ) {
    error =
      'Password must be 8 to 15 characters, contain at least one numeric digit and a special character';
  }
  //minimum password length validation

  return error;
}

export function validateTitle(value: any) {
  let error;
  if (!value) {
    error = 'Title is required';
  } else if (value.trim().length < 2) {
    error = 'Too short length!';
  }
  return error;
}

export function validateUrl(value: any) {
  let error;
  if (!value) {
    error = 'Url is required';
  } else if (!isValidUrl(value)) {
    error = 'Invalid Url';
  }
  return error;
}

export function validateRequired(value: any) {
  let error;
  if (!value) {
    error = `is required`;
  } else if (!Number(value) && value.trim().length < 2) {
    error = 'Too short length!';
  }
  return error;
}

export const getErrorMessage = (fieldName: string, error: string) => {
  return `${fieldName} ${error}`;
};