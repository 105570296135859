import React from 'react';
import { Box, Link, Flex, Text, Container } from '@chakra-ui/react';

const Footer = (): JSX.Element => {
  return (
    <Box as='footer' backgroundColor='#F8F9FF' height='96px'>
      <Container maxW='760px' color='white' h='100%'>
        <Flex
          alignItems='center'
          h='100%'
          justifyContent={{
            base: 'space-around',
            sm: 'space-around',
            md: 'space-between',
            lg: 'space-between',
            xl: 'space-between',
            '2xl': 'space-between',
          }}
          flexDirection={{
            base: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
            '2xl': 'row',
          }}
        >
          <Text color='#758391' fontSize='12px' lineHeight='24px'>
            RSS system — 2022, All Rights Reserved
          </Text>
          <Flex alignItems='center' justifyContent='space-between'>
            <Link>
              <Text color='#758391' fontSize='12px' lineHeight='16px' mr='12px'>
                Privacy policy
              </Text>
            </Link>
            <Link>
              <Text color='#758391' fontSize='12px' lineHeight='16px' mr='12px'>
                Terms & Conditions
              </Text>
            </Link>
            <Link>
              <Text color='#758391' fontSize='12px' lineHeight='16px'>
                Contacts
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
