import { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StatusEnum, StatusGroupEnum } from '../core/enums';
import {
  setCurrentStatusGroupTypeAction,
  setPostsChannelAction,
  setPostsDatesAction,
  setPostsPageAction,
  setPostsSortAction,
  setSearchPostsAction,
  setTakePostsAction,
  setUsePostRouteAction,
} from '../store/post/actions';
import { getPostStoreSelector } from '../store/post/selector';
import getPostsThunk from '../store/post/thunk/get-posts';
import getTechStackThunk from '../store/post/thunk/get-tech-stacks';

const usePosts = function (statusGroup: StatusGroupEnum) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [query, setQuery] = useSearchParams(location.search);

  const {
    posts,
    status,
    techStacks,
    channel,
    search,
    total,
    page,
    take,
    from,
    to,
    orderColumn,
    isAcs,
    usePostRouteKey
  } = useSelector(getPostStoreSelector);
  const loading = status === StatusEnum.running;
  const disabledButtons = loading;

  const setPage = (newPage: number) => {
    const ch = new URLSearchParams(location.search).get('channel');
    const searchParam = new URLSearchParams(location.search).get('search');
    let newQuery: any = {};
    if (ch) {
      newQuery.channel = String(ch);
    }
    if (newPage) {
      newQuery.page = String(newPage);
    }
    if (searchParam) {
      newQuery.search = searchParam;
    }

    setQuery(newQuery);

    // setQuery({ ...query, page: String(page) });

    // const params = new URLSearchParams(location.search);
    // const ch = params.get('channel');
    // if (ch) {
    //   navigate(`${location.pathname}?channel=${ch}&page=${page}`);
    // } else {
    //   navigate(`${location.pathname}?page=${page}`);
    // }
  };

  const setTake = (event: any) => {
    setPage(1);

    dispatch(setTakePostsAction({ take: +event.target.value }));
  };

  interface setSortPayload {
    isAcs?: boolean;
    orderColumn?: number;
  }

  const setSort = (values: setSortPayload) => {
    dispatch(setPostsSortAction(values));
  };

  const setSearch = (search: string) => {
    // setPage(1);
    // const params = new URLSearchParams(location.search);
    // params.append('search', search);
    // dispatch(setSearchPostsAction({ search: search }));
  };

  const setDates = ({ from, to }: any) => {
    setPage(1);
    dispatch(setPostsDatesAction({ from: from, to: to }));
  };

  useEffect(() => {
    dispatch(setCurrentStatusGroupTypeAction({currentStatusGroupType: statusGroup}));
    
    if(usePostRouteKey === location.key){
      return;
    }
    const pageParam = new URLSearchParams(location.search).get('page');
    if(!pageParam){
      setPage(1);
      dispatch(
        setPostsDatesAction({
          from: null,
          to: moment().utc(true).toISOString(),
        })
      );
      dispatch(setUsePostRouteAction({usePostRouteKey: location.key}));
      return;
    }

    const channelParam = new URLSearchParams(location.search).get('channel');
    const searchParam = new URLSearchParams(location.search).get('search');

    dispatch(setSearchPostsAction({ search: searchParam ? searchParam : '' }));
    dispatch(setPostsChannelAction({ channel: channelParam ? channelParam : null }));

    if(pageParam){
      if (+pageParam === 1 && moment(to).day !== moment().utc(true).day ) {
        dispatch(
          setPostsDatesAction({
            from: null,
            to: moment().utc(true).toISOString(),
          })
        );
      }
      dispatch(setPostsPageAction({ page: parseInt(pageParam) }));
    }

    dispatch(
      getPostsThunk({
        page: pageParam ? parseInt(pageParam) : 1,
        take: take,
        statusGroup: statusGroup,
        channelId: channelParam ? channelParam : null,
        from: from,
        to: to,
        search: searchParam ? searchParam : '',
        orderColumn: orderColumn,
        isAcs: isAcs,
      })
    );
    dispatch(setUsePostRouteAction({usePostRouteKey: location.key}));
    dispatch(getTechStackThunk());

  }, [dispatch, channel, page, take, from, to, search, orderColumn, location.key, isAcs, statusGroup]);


  return {
    posts,
    status,
    channel,
    loading,
    disabledButtons,
    page,
    techStacks,
    total,
    setPage,
    take,
    setTake,
    from,
    to,
    setDates,
    search,
    setSearch,
    isAcs,
    orderColumn,
    setSort,
  };
};

export default usePosts;
