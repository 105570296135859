import React from 'react';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Box,
} from '@chakra-ui/react';
import Comments from './Comments';
import Loading from './Loading';
import GeneratedAnswer from './GeneratedAnswer';

interface PostDetailsTabsProps {
  details: any;
  post: any;
}

const PostDetailsTabs = ({
  details,
  post,
}: PostDetailsTabsProps): JSX.Element => {
  return (
    <Tabs>
      <TabList w='345px'>
        <Tab
          _selected={{
            borderBottom: '2px solid #5755FF',
            color: '#5755FF',
          }}
        >
          Details
        </Tab>
        <Tab
          _selected={{
            borderBottom: '2px solid #5755FF',
            color: '#5755FF',
          }}
        >
          Comments
        </Tab>
        <Tab
          _selected={{
            borderBottom: '2px solid #5755FF',
            color: '#5755FF',
          }}
          width={'100%'}
        >
          Generate answer
        </Tab>
      </TabList>

      <TabPanels
        borderRadius='8px'
        boxShadow='0px 4px 10px rgba(217, 216, 255, 0.4)'
        maxHeight='100%'
        height='450px'
        overflowY='auto'
      >
        <TabPanel>
          <Box
            // width='600px'
            dangerouslySetInnerHTML={{ __html: details }}
          ></Box>
        </TabPanel>
        <TabPanel overflowY='auto'>
          <Comments postId={post.id} />
        </TabPanel>
        <TabPanel overflowY='auto'>
          <GeneratedAnswer postId={post.id} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PostDetailsTabs;
