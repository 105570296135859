import {
  addUserAction,
  getUsersAction,
  setUserStoreStatusAction,
} from '../actions';
import UserService from '../../../services/user.service';
import { channels } from '../../../lib/channels';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import { CreateUserModel, UserModel } from '../../../models/user';
import { StatusEnum, UserStatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';
import { sleep } from '../../../utils';

const addUserThunk = (newUser: CreateUserModel): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({
          addUserStatus: StatusEnum.running,
          error: null,
        })
      );

      const response = await UserService.addUser(newUser);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          setUserStoreStatusAction({ addUserStatus: StatusEnum.success })
        );
        dispatch(
          getUsersAction({
            users: getState().userStore.users.concat({
              ...newUser,
              id: response.data,
              status: UserStatusEnum.Active,
            }),
          })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          addUserStatus: StatusEnum.error,
          error: JSON.stringify(error.response.data),
        })
      );
    }
  };
};

export default addUserThunk;
