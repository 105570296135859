import React from 'react';
import { Flex, Stack, Heading, Box } from '@chakra-ui/react';

import SignInForm from '../components/SignInForm';
import Footer from '../components/Footer';
import useAuthToasts from '../hooks/useAuthToasts';

const SignInContainer = (): JSX.Element => {
  useAuthToasts();
  return (
    <>
      <Flex minH={'calc(100vh - 96px)'} align={'center'} justify={'center'}>
        <Stack spacing={8} mx={'auto'} width='100%' maxW='410px' py={12} px={6}>
          <Stack align={'start'}>
            <Heading
              fontSize={'24px'}
              lineHeight='30px'
              textAlign={'center'}
              textTransform='capitalize'
            >
              Sign in
            </Heading>
          </Stack>
          <Box rounded={'lg'} width='100%' maxW='600px'>
            <SignInForm />
          </Box>
        </Stack>
      </Flex>
      <Footer />
    </>
  );
};

export default SignInContainer;
