import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  Textarea,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { getCommentTime } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getCommentStoreSelector } from '../store/comment/selector';
import { CommentTypeEnum, StatusEnum } from '../core/enums';
import Loading from './Loading';
import addCommentThunk from '../store/comment/thunk/add-comment';
import useCommentsToast from '../hooks/useCommentsToasts';

interface CommentsProps {
  postId?: string;
}

const Comments = ({ postId }: CommentsProps): JSX.Element => {
  const dispatch = useDispatch();
  useCommentsToast();
  const [message, setMessage] = useState('');
  const { comments, getCommentsStatus, addCommenStatus } = useSelector(
    getCommentStoreSelector
  );

  const handleChangeComment = (event: any) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (messageType: number) => () => {
    dispatch(
      addCommentThunk({
        jobId: postId,
        message: message,
        messageType: messageType,
      })
    );
    setMessage('');
  };

  return (
    <Box padding='10px'>
      {getCommentsStatus === StatusEnum.running && <Loading />}
      {getCommentsStatus !== StatusEnum.running && (
        <>
          <Box>
            {comments?.map((comment: any) => {
              return <CommentItem comment={comment} key={comment.id} />;
            })}
          </Box>
          <VStack align='start'>
            <Textarea
              placeholder='Type message...'
              value={message}
              onChange={handleChangeComment}
            />
            <Button
              width='172px'
              isLoading={addCommenStatus === StatusEnum.running}
              disabled={
                addCommenStatus === StatusEnum.running ||
                message.trim().length === 0
              }
              loadingText='Wait...'
              borderRadius='8px'
              bg='#5755FF'
              color='#ffffff'
              fontSize='15px'
              fontWeight='400'
              _hover={{
                bg: '#413fe3',
              }}
              _active={{
                bg: '#5755FF',
              }}
              onClick={handleSubmit(CommentTypeEnum.Question)}
            >
              Add Question
            </Button>
            <Button
              width='172px'
              isLoading={addCommenStatus === StatusEnum.running}
              disabled={
                addCommenStatus === StatusEnum.running ||
                message.trim().length === 0
              }
              loadingText='Wait...'
              borderRadius='8px'
              bg='#5755FF'
              color='#ffffff'
              fontSize='15px'
              fontWeight='400'
              _hover={{
                bg: '#413fe3',
              }}
              _active={{
                bg: '#5755FF',
              }}
              onClick={handleSubmit(CommentTypeEnum.Answer)}
            >
              Add Answer
            </Button>
          </VStack>
        </>
      )}
    </Box>
  );
};
export default Comments;

const CommentItem = ({ comment }: any): JSX.Element => {
  const color = useColorModeValue('#354052', '#ffffff');
  return (
    <Box mb='10px'>
      <HStack align='start'>
        <Box>
          <Avatar
            size={'sm'}
            name={comment && `${comment.userName}`}
            backgroundColor='#cfceff'
            fontWeight='bold'
            color='#5755FF'
            // src={
            //   'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
            // }
          />
        </Box>

        <VStack display={{ md: 'flex' }} align='start' spacing='1px' ml='2'>
          <Text fontSize='15px' color={color} fontWeight={600}>
            {comment?.userName}
            <Text as='span' fontSize='12px' color='#758391'>
              {comment?.messageType === CommentTypeEnum.Question
                ? ', question'
                : ', answer'}
            </Text>
          </Text>
          <Text color='#758391' fontSize='12px'>
            {getCommentTime(comment?.dateCreated)}
          </Text>
          <Text fontSize='14px' fontWeight={400} color={color}>
            {comment?.message}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};
