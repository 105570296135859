import React from 'react';
import DashboardSQLContainer from '../containers/dashboard-sql';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';
import SidebarWithHeader from '../layouts/sidebar';

const DashboardSQLPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <DashboardSQLContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default DashboardSQLPage;
