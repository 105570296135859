import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useCheckboxGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { StatusGroupEnum } from '../core/enums';
import CustomCheckbox from './CustomCheckbox';

enum BarTypeEnum {
  Viewed = StatusGroupEnum.New,
  Bidded = StatusGroupEnum.Bidded,
  Declined = StatusGroupEnum.Declined,
  TechSupport = StatusGroupEnum.TechSupport,
  AnswerReceived = StatusGroupEnum.AnswerReceived,
  SQL = StatusGroupEnum.Sql,
}

const colors = ['#16BE32', '#5755FF', 'purple', 'orange', 'pink', 'yellow'];

const CustomTooltip = ({ active, payload, label }: any) => {
  const bg = useColorModeValue('#e2e8f0', '#171923');

  if (active && payload && payload.length) {
    return (
      <Box bg={bg} borderRadius='10px' padding='10px'>
        <Text>{`${payload[0].payload.fullDate}`}</Text>
        {payload?.map((item: any, index: number) => {
          return (
            <Text key={index} textTransform='capitalize'>
              {item?.name} : {item?.value}
            </Text>
          );
        })}
      </Box>
    );
  }

  return null;
};

function NewBarChart({ statusGroups }: any) {
  console.log('statusGroups: ', statusGroups);
  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: ['Danil', 'Alexander'],
  });

  const list2 = [
    { name: 'Bidded', value: StatusGroupEnum.Bidded, color: 'green' },
    { name: 'Declined', value: StatusGroupEnum.Declined, color: 'red' },
    {
      name: 'Tech Support',
      value: StatusGroupEnum.TechSupport,
      color: 'black',
    },
    {
      name: 'Answer Received',
      value: StatusGroupEnum.AnswerReceived,
      color: 'orange',
    },
    { name: 'SQL', value: StatusGroupEnum.Sql, color: 'yellow' },
  ];

  const [leadgenList, setLeadGenList] = useState<any[]>([]);

  const [radioSelect, setRadioSelect] = useState(StatusGroupEnum.Bidded);

  const handleChangeSelect = (values: any[]) => {};

  const bg = useColorModeValue('#FFFFFF', '#252525');
  const color = useColorModeValue('#111111', '#ffffff');

  const data = useMemo(() => {
    let values: any = {};
    let leadGens: any[] = [];

    statusGroups[0]?.months[0]?.leadgens.forEach((lg: any, index: number) => {
      leadGens.push({
        name: lg.name,
        value: lg.name,
        color: colors[index],
      });
    });
    setLeadGenList(leadGens);

    statusGroups.forEach((sg: any) => {
      let arr: any = [];
      sg.months.forEach((m: any) => {
        let object: any = {
          date: moment(m.date).format('MMM'),
          fullDate: moment(m.date).format('MMMM'),
        };
        m?.leadgens.forEach((leadgen: any, index: number) => {
          object[leadgen.name] = leadgen.total;
        });
        arr.push(object);
      });
      values[sg.statusGroup] = arr;
    });
    return values;
  }, [statusGroups]);

  return (
    <Flex
      align='center'
      flexWrap='wrap'
      border='1px solid #D9E2EC'
      borderRadius='10px'
      padding={{ base: '5px 10px', sm: '5px 10px' }}
    >
      <Box w='100%' maxW='840px'>
        <ResponsiveContainer width='100%' aspect={2}>
          <BarChart
            layout='vertical'
            data={data[+radioSelect]}
            margin={{
              top: 10,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid horizontal={false} vertical={true} color='#D9E2EC' />
            <XAxis type='number' />
            <YAxis type='category' dataKey='date' />
            <Tooltip
              contentStyle={{
                backgroundColor: bg,
                color: color,
                borderRadius: 10,
              }}
              itemStyle={{ color: color }}
              content={<CustomTooltip />}
              cursor={false}
            />
            {leadgenList.map((lg: any, idx: number) => {
              if (value.includes(lg.value)) {
                return (
                  <Bar
                    key={lg.value}
                    barSize={8}
                    dataKey={lg.value}
                    fill={lg.color}
                  />
                );
              }
              return null;
            })}
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box ml='30px'>
        <Box mb='20px'>
          <CheckboxGroup
            colorScheme='green'
            defaultValue={value}
            onChange={handleChangeSelect}
          >
            <Stack
              spacing={[1, 3]}
              direction={{ base: 'row', sm: 'row' }}
              flexWrap='wrap'
            >
              {leadgenList.map((item, index) => {
                return (
                  <CustomCheckbox
                    key={index}
                    value={item.value}
                    color={item.color}
                    {...getCheckboxProps({ value: item.value })}
                  >
                    <Text color={color}>{item.name}</Text>
                  </CustomCheckbox>
                );
              })}
            </Stack>
          </CheckboxGroup>
        </Box>
        <RadioGroup
          onChange={(value: string) => {
            setRadioSelect(+value);
          }}
          value={radioSelect}
        >
          <Stack direction='column' flexWrap='wrap'>
            {list2.map((item: any, index: number) => {
              return (
                <Radio key={index} value={item.value}>
                  {item.name}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </Box>
    </Flex>
  );
}

export default NewBarChart;
