import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';

import { rootStore } from '../store';
import theme from '../theme';
import AppRoutes from '../routes';
import Fonts from '../fonts';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Provider store={rootStore}>
        <AppRoutes />
      </Provider>
    </ChakraProvider>
  );
}

export default App;
