import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import SettingsChannelsContainer from '../containers/settings-channels';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const SettingsChannelsPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <SettingsChannelsContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default SettingsChannelsPage;
