import { StatusEnum } from '../../core/enums';
import { LeadGenModel } from '../../models/user';

export const initialState = {
  status: StatusEnum.initial,
  addUserStatus: StatusEnum.initial,
  updateUserStatus: StatusEnum.initial,
  getUserByIdStatus: StatusEnum.initial,
  inactivateUserStatus: StatusEnum.initial,
  activateUserStatus: StatusEnum.initial,
  updateProfileStatus: StatusEnum.initial,
  getAllLeadGensStatus: StatusEnum.initial,
  user: null,
  users: [],
  leadgens: [] as LeadGenModel[],
  leadgenIds: [],
  error: null,
};
