import { StatusEnum } from '../../core/enums';
import { CreateUserModel, LeadGenModel, UserModel } from '../../models/user';

export const SET_USER_STORE_STATUS: string = 'SET_USER_STORE_STATUS';
export const ADD_USER: string = 'ADD_USER';
export const GET_USERS: string = 'GET_USERS';
export const GET_ALL_LEADGENS: string = 'GET_ALL_LEADGENS';
export const GET_USER_BY_ID: string = 'GET_USER_BY_ID';
export const UPDATE_USER: string = 'UPDATE_USER';
export const INACTIVATE_USER: string = 'INACTIVATE_USER';
export const ACTIVATE_USER: string = 'ACTIVATE_USER';
export const SET_LEADGEN: string = 'SET_LEADGEN';
export const UPDATE_PROFILE: string = 'UPDATE_PROFILE';

export interface UserState {
  status: StatusEnum;
  users: UserModel[];
  leadgens: LeadGenModel[];
  addUserStatus: StatusEnum;
  updateUserStatus: StatusEnum;
  getUserByIdStatus: StatusEnum;
  inactivateUserStatus: StatusEnum;
  activateUserStatus: StatusEnum;
  updateProfileStatus: StatusEnum;
  getAllLeadGensStatus: StatusEnum;
  user: UserModel | null;
  leadgenIds: string[];
  error: any | null;
}

export interface SetUserStoreStatusAction {
  type: typeof SET_USER_STORE_STATUS;
  payload: SetUserStoreStatusActionPayload;
}

export interface SetUserStoreStatusActionPayload {
  status?: StatusEnum;
  addUserStatus?: StatusEnum;
  updateUserStatus?: StatusEnum;
  getUserByIdStatus?: StatusEnum;
  inactivateUserStatus?: StatusEnum;
  activateUserStatus?: StatusEnum;
  updateProfileStatus?: StatusEnum;
  getAllLeadGensStatus?: StatusEnum;
  error?: any;
}

export interface AddUserAction {
  type: typeof ADD_USER;
  payload: AddUserActionPayload;
}
export interface AddUserActionPayload {
  user: Partial<UserModel>;
}

export interface GetUsersActionPayload {
  users: UserModel[];
}
export interface GetUsersAction {
  type: typeof GET_USERS;
  payload: GetUsersActionPayload;
}

export interface SetLeadGenAction {
  type: typeof SET_LEADGEN;
  payload: SetLeadGenActionPayload;
}
export interface SetLeadGenActionPayload {
  leadgenIds: string[];
}

export interface GetAllLeadGensAction {
  type: typeof GET_ALL_LEADGENS;
  payload: GetAllLeadGensActionPayload;
}
export interface GetAllLeadGensActionPayload {
  leadgens: any[];
}

export interface UpdateUserActionPayload {
  userId: string;
  user: any;
}
export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: UpdateUserActionPayload;
}

export interface InactivateUserAction {
  type: typeof INACTIVATE_USER;
  payload: InactivateUserActionPayload;
}
export interface InactivateUserActionPayload {
  users: UserModel[];
}


export interface ActivateUserAction {
  type: typeof ACTIVATE_USER;
  payload: InactivateUserActionPayload;
}
export interface ActivateUserActionPayload {
  users: UserModel[];
}
