import {
  AuthState,
  AUTH_STATUS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_IN,
} from './types';
import { initialState } from './store';
import { ActionType } from '../index';

const authReducer = (state: AuthState = initialState, action: ActionType) => {
  switch (action.type) {
    // case SIGN_IN:
    //   return { ...state, ...action.payload };
    // case FORGOT_PASSWORD:
    //   return { ...state, ...action.payload };
    case AUTH_STATUS:
      return { ...state, ...action.payload };
    // case RESET_PASSWORD:
    //   return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default authReducer;
