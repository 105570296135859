import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { DashboardRoute, SignInRoute } from '../constants/routes';
import useAuth from '../hooks/useAuth';

const AuthRoute = () => {
  const auth = useAuth();
  return !auth ? <Outlet /> : <Navigate to={DashboardRoute} />;
};

export default AuthRoute;
