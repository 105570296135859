import {
  getUserByIdAction,
  getUsersAction,
  setUserStoreStatusAction,
} from '../actions';
import UserService from '../../../services/user.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { StatusEnum } from '../../../core/enums';

const getUserByIdThunk = (userId: string): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({
          getUserByIdStatus: StatusEnum.running,
          error: null,
        })
      );

      const response = await UserService.getUserById(userId);

      if (response.status === 200 || response.status === 201) {
        dispatch(getUserByIdAction({ user: response.data }));
        dispatch(
          setUserStoreStatusAction({ getUserByIdStatus: StatusEnum.success })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          getUserByIdStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getUserByIdThunk;
