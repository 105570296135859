import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SuccessResetPasswordRoute } from '../constants/routes';
import { StatusEnum } from '../core/enums';
import { authStatusAction } from '../store/auth/actions';
import { getAuthStoreSelector } from '../store/auth/selector';
import { removeQuotesFromString } from '../utils';

const useResetPasswordToasts = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetPasswordStatus, error } = useSelector(getAuthStoreSelector);

  useEffect(() => {
    if (error && resetPasswordStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Reset password error',
        description: removeQuotesFromString(error),
      });
    }
    if (resetPasswordStatus === StatusEnum.success) {
      dispatch(
        authStatusAction({
          resetPasswordStatus: StatusEnum.initial,
          error: null,
        })
      );
      navigate(SuccessResetPasswordRoute);
    }
  }, [error, toast, resetPasswordStatus, navigate, dispatch]);

  useEffect(() => {
    dispatch(
      authStatusAction({
        resetPasswordStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        authStatusAction({
          resetPasswordStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useResetPasswordToasts;
