export enum StatusEnum {
  initial = 'initial',
  running = 'running',
  success = 'success',
  error = 'error',
}

export enum UserRoleEnum {
  None = 0,
  Admin = 1,
  LeadGen = 2,
  Sales = 3
}

export enum StatusGroupEnum {
  None = 0,
  New = 1,
  Old = 2,
  PotentialTechnologies = 3,
  Bidded = 4,
  Declined = 5,
  TechSupport = 6,
  AnswerReceived = 7,
  Sql = 8,
}

export enum DeclineReasonType {
  None = 0,
  LowBudget = 1,
  NoAvailableDevelopers = 2,
  NotOurTechStack = 3,
  LowWorkload = 4,
  Other = 5,
}

export enum DatePickerEnum {
  None = 0,
  Today = 1,
  ThreeDays = 2,
  Week = 3,
  Custom = 4,
}

export enum CommentTypeEnum {
  None = 0,
  Question = 1,
  Answer = 2,
}

export enum TechSupportMessageEnum {
  None = 0,
  Question = 1,
  Answer = 2,
}

export enum UserStatusEnum {
  None = 0,
  Active = 1,
  Inactive = 2,
}
