import {
  SIGN_IN,
  FORGOT_PASSWORD,
  SignInAction,
  ForgotPasswordAction,
  ForgotPasswordActionPayload,
  SignInActionPayload,
  RESET_PASSWORD,
  ResetPasswordAction,
  ResetPasswordActionPayload,
  AuthStatusAction,
  AuthStatusActionPayload,
  AUTH_STATUS,
} from './types';

export const authStatusAction = (
  payload: AuthStatusActionPayload
): AuthStatusAction => ({
  type: AUTH_STATUS,
  payload: payload,
});
export const signInAction = (payload: SignInActionPayload): SignInAction => ({
  type: SIGN_IN,
  payload: payload,
});

export const forgotPasswordAction = (
  payload: ForgotPasswordActionPayload
): ForgotPasswordAction => ({
  type: FORGOT_PASSWORD,
  payload: payload,
});

export const resetPasswordAction = (
  payload: ResetPasswordActionPayload
): ResetPasswordAction => ({
  type: RESET_PASSWORD,
  payload: payload,
});
