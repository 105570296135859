import {
  getGroupChannelsAction,
  setChannelStoreStatusAction,
} from '../actions';
import ChannelService from '../../../services/channel.service';
import { channels } from '../../../lib/channels';

import { RootStore } from '../..';
import { Dispatch } from 'redux';

const getGroupChannelsThunk = (type: any): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(setChannelStoreStatusAction({ status: 'running' }));

      // const response = {
      //   status: 200,
      //   data: { channels: channels, total: 100 },
      // };
      const response = await ChannelService.getByStatusGroup(type);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          getGroupChannelsAction({
            groupChannels: response.data.channels,
            groupChannelsTotal: response.data.total,
          })
        );
        dispatch(setChannelStoreStatusAction({ status: 'success' }));
      }
    } catch (error) {
      dispatch(setChannelStoreStatusAction({ status: 'error', error: error }));
    }
  };
};

export default getGroupChannelsThunk;
