import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import DashboardContainer from '../containers/dashboard';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const DashboardPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <DashboardContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default DashboardPage;
