import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import FormErrorMessage from './FormErrorMessage';
import { StatusEnum, UserRoleEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import updateChannelThunk from '../store/channel/thunk/update-channel';
import { getChannelStoreSelector } from '../store/channel/selector';
import { setChannelStoreStatusAction } from '../store/channel/actions';
import { validateTitle, validateUrl } from '../validations';

interface EditChannelPopupProps {
  isOpen: boolean;
  onClose: () => void;
  channel?: any;
}

const EditChannelPopup = ({
  isOpen,
  onClose,
  channel,
}: EditChannelPopupProps): JSX.Element => {
  const dispatch = useDispatch();

  const { updateChannelStatus, error } = useSelector(getChannelStoreSelector);

  const handleEditSubmit = (values: any) => {
    const body = { ...channel, ...values };
    dispatch(updateChannelThunk(body));
  };

  useEffect(() => {
    if (updateChannelStatus === StatusEnum.success) {
      onClose();
    }
  }, [updateChannelStatus, onClose]);

  return (
    <Modal
      size='lg'
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Channel</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              title: channel?.title ?? '',
              url: channel?.url ?? '',
            }}
            onSubmit={(values, actions) => {
              handleEditSubmit(values);
            }}
          >
            <Form>
              <Field name='title' validate={validateTitle}>
                {({ field, form }: any) => (
                  <FormControl
                    id='title'
                    isInvalid={form.errors.title && form.touched.title}
                    marginBottom='30px'
                  >
                    <FormLabel>Name</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                    </InputGroup>
                    {form.errors.title && (
                      <FormErrorMessage message={form.errors.title} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='url' validate={validateUrl}>
                {({ field, form }: any) => (
                  <FormControl
                    id='url'
                    isInvalid={form.errors.url && form.touched.url}
                    marginBottom='30px'
                  >
                    <FormLabel>Url</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                    </InputGroup>
                    {form.errors.url && (
                      <FormErrorMessage message={form.errors.url} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Wrap spacing='30px' justify='center' mb='25px'>
                <WrapItem>
                  <Button
                    border='1px solid #413fe3'
                    borderRadius='8px'
                    bg='transparent'
                    color='#413fe3'
                    fontSize='15px'
                    fontWeight='400'
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    borderRadius='8px'
                    bg='#5755FF'
                    color='#ffffff'
                    isLoading={updateChannelStatus === StatusEnum.running}
                    loadingText='Wait...'
                    fontSize='15px'
                    fontWeight='400'
                    _hover={{
                      bg: '#413fe3',
                    }}
                    _active={{
                      bg: '#5755FF',
                    }}
                    type='submit'
                  >
                    Save
                  </Button>
                </WrapItem>
              </Wrap>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditChannelPopup;
