import React from 'react';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
  Flex,
  Box,
  Select,
  Menu,
  MenuButton,
  MenuList,
  CheckboxGroup,
  Stack,
  Checkbox,
  Button,
} from '@chakra-ui/react';
import BarChart from './BarChart';
import LineChart from './LineChart';
import { stats } from '../lib/stats';
import StatisticReceivedTable from './StatisticReceivedTable';
import StatisticPostsTable from './StatisticPostsTable';
import DateCalendar from './DateCalendar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LeadGenModel } from '../models/user';
import NewLineChart from './NewLineChart';
import NewBarChart from './NewBarChart';

interface StatisticTabsProps {
  receivedStatistic: any[];
  postsStatistic: any;
  fromReceivedDate: string;
  toReceivedDate: string;
  fromPostDate: string;
  toPostDate: string;
  setReceivedDates: (date: any) => void;
  setPostsDates: (date: any) => void;
  opened: number;
  leadgens: LeadGenModel[];
  leadgenIds: string[];
  setLeadGen: (value: string[]) => void;
}

const StatisticTabs = ({
  receivedStatistic,
  postsStatistic,
  fromReceivedDate,
  toReceivedDate,
  fromPostDate,
  toPostDate,
  setReceivedDates,
  setPostsDates,
  opened,
  leadgens,
  leadgenIds,
  setLeadGen,
}: StatisticTabsProps): JSX.Element => {
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const bg = useColorModeValue('#FFFFFF', '#252525');
  const borderColor = useColorModeValue('#e4dfdf', '#1a1818');

  const handleSelect = (values: any) => {
    setLeadGen(values);
  };
  return (
    <Tabs variant='unstyled' defaultIndex={opened}>
      <TabList>
        <Tab
          bg={bg}
          width='196px'
          height='70px'
          borderRadius='10px 10px 0 0'
          _selected={{
            color: '#5755FF',
            mr: '-5px',
            zIndex: '20',
            boxShadow: '6px 0px 6px -2px rgba(0,0,0,0.12);',
          }}
          onClick={() => {
            navigate('/statistic?tab=0');
          }}
        >
          Received
        </Tab>
        <Tab
          bg={bg}
          width='196px'
          height='70px'
          borderRadius='10px 10px 0 0'
          _selected={{
            color: '#5755FF',
            ml: '-5px',
            zIndex: '20',
            boxShadow:
              '6px 0px 6px -2px rgba(0,0,0,0.12), -4px 0px 6px -2px rgba(0,0,0,0.12)',
          }}
          onClick={() => {
            navigate('/statistic?tab=1');
          }}
        >
          Posts
        </Tab>
      </TabList>
      <TabPanels
        bg={bg}
        borderRadius='0px 10px 10px 10px;'
        p='10px 20px 20px 20px'
      >
        <TabPanel p='0'>
          <Flex align='center' justify='end'>
            <Flex align='center' flexWrap='wrap' mb='10px'>
              <Box w='150px' mr='10px'>
                <Menu>
                  <MenuButton
                    width='100%'
                    as={Button}
                    variant='custom'
                    border='1px solid #D9E2EC;'
                    h='48px'
                    fontSize='16px'
                    fontWeight='400'
                  >
                    LeadGens
                  </MenuButton>
                  <MenuList padding='10px'>
                    <CheckboxGroup
                      colorScheme='green'
                      defaultValue={leadgenIds}
                      onChange={handleSelect}
                    >
                      <Stack spacing={[1, 2]} direction={['column']}>
                        {leadgens.map((leadgen) => {
                          return (
                            <Checkbox
                              key={leadgen.id}
                              value={leadgen.id}
                              colorScheme='blue'
                            >
                              {leadgen.fullName}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </CheckboxGroup>
                  </MenuList>
                </Menu>
              </Box>
              <DateCalendar
                from={fromReceivedDate}
                to={toReceivedDate}
                setDates={setReceivedDates}
              />
            </Flex>
          </Flex>
          <NewLineChart receivedStatistic={receivedStatistic} />
        </TabPanel>
        <TabPanel p='0'>
          <Flex align='center' justify='end' mb='10px'>
            <Box>
              <DateCalendar
                from={fromPostDate}
                to={toPostDate}
                setDates={setPostsDates}
              />
            </Box>
          </Flex>
          <NewBarChart statusGroups={postsStatistic.statusGroups} />
        </TabPanel>
      </TabPanels>
      <TabPanels mt='48px'>
        <TabPanel>
          <StatisticReceivedTable stats={receivedStatistic} />
        </TabPanel>
        <TabPanel>
          <StatisticPostsTable stats={postsStatistic.leadgens} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default StatisticTabs;
