import React, { useEffect } from 'react';
import { Button, Wrap, WrapItem, useDisclosure, Box } from '@chakra-ui/react';

import AddUserPopup from '../components/AddUserPopup';
import UsersTable from '../components/tables/UsersTable';
import ContentLayout from '../layouts/content';
import { PlusIcon } from '../icons';
import { useDispatch, useSelector } from 'react-redux';
import getUsersThunk from '../store/user/thunk/get-users';
import { getUserStoreSelector } from '../store/user/selector';
import useUserManagementToasts from '../hooks/useUserManagementToasts';
import useSignalR from '../hooks/useSignalR';

const UserManagementContainer = (): JSX.Element => {
  useUserManagementToasts();
  useSignalR();

  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { status, users } = useSelector(getUserStoreSelector);

  const loading = status === 'running';

  useEffect(() => {
    dispatch(getUsersThunk());
  }, [dispatch]);

  return (
    <Box
      as='main'
      height='calc(100vh - 60px)'
      w='100%'
      p={{ base: '10px 2px', sm: '32px' }}
      overflow='hidden'
    >
      <ContentLayout
        title='User Management'
        headerChildren={
          <Wrap>
            <WrapItem>
              <Button
                leftIcon={<PlusIcon fill='#ffffff' />}
                borderRadius='8px'
                bg='#5755FF'
                color='#ffffff'
                fontSize='15px'
                _hover={{
                  bg: '#413fe3',
                }}
                _active={{
                  bg: '#5755FF',
                }}
                onClick={onOpen}
              >
                Add new
              </Button>
            </WrapItem>
          </Wrap>
        }
      >
        <UsersTable users={users} isLoading={loading} />
      </ContentLayout>
      <AddUserPopup isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default UserManagementContainer;
