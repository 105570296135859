import {
  AddCommentAction,
  AddCommentActionPayload,
  ADD_COMMENT,
  GetCommentsAction,
  GetCommentsActionPayload,
  GET_COMMENTS,
  SetCommentStoreStatusActionPayload,
  SET_COMMENT_STORE_STATUS,
} from './types';

export const setCommentStoreStatusAction = (
  payload: SetCommentStoreStatusActionPayload
): any => ({
  type: SET_COMMENT_STORE_STATUS,
  payload: payload,
});

export const addCommentAction = (
  payload: AddCommentActionPayload
): AddCommentAction => ({
  type: ADD_COMMENT,
  payload: payload,
});

export const getCommentsAction = (
  payload: GetCommentsActionPayload
): GetCommentsAction => ({
  type: GET_COMMENTS,
  payload: payload,
});
