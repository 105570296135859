import React, { useEffect, useState } from 'react';
import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Menu,
  MenuList,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { SearchIcon } from '../icons';
import { useSelector } from 'react-redux';
import { getPostStoreSelector } from '../store/post/selector';
import { useLocation, useSearchParams } from 'react-router-dom';

interface SearchPopupProps {
  children?: React.ReactNode;
}

const SearchPopup = ({ children }: SearchPopupProps) => {
  const location = useLocation();
  const [query, setQuery] = useSearchParams(location.search);
  const [string, setString] = useState('');

  const handleChangeSearch = (event: any) => {
    setString(event.target.value);
  };

  const { search } = useSelector(getPostStoreSelector);

  const handleSearch = () => {
    const channel = query.get('channel');
    if (channel) {
      setQuery({ channel: String(channel), search: string });
    } else {
      setQuery({ search: string });
    }
  };
  const handleSearchOnKey = (event: any) => {
    if (event.code === 'Enter') {
      const channel = query.get('channel');
      if (channel) {
        setQuery({ channel: String(channel), search: string });
      } else {
        setQuery({ search: string });
      }
    }
  };

  useEffect(() => {
    setString(search);
  }, [search]);

  return (
    <Menu>
      <MenuButton
        cursor='pointer'
        size='sm'
        as={Box}
        bg='transparent'
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      >
        {children}
      </MenuButton>
      <MenuList>
        <Box padding='20px' borderRadius='8px'>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              placeholder='Search'
              value={string}
              onChange={handleChangeSearch}
              onKeyDown={handleSearchOnKey}
            />
            <InputRightElement>
              <IconButton
                aria-label='search-button'
                size='sm'
                icon={<SearchIcon />}
                onClick={handleSearch}
              />
            </InputRightElement>
          </InputGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default SearchPopup;
