import { getChannelsAction, setChannelStoreStatusAction } from '../actions';
import ChannelService from '../../../services/channel.service';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import { CreateChannelModel } from '../../../models/channel';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';

const addChannelThunk = (channel: CreateChannelModel): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setChannelStoreStatusAction({
          addChannelStatus: StatusEnum.running,
          error: null,
        })
      );
      const response = await ChannelService.addChannel(channel);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          getChannelsAction({
            channels: getState().channelStore.channels.concat({
              ...channel,
              id: response.data,
              isActive: true,
            }),
          })
        );
        dispatch(
          setChannelStoreStatusAction({ addChannelStatus: StatusEnum.success })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setChannelStoreStatusAction({
          addChannelStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default addChannelThunk;
