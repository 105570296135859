import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SuccessForgotPasswordRoute } from '../constants/routes';
import { StatusEnum } from '../core/enums';
import { authStatusAction } from '../store/auth/actions';
import { getAuthStoreSelector } from '../store/auth/selector';
import { removeQuotesFromString } from '../utils';

const useForgotPasswordToasts = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgotPasswordStatus, error } = useSelector(getAuthStoreSelector);

  useEffect(() => {
    if (error && forgotPasswordStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Forgot password error',
        description: removeQuotesFromString(error),
      });
    }
    if (forgotPasswordStatus === StatusEnum.success) {
      dispatch(
        authStatusAction({
          forgotPasswordStatus: StatusEnum.initial,
          error: null,
        })
      );
      navigate(SuccessForgotPasswordRoute.format({ email }));
    }
  }, [error, toast, email, forgotPasswordStatus, navigate, dispatch]);

  useEffect(() => {
    dispatch(
      authStatusAction({
        forgotPasswordStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        authStatusAction({
          forgotPasswordStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
  return { setEmail };
};

export default useForgotPasswordToasts;
