import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../core/enums';
import { setUserStoreStatusAction } from '../store/user/actions';
import { getUserStoreSelector } from '../store/user/selector';
import { removeQuotesFromString } from '../utils';

const useUserManagementToasts = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { updateUserStatus, addUserStatus, error } =
    useSelector(getUserStoreSelector);

  useEffect(() => {
    if (updateUserStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Action error',
        description: removeQuotesFromString(error),
      });
    }
    if (updateUserStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'User Updated!',
      });
    }
    return () => {
      dispatch(
        setUserStoreStatusAction({
          updateUserStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [error, toast, updateUserStatus, dispatch]);

  useEffect(() => {
    if (addUserStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Action error',
        description: removeQuotesFromString(error),
      });
    }
    if (addUserStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'User Added!',
      });
    }
    return () => {
      dispatch(
        setUserStoreStatusAction({
          addUserStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [error, toast, addUserStatus, dispatch]);

  useEffect(() => {
    // dispatch(
    //   setUserStoreStatusAction({
    //     updateUserStatus: StatusEnum.initial,
    //     addUserStatus: StatusEnum.initial,
    //     error: null,
    //   })
    // );
    return () => {
      dispatch(
        setUserStoreStatusAction({
          updateUserStatus: StatusEnum.initial,
          addUserStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useUserManagementToasts;
