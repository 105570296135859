import { getUsersAction, setUserStoreStatusAction } from '../actions';
import UserService from '../../../services/user.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { StatusEnum } from '../../../core/enums';

const getUsersThunk = (): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({ status: StatusEnum.running, error: null })
      );

      const response = await UserService.getUsers();

      if (response.status === 200 || response.status === 201) {
        dispatch(getUsersAction({ users: response.data.users }));
        dispatch(setUserStoreStatusAction({ status: StatusEnum.success }));
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          status: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getUsersThunk;
