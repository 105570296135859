import React, { useEffect, useState } from 'react';
import { Box, Flex, Link, Text, FlexProps } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ChannelModel } from '../../models/channel';

interface ChannelNavItemProps extends FlexProps {
  beforePath: string;
  path: string;
  activePath: string;
  children: string;
  isNavigate?: boolean;
  isCount?: boolean;
  total: number;
  channel: any;
  channels?: ChannelModel[];
}

const ChannelNavItem = ({
  beforePath,
  path,
  activePath,
  children,
  isCount,
  isNavigate,
  total,
  channel,
  channels,
  ...rest
}: ChannelNavItemProps) => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    if (isNavigate) {
      navigate(path);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const channelParamId = params.get('channel');
    const page = params.get('page');
    let newActivePath;

    // if (!page) {
    //   newActivePath = activePath;
    // }
    // if (page) {
    //   newActivePath = activePath + `&page=${page}`;
    // }
    // const currentPath = location.pathname + location.search;
    // if (currentPath === newActivePath) {
    //   setActive(true);
    // } else {
    //   setActive(false);
    // }
    if (channelParamId) {
      if (channelParamId === channel?.id && beforePath === location.pathname) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else {
      if (beforePath === activePath) {
        setActive(true);
      } else {
        setActive(false);
      }
    }

    return () => {};
  }, [location, activePath, channel, beforePath]);

  return (
    <Link
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={handleNavigate}
    >
      <Flex
        align='center'
        p='2'
        pl={isCount ? '5px' : '60px'}
        role='group'
        cursor='pointer'
        borderLeft={isActive ? '6px solid #5755FF' : '6px solid transparent'}
        _groupHover={{
          color: '#5755FF',
        }}
        _hover={{
          borderLeft: '6px solid #5755FF',
        }}
        wordBreak='break-word'
        {...rest}
      >
        {isCount && (
          <Box
            padding='3px'
            width='35px'
            height='35px'
            borderRadius='50%'
            backgroundColor='#5755FF'
            color='#ffffff'
            display='flex'
            alignItems='center'
            justifyContent='center'
            fontSize='10px'
            lineHeight='14px'
            mr='14px'
          >
            {total}
          </Box>
        )}
        <Text
          color={isActive ? '#5755FF' : '#758391'}
          _groupHover={{
            color: '#5755FF',
          }}
          fontSize='15px'
        >
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

export default ChannelNavItem;
