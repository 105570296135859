import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import UserManagementContainer from '../containers/user-management';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const UserManagementPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <UserManagementContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default UserManagementPage;
