import React from 'react';
import { Icon } from '@chakra-ui/react';

export const SearchIcon = (props: any) => (
  <Icon
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    fill='#758391'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5178 15.0964C17.6345 13.6751 18.2437 11.9492 18.2437 10.1218C18.2437 5.65482 14.5888 2 10.1218 2C5.65482 2 2 5.65482 2 10.1218C2 14.5888 5.65482 18.2437 10.1218 18.2437C11.9492 18.2437 13.7766 17.6345 15.0964 16.5178L20.5787 22L22 20.5787L16.5178 15.0964ZM10.1218 16.2132C6.77157 16.2132 4.03046 13.4721 4.03046 10.1218C4.03046 6.77157 6.77157 4.03046 10.1218 4.03046C13.4721 4.03046 16.2132 6.77157 16.2132 10.1218C16.2132 13.4721 13.4721 16.2132 10.1218 16.2132Z'
      fill='#758391'
    />
  </Icon>
);
