import { getCommentsAction, setCommentStoreStatusAction } from '../actions';
import CommentService from '../../../services/comment.service';
import { channels } from '../../../lib/channels';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { sleep } from '../../../utils';
import { StatusEnum } from '../../../core/enums';

const getCommentsByIdThunk = (postId: string): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setCommentStoreStatusAction({
          getCommentsStatus: StatusEnum.running,
          error: null,
        })
      );
      const response = await CommentService.getCommentsById(postId);
      if (response.status === 200 || response.status === 201) {
        dispatch(getCommentsAction({ comments: response.data.comments }));
        dispatch(
          setCommentStoreStatusAction({ getCommentsStatus: StatusEnum.success })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setCommentStoreStatusAction({
          getCommentsStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getCommentsByIdThunk;
