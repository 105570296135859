import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import DashboardDeclinedContainer from '../containers/dashboard-declined';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const DashboardDeclinedPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <DashboardDeclinedContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default DashboardDeclinedPage;
