import { CommentsState, GET_COMMENTS, SET_COMMENT_STORE_STATUS } from './types';
import { initialState } from './store';
import { ActionType } from '../index';

const commentReducer = (
  state: CommentsState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case SET_COMMENT_STORE_STATUS:
      return { ...state, ...action.payload };
    case GET_COMMENTS:
      return { ...state, comments: action.payload.comments };

    default:
      return state;
  }
};

export default commentReducer;
