import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import DashboardAnswerReceivedContainer from '../containers/dashboard-answer-received';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const DashboardAnswerReceivedPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <DashboardAnswerReceivedContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default DashboardAnswerReceivedPage;
