import React, { ReactNode } from 'react';
import {
  Box,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react';

import SidebarContent from '../components/sidebar/SidebarContent';
import MobileNav from '../components/sidebar/MobileNav';

const SidebarWithHeader = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      minH='100vh'
      bg={useColorModeValue('gray.100', 'gray.900')}
      overflow='hidden'
    >
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Flex flexDirection='row' overflow='hidden'>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        {children}
      </Flex>
    </Box>
  );
};

export default SidebarWithHeader;
