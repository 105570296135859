import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localStorageService from '../services/local-storage.service';

import {
  setPostsDatesAction,
  setReceivedDatesAction,
} from '../store/statistic/actions';
import { getStatisticStoreSelector } from '../store/statistic/selector';
import getPostsStatisticThunk from '../store/statistic/thunk/get-posts-statistic';
import getReceivedStatisticThunk from '../store/statistic/thunk/get-received-statistic';
import { setLeadGenAction } from '../store/user/actions';
import { getUserStoreSelector } from '../store/user/selector';
import getAllLeadGensThunk from '../store/user/thunk/get-all-leadgens';

const useStatistics = function () {
  const dispatch = useDispatch();

  const user = localStorageService.getUser();

  const {
    receivedStatistic,
    postsStatistic,
    getReceivedStatus,
    getPostsStatus,
    fromReceivedDate,
    toReceivedDate,
    fromPostDate,
    toPostDate,
  } = useSelector(getStatisticStoreSelector);

  const { leadgens, getAllLeadGensStatus, leadgenIds } =
    useSelector(getUserStoreSelector);

  const setReceivedDates = ({ from, to }: any) => {
    dispatch(
      setReceivedDatesAction({ fromReceivedDate: from, toReceivedDate: to })
    );
  };

  const setPostsDates = ({ from, to }: any) => {
    dispatch(setPostsDatesAction({ fromPostDate: from, toPostDate: to }));
  };

  const setLeadGen = (ids: string[]) => {
    dispatch(setLeadGenAction({ leadgenIds: ids }));
  };

  // const setLeadGen = useCallback((id: string) => () => {
  //   dispatch(setLeadGenAction({ leadgenId: id }));
  // });

  useEffect(() => {
    dispatch(
      getReceivedStatisticThunk({
        from: fromReceivedDate,
        to: toReceivedDate,
        leadGenIds: leadgenIds,
      })
    );
  }, [dispatch, fromReceivedDate, leadgenIds, toReceivedDate, user.id]);

  useEffect(() => {
    dispatch(
      getPostsStatisticThunk({
        from: fromPostDate,
        to: toPostDate,
      })
    );
  }, [dispatch, fromPostDate, toPostDate]);

  useEffect(() => {
    dispatch(getAllLeadGensThunk());
  }, [dispatch]);

  useEffect(() => {
    if (leadgens?.length > 0 && !leadgenIds) {
      setLeadGen(leadgens[0].id);
    }
  }, [leadgens, leadgenIds, setLeadGen]);

  return {
    receivedStatistic,
    postsStatistic,
    getReceivedStatus,
    getPostsStatus,
    fromReceivedDate,
    toReceivedDate,
    fromPostDate,
    toPostDate,
    leadgens,
    leadgenIds,
    getAllLeadGensStatus,
    setReceivedDates,
    setPostsDates,
    setLeadGen,
  };
};

export default useStatistics;
