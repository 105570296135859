import React from 'react';
import { Icon } from '@chakra-ui/react';

export const OpenEyeIcon = (props: any) => (
  <Icon
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    fill='#758391'
    {...props}
  >
    <g id='Action / visibility'>
      <mask
        id='mask0_1_9599'
        maskUnits='userSpaceOnUse'
        x='1'
        y='4'
        width='22'
        height='16'
      >
        <g id='Icon Mask'>
          <path
            id='Round'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12Z'
          />
        </g>
      </mask>
      <g mask='url(#mask0_1_9599)'>
        <rect id='Color Fill' width='24' height='24' fill='#858C94' />
      </g>
    </g>
  </Icon>
);
