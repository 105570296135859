import {
  GET_POSTS,
  GET_TECH_STACKS,
  PostState,
  SET_CLIENT_INFO,
  SET_CURRENT_STATUS_GROUP_TYPE_KEY,
  SET_GENERATE_ANSWER_POST,
  SET_GENERATE_ANSWER_POST_STATUS,
  SET_POSTS_CHANNEL,
  SET_POSTS_PAGE,
  SET_POSTS_SORT,
  SET_POSTS_STORE_STATUS,
  SET_POST_DATES,
  SET_POST_TAKE,
  SET_SEARCH_POSTS,
  SET_USE_POST_ROUTE_KEY,
  UPDATE_POSTS,
} from './types';
import { initialState } from './store';
import { ActionType } from '../index';

const postsReducer = (state: PostState = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_POSTS_STORE_STATUS:
      return { ...state, ...action.payload };
    case SET_SEARCH_POSTS:
      return { ...state, search: action.payload.search };
    case SET_POST_TAKE:
      return { ...state, take: action.payload.take };
    case SET_POSTS_SORT:
      return {
        ...state,
        isAcs: action.payload.isAcs,
        orderColumn: action.payload.orderColumn,
      };
    case UPDATE_POSTS:
      return {
        ...state,
        posts: action.payload.posts,
        total: action.payload.total,
      };
    case SET_POST_DATES:
      return { ...state, from: action.payload.from, to: action.payload.to };
    case GET_POSTS:
      return { ...state, posts: action.payload.posts, total: action.payload.total};
    case GET_TECH_STACKS:
        return { ...state, techStacks: action.payload.techStacks};
    case SET_POSTS_CHANNEL:
      return { ...state, channel: action.payload.channel };
    case SET_POSTS_PAGE:
      return { ...state, page: action.payload.page };
    case SET_CLIENT_INFO:
      return { ...state, clientInfo: action.payload };
    case SET_GENERATE_ANSWER_POST:
      return { ...state, ...action.payload };
    case SET_GENERATE_ANSWER_POST_STATUS:
      return { ...state, ...action.payload };
    case SET_USE_POST_ROUTE_KEY:
      return { ...state, ...action.payload };
    case SET_CURRENT_STATUS_GROUP_TYPE_KEY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default postsReducer;
