import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminRoute from '../components/AdminRoute';
import AuthRoute from '../components/AuthRoute';

import PrivateRoute from '../components/PrivateRoute';

// ROUTES
import {
  ForgotPasswordRoute,
  ResetPasswordRoute,
  SignInRoute,
  SuccessForgotPasswordRoute,
  SuccessResetPasswordRoute,
  DashboardRoute,
  UserManagementRoute,
  StatisticRoute,
  DashboardNewRoute,
  DashboardOldRoute,
  DashboardBiddedRoute,
  DashboardDeclinedRoute,
  DashboardTechSupportRoute,
  DashboardAnswersRoute,
  DashboardSqlRoute,
  SettingsChannelsRoute,
  ProfileRoute,
  DashboardPotentialTechnologiesRoute,
} from '../constants/routes';
// PAGES
import {
  SignInPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SuccessForgotPasswordPage,
  SuccessResetPasswordPage,
  DashboardPage,
  UserManagementPage,
  StatisticPage,
  ProfilePage,
  SettingsChannelsPage,
  DashboardAnswerReceivedPage,
  DashboardSQLPage,
  DashboardDeclinedPage,
  DashboardBiddedPage,
  DashboardOldPage,
  DashboardTechSupportPage,
  DashboardPotentialTechnologiesPage,
} from '../pages';

const AppRoutes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={ResetPasswordRoute.template}
          element={<ResetPasswordPage />}
        />
        <Route
          path={SuccessResetPasswordRoute}
          element={<SuccessResetPasswordPage />}
        />
        <Route element={<AuthRoute />}>
          <Route path={SignInRoute} element={<SignInPage />} />
          <Route path={ForgotPasswordRoute} element={<ForgotPasswordPage />} />
          <Route
            path={SuccessForgotPasswordRoute.template}
            element={<SuccessForgotPasswordPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={DashboardNewRoute} element={<DashboardPage />} />
          <Route path={DashboardOldRoute} element={<DashboardOldPage />} />
          <Route
            path={DashboardPotentialTechnologiesRoute}
            element={<DashboardPotentialTechnologiesPage />}
          />
          <Route
            path={DashboardBiddedRoute}
            element={<DashboardBiddedPage />}
          />
          <Route
            path={DashboardDeclinedRoute}
            element={<DashboardDeclinedPage />}
          />
          <Route
            path={DashboardTechSupportRoute}
            element={<DashboardTechSupportPage />}
          />
          <Route
            path={DashboardAnswersRoute}
            element={<DashboardAnswerReceivedPage />}
          />
          <Route path={DashboardSqlRoute} element={<DashboardSQLPage />} />
          <Route path={StatisticRoute} element={<StatisticPage />} />
          <Route path={ProfileRoute} element={<ProfilePage />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path={UserManagementRoute} element={<UserManagementPage />} />
          <Route path={StatisticRoute} element={<StatisticPage />} />
          <Route
            path={SettingsChannelsRoute}
            element={<SettingsChannelsPage />}
          />
        </Route>

        <Route path='*' element={<Navigate to={DashboardRoute} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
