import React from 'react';
import { Container, Box } from '@chakra-ui/react';

import ContentLayout from '../layouts/content';
import ProfileForm from '../components/ProfileForm';
import { useSelector } from 'react-redux';
import { getUserStoreSelector } from '../store/user/selector';
import localStorageService from '../services/local-storage.service';
import useProfileToasts from '../hooks/useProfileToasts';
import useSignalR from '../hooks/useSignalR';

const ProfileContainer = (): JSX.Element => {
  useProfileToasts();
  useSignalR();

  const { updateProfileStatus, getUserByIdStatus } =
    useSelector(getUserStoreSelector);

  const user = localStorageService.getUser();

  return (
    <Box
      as='main'
      height='calc(100vh - 60px)'
      w='100%'
      p={{ base: '10px 2px', sm: '32px' }}
      overflowY='scroll'
    >
      <ContentLayout title='Profile'>
        <Container>
          <ProfileForm
            userById={user}
            getUserByIdStatus={getUserByIdStatus}
            updateProfileStatus={updateProfileStatus}
          />
        </Container>
      </ContentLayout>
    </Box>
  );
};

export default ProfileContainer;
