import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DashboardRoute } from '../constants/routes';
import { StatusEnum } from '../core/enums';
import { setUserStoreStatusAction } from '../store/user/actions';
import { getUserStoreSelector } from '../store/user/selector';
import { removeQuotesFromString } from '../utils';

const useProfileToasts = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateProfileStatus, error } = useSelector(getUserStoreSelector);

  useEffect(() => {
    if (error && updateProfileStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Action error',
        description: removeQuotesFromString(error),
      });
    }
    if (updateProfileStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Profile Updated!',
      });
      navigate(DashboardRoute);
    }
  }, [error, toast, updateProfileStatus, navigate, dispatch]);

  useEffect(() => {
    dispatch(
      setUserStoreStatusAction({
        updateProfileStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        setUserStoreStatusAction({
          updateProfileStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useProfileToasts;
