import React from 'react';
import { Flex, Stack, Heading, Box, Text, Link } from '@chakra-ui/react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { SignInRoute } from '../constants/routes';
import Footer from '../components/Footer';
import ResetPasswordForm from '../components/ResetPasswordForm';
import useResetPasswordToasts from '../hooks/useResetPasswordToast';

const ResetPasswordContainer = (): JSX.Element => {
  const location = useLocation();

  const [searchParams] = useSearchParams(location.search);
  const navigate = useNavigate();
  useResetPasswordToasts();

  const token = searchParams.get('token') as string;
  const email = searchParams.get('email') as string;

  return (
    <>
      <Flex width='100%' height='40px' align={'center'} justify={'end'}>
        <Link
          pr='25px'
          color={'#5755FF'}
          onClick={() => {
            navigate(SignInRoute);
          }}
        >
          Sign in
        </Link>
      </Flex>
      <Flex
        minH={'calc(100vh - 96px - 40px)'}
        align={'center'}
        justify={'center'}
      >
        <Stack spacing={8} mx={'auto'} width='100%' maxW='410px' py={12} px={6}>
          <Stack align={'center'}>
            <Heading
              fontSize={'24px'}
              lineHeight='30px'
              textAlign={'center'}
              textTransform='capitalize'
            >
              Reset password
            </Heading>
            <Text
              textAlign='center'
              color='#758391'
              fontSize='16px'
              lineHeight='24px'
            >
              Set a password of at least 8 characters with letters and numbers.
              Both passwords must match.
            </Text>
          </Stack>
          <Box rounded={'lg'} width='100%' maxW='600px'>
            <ResetPasswordForm token={token} email={email} />
          </Box>
        </Stack>
      </Flex>
      <Footer />
    </>
  );
};

export default ResetPasswordContainer;
