import axiosInstance from '../api';
import { GetCommentsById, AddCommentApi } from '../constants/api';

class CommentService {
  addComment = async (comment: any) => {
    return axiosInstance.post(AddCommentApi(), comment);
  };
  getCommentsById = async (postId: string) => {
    return await axiosInstance.get(GetCommentsById(postId), {});
  };
}

export default new CommentService();
