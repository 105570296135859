import { StatusEnum, StatusGroupEnum } from '../../core/enums';
import { PostModel } from '../../models/post';
import { TechStackModel } from '../../models/techStack';

export const SET_POSTS_STORE_STATUS: string = 'SET_POSTS_STORE_STATUS';
export const GET_POSTS: string = 'GET_POSTS';
export const SET_POSTS_CHANNEL: string = 'SET_POSTS_CHANNEL';
export const SET_POSTS_PAGE: string = 'SET_POSTS_PAGE';
export const SET_POST_TAKE: string = 'SET_POST_TAKE';
export const SET_POST_DATES: string = 'SET_POST_DATES';
export const SET_SEARCH_POSTS: string = 'SET_SEARCH_POSTS';
export const SET_POSTS_SORT: string = 'SET_POSTS_SORT';
export const UPDATE_POSTS: string = 'UPDATE_POSTS';
export const SET_CLIENT_INFO: string = 'SET_CLIENT_INFO';
export const SET_GENERATE_ANSWER_POST: string = 'SET_GENERATE_ANSWER_POST';
export const SET_GENERATE_ANSWER_POST_STATUS: string = 'SET_GENERATE_ANSWER_POST_STATUS';
export const SET_USE_POST_ROUTE_KEY: string = 'SET_USE_POST_ROUTE_KEY';
export const SET_CURRENT_STATUS_GROUP_TYPE_KEY: string = 'SET_CURRENT_STATUS_GROUP_TYPE_KEY';
export const GET_TECH_STACKS: string = 'GET_TECH_STACKS';

export interface PostState {
  page: number;
  take: number;
  total: number;
  status: StatusEnum;
  transferStatus: StatusEnum;
  posts: PostModel[];
  techStacks: TechStackModel[];
  clientInfo: ClientInfoPostState | null;
  isAcs: boolean;
  orderColumn: number;
  search: string;
  from: string | null;
  to: string | null;
  channel: string | null;
  error: any | null;
  generateAnswerStatus?: StatusEnum;
  usePostRouteKey: string | null;
  currentStatusGroupType: StatusGroupEnum;
}

export interface ClientInfoPostState {
  postId: string;
  channelId: string;
  name: string,
  agency: string,
  rate: number,
  techStack: string
}
export interface SetPostsSortAction {
  type: typeof SET_POSTS_SORT;
  payload: SetPostsSortActionPayload;
}
export interface SetPostsSortActionPayload {
  isAcs?: boolean;
  orderColumn?: number;
}

export interface SetPostStoreStatusAction {
  type: typeof SET_POSTS_STORE_STATUS;
  payload: SetPostStoreStatusActionPayload;
}
export interface SetPostStoreStatusActionPayload {
  status?: StatusEnum;
  transferStatus?: StatusEnum;
  error?: any;
}

export interface GetPostsAction {
  type: typeof GET_POSTS;
  payload: GetPostsActionPayload;
}


export interface GetPostsActionPayload {
  posts: PostModel[];
  total?: number;
  transferStatus?: StatusEnum;
}


export interface GetTechStackAction {
  type: typeof GET_TECH_STACKS;
  payload: GetTechStackActionPayload;
}

export interface GetTechStackActionPayload {
  techStacks: TechStackModel[];
}

export interface SetPostsChannelAction {
  type: typeof SET_POSTS_CHANNEL;
  payload: SetPostsChannelActionPayload;
}
export interface SetPostsChannelActionPayload {
  channel: string | null;
}

export interface SetSearchPostsAction {
  type: typeof SET_SEARCH_POSTS;
  payload: SetSearchPostsActionPayload;
}
export interface SetSearchPostsActionPayload {
  search: string;
}

export interface SetPostsPageAction {
  type: typeof SET_POSTS_PAGE;
  payload: SetPostsPageActionPayload;
}
export interface SetPostsPageActionPayload {
  page: number | null;
}

export interface SetClientInfoPostAction {
  type: typeof SET_CLIENT_INFO;
  payload: SetClientInfoPostPayload | null;
}

export interface SetClientInfoPostPayload {
  postId: string;
  channelId: string;
  name?: string | null;
  agency?: string | null;
  rate?: number | null;
  techStacks?: string[] | null;
}

export interface SetGenerateAnswerStoreStatusAction {
  type: typeof SET_GENERATE_ANSWER_POST_STATUS;
  payload: SetGenerateAnswerPostStoreStatusActionPayload | null;
}

export interface SetGenerateAnswerPostStoreStatusActionPayload{
  generateAnswerStatus?: string;
  error?: any;
}

export interface SetGenerateAnswerPostStoreAction {
  type: typeof SET_GENERATE_ANSWER_POST;
  payload: SetGenerateAnswerPostStoreActionPayload | null;
}

export interface SetGenerateAnswerPostStoreActionPayload {
  posts?: PostModel[];
}

export interface SetUsePostRouteAction {
  type: typeof SET_USE_POST_ROUTE_KEY;
  payload: SetUsePostRouteActionPayload | null;
}

export interface SetUsePostRouteActionPayload {
  usePostRouteKey: string;
}

export interface SetCurrentStatusGroupTypeAction {
  type: typeof SET_USE_POST_ROUTE_KEY;
  payload: SetCurrentStatusGroupTypeActionPayload | null;
}

export interface SetCurrentStatusGroupTypeActionPayload {
  currentStatusGroupType: StatusGroupEnum;
}
