import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../core/enums';
import { setPostsStoreStatusAction } from '../store/post/actions';
import { getPostStoreSelector } from '../store/post/selector';
import { removeQuotesFromString } from '../utils';

const useTransferToast = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { transferStatus, error } = useSelector(getPostStoreSelector);

  useEffect(() => {
    if (transferStatus === StatusEnum.error) {
      if (error) {
        toast({
          position: 'top-right',
          status: 'error',
          title: 'Action error',
          description: removeQuotesFromString(error),
        });
      } else {
        toast({
          position: 'top-right',
          status: 'error',
          title: 'Action error',
          description: 'Tranfer error',
        });
      }
    }
    if (transferStatus === StatusEnum.running) {
      toast({
        position: 'top-right',
        status: 'info',
        description: 'Updating process...',
      });
    }
    if (transferStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Success action',
      });
    }
    return () => {
      dispatch(
        setPostsStoreStatusAction({
          transferStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [error, toast, transferStatus, dispatch]);

  useEffect(() => {
    dispatch(
      setPostsStoreStatusAction({
        transferStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        setPostsStoreStatusAction({
          transferStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useTransferToast;
