import React from 'react';
import { Flex, VStack, Text, Button, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { SuccessResetPasswordIcon } from '../icons';
import { SignInRoute } from '../constants/routes';

const SuccessResetPassword = (): JSX.Element => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(SignInRoute);
  };
  return (
    <Flex alignItems='center' justifyContent='center'>
      <Stack spacing={4} w={'full'} maxW={'md'} p={6} my={12}>
        <VStack spacing={5}>
          <SuccessResetPasswordIcon />
          <Text
            textAlign='center'
            color='#758391'
            fontSize='16px'
            lineHeight='24px'
          >
            Your password was changed successfuly!
          </Text>
        </VStack>
        <Button
          type='submit'
          loadingText='Submitting'
          size='lg'
          bg={'#5755FF'}
          color={'white'}
          _hover={{
            bg: '#3e3dd2',
          }}
          onClick={handleBack}
        >
          Back to Dashboards
        </Button>
      </Stack>
    </Flex>
  );
};

export default SuccessResetPassword;
