import React from 'react';
import { Icon } from '@chakra-ui/react';

export const SuccessResetPasswordIcon = (props: any) => (
  <Icon
    width='100px'
    height='101px'
    viewBox='0 0 100 101'
    // fill='#394452'
    {...props}
  >
    <circle cx='50.5' cy='50' r='50' fill='#F1F1FF' />
    <mask
      id='mask0_12_489'
      maskUnits='userSpaceOnUse'
      x='29'
      y='28'
      width='45'
      height='40'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.3182 40.5837L53.3982 29.5037C54.5582 28.3237 56.4382 28.3237 57.6182 29.4837C58.3382 30.2037 58.6382 31.2237 58.4382 32.2237L56.5382 41.3837H67.8382C72.1382 41.3837 75.0382 45.7837 73.3582 49.7437L66.8382 64.9637C66.1982 66.4237 64.7582 67.3837 63.1582 67.3837H45.1582C42.9582 67.3837 41.1582 65.5837 41.1582 63.3837V43.4037C41.1582 42.3437 41.5782 41.3237 42.3182 40.5837ZM37.1582 63.3838C37.1582 65.5838 35.3582 67.3838 33.1582 67.3838C30.9582 67.3838 29.1582 65.5838 29.1582 63.3838V47.3838C29.1582 45.1838 30.9582 43.3838 33.1582 43.3838C35.3582 43.3838 37.1582 45.1838 37.1582 47.3838V63.3838Z'
        fill='#5755FF'
      />
    </mask>
    <g mask='url(#mask0_12_489)'>
      <rect x='27.5' y='24' width='48' height='48' fill='#5755FF' />
    </g>
  </Icon>
);
