import {
  Box,
  CheckboxGroup,
  Flex,
  Stack,
  Text,
  useCheckboxGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import CustomCheckbox from './CustomCheckbox';

const CustomTooltip = ({ active, payload, label }: any) => {
  const bg = useColorModeValue('#e2e8f0', '#171923');

  if (active && payload && payload.length) {
    return (
      <Box bg={bg} borderRadius='10px' padding='10px'>
        <Text>{`${payload[0].payload.fullDate}`}</Text>
        {payload?.map((item: any, index: number) => {
          return (
            <Text key={index} textTransform='capitalize'>
              {String(item?.name)} : {item?.value}
            </Text>
          );
        })}
      </Box>
    );
  }

  return null;
};

function NewLineChart({ receivedStatistic }: any) {
  const [filter, setFilter] = useState({
    total: true,
    viewed: true,
    bidded: true,
    declined: false,
    techSupport: false,
    answerReceived: false,
    sql: false,
  });

  const list = [
    { name: 'Total', value: 'total', color: 'purple' },
    { name: 'Viewed', value: 'viewed', color: 'blue' },
    { name: 'Bidded', value: 'bidded', color: 'green' },
    { name: 'Declined', value: 'declined', color: 'red' },
    { name: 'Tech Support', value: 'techSupport', color: 'black' },
    { name: 'Answer Received', value: 'answerReceived', color: 'orange' },
    { name: 'SQL', value: 'sql', color: 'yellow' },
  ];

  const bg = useColorModeValue('#FFFFFF', '#252525');
  const color = useColorModeValue('#111111', '#ffffff');

  const data = useMemo(() => {
    let result: any = receivedStatistic?.map((item: any) => {
      return {
        date: moment(item.date).format('ddd'),
        fullDate: moment(item.date).format('dddd'),
        total: item.total,
        viewedPosts: item.viewedPosts,
        bidded: item.bidded,
        declined: item.declined,
        techSupport: item.techSupport,
        answerReceived: item.answerRecieved,
        sql: item.sql,
      };
    });
    return result;
  }, [receivedStatistic]);

  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: ['total', 'viewed', 'bidded', 'declined'],
  });

  useEffect(() => {
    let obj: any = {
      total: false,
      viewed: false,
      bidded: false,
      declined: false,
      techSupport: false,
      answerReceived: false,
      sql: false,
    };
    value.forEach((item: any) => {
      obj[item] = true;
    });
    setFilter(obj);
  }, [value]);

  useEffect(() => {
    console.log(filter);
  }, [filter]);

  return (
    <Flex
      align='center'
      flexWrap='wrap'
      borderRadius='10px'
      padding={{ base: '5px 10px', sm: '5px 10px' }}
    >
      <Box
        w='100%'
        maxW='840px'
        border='1px solid #D9E2EC'
        borderRadius='10px'
        padding='15px 30px'
      >
        <Text
          fontWeight='600'
          fontSize='14px'
          lineHeight='18px'
          textTransform='capitalize'
          color='#354052'
          mb='50px'
        >
          Total number of posts received
        </Text>
        <ResponsiveContainer width='100%' aspect={2}>
          <LineChart
            data={data}
            margin={{
              top: 15,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' color='#D9E2EC' />
            <XAxis dataKey='date' />
            <YAxis />
            <Tooltip
              contentStyle={{
                backgroundColor: bg,
                color: color,
                borderRadius: 10,
              }}
              content={<CustomTooltip />}
              itemStyle={{ color: color }}
              cursor={false}
            />
            {filter.total && (
              <Line
                type='monotone'
                dataKey='total'
                stroke='#8884d8'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: '#8884d8',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: '#8884d8',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
            {filter.viewed && (
              <Line
                type='monotone'
                dataKey='viewedPosts'
                stroke='#5755FF'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: '#5755FF',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: '#5755FF',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
            {filter.bidded && (
              <Line
                type='monotone'
                dataKey='bidded'
                stroke='#00CA20'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: '#00CA20',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: '#00CA20',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
            {filter.declined && (
              <Line
                type='monotone'
                dataKey='declined'
                stroke='#EB5757'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: '#EB5757',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: '#EB5757',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
            {filter.techSupport && (
              <Line
                type='monotone'
                dataKey='techSupport'
                stroke='#F5F5FF'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: '#F5F5FF',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: '#F5F5FF',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
            {filter.answerReceived && (
              <Line
                type='monotone'
                dataKey='answerReceived'
                stroke='orange'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: 'orange',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: 'orange',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
            {filter.sql && (
              <Line
                type='monotone'
                dataKey='sql'
                stroke='yellow'
                strokeWidth='2'
                dot={{
                  fill: '#ffffff',
                  stroke: 'yellow',
                  strokeWidth: 2,
                  r: 5,
                }}
                activeDot={{
                  fill: '#ffffff',
                  stroke: 'yellow',
                  strokeWidth: 1,
                  r: 4,
                }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box ml='30px'>
        <Box>
          <CheckboxGroup colorScheme='green' defaultValue={value}>
            <Stack
              spacing={[1, 3]}
              direction={{ base: 'row', sm: 'row', md: 'column' }}
              flexWrap='wrap'
            >
              {list.map((item, index) => {
                return (
                  <CustomCheckbox
                    key={index}
                    value={item.value}
                    color={item.color}
                    {...getCheckboxProps({ value: item.value })}
                  >
                    <Text color={color}>{item.name}</Text>
                  </CustomCheckbox>
                );
              })}
            </Stack>
          </CheckboxGroup>
        </Box>
      </Box>
    </Flex>
  );
}

export default NewLineChart;
