import React from 'react';
import { Icon } from '@chakra-ui/react';

export const HorizontalDotsIcon = (props: any) => (
  <Icon width='10px' height='3px' viewBox='0 0 10 3' fill='#758391' {...props}>
    <path
      d='M1.77716 2.091C1.53449 2.091 1.33849 2.021 1.18916 1.881C1.04449 1.741 0.972156 1.552 0.972156 1.314C0.972156 1.076 1.04449 0.887 1.18916 0.747C1.33382 0.607 1.52982 0.537 1.77716 0.537C2.01982 0.537 2.21349 0.609333 2.35816 0.754C2.50749 0.894 2.58216 1.08067 2.58216 1.314C2.58216 1.54733 2.50749 1.73633 2.35816 1.881C2.20882 2.021 2.01516 2.091 1.77716 2.091ZM5.00372 2.091C4.76105 2.091 4.56505 2.021 4.41572 1.881C4.27105 1.741 4.19872 1.552 4.19872 1.314C4.19872 1.076 4.27105 0.887 4.41572 0.747C4.56039 0.607 4.75639 0.537 5.00372 0.537C5.24639 0.537 5.44005 0.609333 5.58472 0.754C5.73405 0.894 5.80872 1.08067 5.80872 1.314C5.80872 1.54733 5.73405 1.73633 5.58472 1.881C5.43539 2.021 5.24172 2.091 5.00372 2.091ZM8.23028 2.091C7.98761 2.091 7.79161 2.021 7.64228 1.881C7.49761 1.741 7.42528 1.552 7.42528 1.314C7.42528 1.076 7.49761 0.887 7.64228 0.747C7.78695 0.607 7.98295 0.537 8.23028 0.537C8.47295 0.537 8.66661 0.609333 8.81128 0.754C8.96061 0.894 9.03528 1.08067 9.03528 1.314C9.03528 1.54733 8.96061 1.73633 8.81128 1.881C8.66195 2.021 8.46828 2.091 8.23028 2.091Z'
      fill='#354052'
    />
  </Icon>
);
