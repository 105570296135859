import { authStatusAction, signInAction } from '../actions';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import AuthService from '../../../services/auth.service';
import LocalStorageService from '../../../services/local-storage.service';
import { UserRoleEnum } from '../../../core/enums';
import { AxiosError } from 'axios';

interface SignInThunkPayload {
  email: string;
  password: string;
  rememberMe: boolean;
}

const signInThunk = (payload: SignInThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(authStatusAction({ signInStatus: 'running', error: null }));
      const response = await AuthService.signIn(payload);
      LocalStorageService.setAccessToken('access_token');
      dispatch(authStatusAction({ signInStatus: 'success', error: null }));
      if (response.status === 200 || response.status === 201) {
        LocalStorageService.setAccessToken(response.data.token);
        LocalStorageService.setUser({ ...response.data, token: undefined });
        dispatch(authStatusAction({ signInStatus: 'success' }));
      }
    } catch (error: AxiosError | any) {
      dispatch(
        authStatusAction({ signInStatus: 'error', error: error.response.data })
      );
    }
  };
};

export default signInThunk;
