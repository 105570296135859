import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Checkbox,
  Link,
  useToast,
  Box,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { DashboardRoute, ForgotPasswordRoute } from '../constants/routes';
import { CloseEyeIcon, EmailIcon, OpenEyeIcon } from '../icons';
import FormErrorMessage from './FormErrorMessage';
import LocalStorageService from '../services/local-storage.service';
import { StatusEnum, UserRoleEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import signInThunk from '../store/auth/thunk/signin';
import { getAuthStoreSelector } from '../store/auth/selector';
import { validateEmail, validatePassword } from '../validations';

const SignInForm = (): JSX.Element => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { signInStatus, error } = useSelector(getAuthStoreSelector);

  const loading = signInStatus === 'running';

  const handleSubmit = (values: any) => {
    dispatch(signInThunk(values));
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', rememberMe: false }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
      }}
    >
      <Form>
        <Field name='email' validate={validateEmail}>
          {({ field, form }: any) => (
            <FormControl
              id='email'
              isInvalid={form.errors.email && form.touched.email}
              marginBottom='30px'
            >
              <InputGroup>
                <Input type='email' {...field} placeholder='Email' />
                <InputRightElement h={'full'}>
                  <EmailIcon />
                </InputRightElement>
              </InputGroup>
              {form.errors.email && (
                <FormErrorMessage message={form.errors.email} />
              )}
            </FormControl>
          )}
        </Field>
        <Field name='password' validate={validatePassword}>
          {({ field, form }: any) => (
            <FormControl
              id='password'
              isInvalid={form.errors.password && form.touched.password}
              marginBottom='30px'
            >
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  placeholder='Password'
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {form.errors.password && (
                <FormErrorMessage message={form.errors.password} />
              )}
            </FormControl>
          )}
        </Field>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          align={'start'}
          justify={'space-between'}
          mb='10px'
        >
          <Field type='checkbox' name='rememberMe'>
            {({ field, form }: any) => (
              <FormControl id='rememberMe' marginBottom='30px'>
                <Checkbox {...field}>Remember me</Checkbox>
                {form.errors.rememberMe && (
                  <FormErrorMessage message={form.errors.rememberMe} />
                )}
              </FormControl>
            )}
          </Field>

          <Link
            color={'#5755FF'}
            onClick={() => {
              navigate(ForgotPasswordRoute);
            }}
          >
            Forgot password?
          </Link>
        </Stack>
        <Stack spacing={10} pt={2}>
          <Button
            type='submit'
            isLoading={loading}
            loadingText='Login..'
            size='lg'
            bg={'#5755FF'}
            color={'white'}
            _hover={{
              bg: '#3e3dd2',
            }}
            // onClick={() => {
            //   setIsLoading(!isLoading);
            // }}
          >
            Sign in
          </Button>
        </Stack>
      </Form>
    </Formik>
  );
};

export default SignInForm;
