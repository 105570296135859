import {
  getChannelsAction,
  getGroupChannelsAction,
  getGroupChannelsCountAction,
  setChannelStoreStatusAction,
} from '../actions';
import ChannelService from '../../../services/channel.service';
import { channels } from '../../../lib/channels';

import { RootStore } from '../..';
import { Dispatch } from 'redux';

const getGroupCountThunk = (): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(setChannelStoreStatusAction({ groupChannelsStatus: 'running' }));

      const response = await ChannelService.getStatusGroupCount();
      if (response.status === 200 || response.status === 201) {
        dispatch(
          getGroupChannelsCountAction({
            statusGroups: response.data.statusGroups,
          })
        );
        dispatch(
          setChannelStoreStatusAction({ groupChannelsStatus: 'success' })
        );
      }
    } catch (error) {
      dispatch(
        setChannelStoreStatusAction({
          groupChannelsStatus: 'error',
          error: error,
        })
      );
    }
  };
};

export default getGroupCountThunk;
