import React from 'react';
import { Icon } from '@chakra-ui/react';

export const EmptyBoxIcon = (props: any) => (
  <Icon
    width='64px'
    height='64px'
    viewBox='0 0 64 64'
    fill='#758391'
    {...props}
  >
    <path
      fill='#85cbf8'
      d='M51,43.77v5.82a2,2,0,0,1-1,1.72L33,61.39a2,2,0,0,1-2,0L14,51.31a2,2,0,0,1-1-1.72V43l16-5.61,3.08-1.08,3,1.16Z'
    />
    <path
      fill='#9fddff'
      d='M13.72 24.89L27.02 9.06 50.41 24.89 31.09 36.97 13.72 24.89z'
    />
    <path
      fill='#7bbeeb'
      d='M51,44.77v2.17l-5.58,3.67a1,1,0,0,1-1.19-.07L32.71,40.85a1,1,0,0,0-1.29,0L19.9,50.54a1,1,0,0,1-1.19.07L13,46.85V44l16-7.61h6.16Z'
    />
    <path
      fill='#85cbf8'
      d='M61.86 14L45.42 3.16a1 1 0 0 0-1.19.07L32.07 13.47 50.42 24.89l11.52-9.3A1 1 0 0 0 61.86 14zM32.07 13.47L19.9 3.23a1 1 0 0 0-1.19-.07L2.28 14a1 1 0 0 0-.08 1.61l11.52 9.3 10.36-6.44'
    />
    <path
      fill='#85cbf8'
      d='M32.07,36.31,19.9,46.54a1,1,0,0,1-1.19.07L2.28,35.8a1,1,0,0,1-.08-1.61l11.52-9.3L32.07,36.31,50.42,24.89l11.52,9.3a1,1,0,0,1-.08,1.61L45.42,46.61a1,1,0,0,1-1.19-.07L32.07,36.31Z'
    />
    <path
      fill='#8d6c9f'
      d='M27.35,17.4a1,1,0,0,0,.53-.15l1.35-.84a1,1,0,1,0-1.06-1.7l-1.35.84a1,1,0,0,0,.53,1.85Z'
    />
    <path
      fill='#8d6c9f'
      d='M52,24.89l10.55-8.52a2,2,0,0,0-.16-3.23L46,2.33a2,2,0,0,0-2.39.14L32.07,12.16,20.55,2.47a2,2,0,0,0-2.39-.14L1.73,13.14a2,2,0,0,0-.16,3.23l10.55,8.52L1.57,33.41a2,2,0,0,0,.16,3.23L12,43.39v6.2a3,3,0,0,0,1.47,2.58l17,10.08a3,3,0,0,0,3.06,0l17-10.08A3,3,0,0,0,52,49.59V43.77a1,1,0,0,0-.05-.25L62.4,36.63a2,2,0,0,0,.16-3.23ZM44.87,4,61.31,14.81l-11,8.85L33.76,13.35ZM19.26,4,31.42,14.23l.07,0,0,0,17,10.57L32.15,35.08,32.07,35l-.09.07L15.61,24.89l9-5.6a1,1,0,1,0-1.06-1.7l-9.75,6.07-11-8.85ZM13.79,26.11,30.37,36.43,19.26,45.78,2.83,35ZM50,49.59a1,1,0,0,1-.49.86l-17,10.08a1,1,0,0,1-1,0l-17-10.08a1,1,0,0,1-.49-.86V44.71l4.16,2.74a2,2,0,0,0,2.39-.14l11.52-9.69,11.52,9.69a2,2,0,0,0,2.39.14l4-2.65Zm-5.13-3.81L33.76,36.43,50.34,26.11l11,8.85Z'
    />
    <path
      fill='#8d6c9f'
      d='M33 41H31a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM33 46H31a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM33 51H31a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM33 56H31a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z'
    />
  </Icon>
);
