import moment from 'moment';
import { StatusEnum } from '../../core/enums';

export const initialState = {
  getReceivedStatus: StatusEnum.initial,
  getPostsStatus: StatusEnum.initial,
  receivedStatistic: [],
  postsStatistic: {
    leadgens: [],
    statusGroups: [],
  },
  fromReceivedDate: moment().subtract(6, 'days').startOf('day').toISOString(),
  toReceivedDate: moment().endOf('day').toISOString(),
  fromPostDate: moment().subtract(3, 'months').startOf('day').toISOString(),
  toPostDate: moment().endOf('day').toISOString(),

  error: null,
};
