import { RootStore } from '../..';
import { Dispatch } from 'redux';
import PostService from '../../../services/post.service';
import { AxiosError } from 'axios';
import { StatusEnum, StatusGroupEnum } from '../../../core/enums';
import moment from 'moment';
import {
  setStatisticStoreStatusAction,
  getPostsStatisticAction,
} from '../actions';

interface getPostsThunkPayload {
  from: Date;
  to: Date;
}

const getPostsStatisticThunk = ({ from, to }: getPostsThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setStatisticStoreStatusAction({
          getPostsStatus: StatusEnum.running,
          error: null,
        })
      );

      const options = {
        from: moment(from).utc(true).startOf('day').toISOString(),
        to: moment(to).utc(true).endOf('day').toISOString(),
      };

      const response = await PostService.getPostsStatisticByFilter(options);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          setStatisticStoreStatusAction({ getPostsStatus: StatusEnum.success })
        );
        dispatch(
          getPostsStatisticAction({
            postsStatistic: response.data,
          })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setStatisticStoreStatusAction({
          getPostsStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getPostsStatisticThunk;
