import React from 'react';
import { Icon } from '@chakra-ui/react';

export const ErrorIcon = (props: any) => (
  <Icon
    width='16px'
    height='16px'
    viewBox='0 0 16 16'
    fill='#394452'
    {...props}
  >
    <mask
      id='mask0_13_2007'
      maskUnits='userSpaceOnUse'
      x='1'
      y='1'
      width='14'
      height='14'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM7.99992 8.66671C7.63325 8.66671 7.33325 8.36671 7.33325 8.00004V5.33337C7.33325 4.96671 7.63325 4.66671 7.99992 4.66671C8.36659 4.66671 8.66659 4.96671 8.66659 5.33337V8.00004C8.66659 8.36671 8.36659 8.66671 7.99992 8.66671ZM7.33325 10V11.3334H8.66659V10H7.33325Z'
      />
    </mask>
    <g mask='url(#mask0_13_2007)'>
      <rect width='16' height='16' fill='#394452' />
    </g>
  </Icon>
);
