import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ErrorIcon } from '../icons';

interface FormErrorMessageProps {
  message: string;
}

const FormErrorMessage = ({ message }: FormErrorMessageProps): JSX.Element => {
  return (
    <Box
      backgroundColor='#EBEEF2'
      borderRadius='8px'
      p='6px 8px 6px 16px'
      mt='8px'
      mb='8px'
    >
      <Flex alignItems='center'>
        <ErrorIcon />
        <Text color='#394452' fontSize='13px' ml='5px'>
          {message}
        </Text>
      </Flex>
    </Box>
  );
};

export default FormErrorMessage;
