import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth/reducer';
import channelReducer from './channel/reducer';
import commentReducer from './comment/reducer';
import postsReducer from './post/reducer';
import statisticReducer from './statistic/reducer';
import userReducer from './user/reducer';

export const rootReducer = combineReducers({
  authStore: authReducer,
  channelStore: channelReducer,
  userStore: userReducer,
  postStore: postsReducer,
  commentStore: commentReducer,
  statisticStore: statisticReducer,
});

const composeEnhancers =
  (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export const rootStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
export type RootStore = ReturnType<typeof rootReducer>;

export type ActionType = {
  type: string;
  payload?: any;
};
