import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 100;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-ExtraLight.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 200;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-Thin.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 300;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-Light.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 400;
        src: 
          url('assets/fonts/EncodeSans/EncodeSans-Regular.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 500;
        src: 
          url('assets/fonts/EncodeSans/EncodeSans-Medium.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 600;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-SemiBold.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 700;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-Bold.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 800;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-Bold.ttf') format('ttf');
      }
      
      @font-face {
        font-family: 'Encode Sans';
        font-style: normal;
        font-weight: 900;
        src:  
          url('assets/fonts/EncodeSans/EncodeSans-ExtraBold.ttf') format('ttf');
      }
      `}
  />
);

export default Fonts;
