import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  useDisclosure,
  NumberInput,
  NumberInputField,
  Select,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import FormErrorMessage from './FormErrorMessage';
import { StatusEnum, StatusGroupEnum, UserRoleEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage, validateRequired } from '../validations';
import { getPostStoreSelector } from '../store/post/selector';
import transferPostThunk from '../store/post/thunk/transfer-post';
import SelectLeadgenPopup from './SelectLeadgenPopup';
import getAllLeadGensThunk from '../store/user/thunk/get-all-leadgens';
import localStorageService from '../services/local-storage.service';
import { setClientInfoAction } from '../store/post/actions';
import { PostModel } from '../models/post';
import { MultiSelect, useMultiSelect } from 'chakra-multiselect';
import { TechStackModel } from '../models/techStack';

interface AddAnswerRecievedClientProps {
  post: PostModel | null;
  techStacks: TechStackModel[],
  isOpen: boolean;
  onClose: () => any;
}

const AddAnswerRecievedClientPopup = ({
  isOpen,
  post,
  techStacks,
  onClose,
}: AddAnswerRecievedClientProps): JSX.Element => {
  const dispatch = useDispatch();
  const user = localStorageService.getUser();
  const { transferStatus, clientInfo, channel,
    search,
    page,
    take,
    from,
    to,isAcs, orderColumn} = useSelector(getPostStoreSelector);
  const { isOpen: isOpenLeadgen, onOpen: onOpenLeadgen, onClose: onCloseLeadgen } = useDisclosure();

  const { value, options, onChange } = useMultiSelect({
    value: [],
    options: []
  });

  const handleCancelLeadgen = () => {
    onCloseLeadgen();
    onClose();
  };

  const format = (val: number) => {
    if(`${val}`.includes('$')){
      const value = `${val}`.replace(/^\$/, '');
      return `$` + value;
    }
    return `$` + val;
  }
  const closeModalClick = () => {
    onClose();
    if(onChange){
      onChange([]);
    }
  }

  const handleSubmit = (values: any) => {
    if (user.role === UserRoleEnum.Sales) {
      dispatch(
        setClientInfoAction({
          postId: post!.id,
          channelId: post!.channelId,
          name: values.name,
          agency: values.agency,
          rate: values.rate,
          techStacks:(value as any[]).map(x=>x.value.split('|')[0])
        })
      );
      dispatch(getAllLeadGensThunk());
      onOpenLeadgen();
      return;
    }

    dispatch(
      transferPostThunk({
        postId: clientInfo.postId,
        channelId: clientInfo.channelId,
        sourceStatusGroup: StatusGroupEnum.Bidded,
        destinationStatusGroup: StatusGroupEnum.AnswerReceived,
        shouldRemoveFromSource: false,
        clientInfo: {
          name: values.name,
          agency: values.agency,
          rate: values.rate.replace(/^\$/, ''),
          techStacks:(value as any[]).map(x=>x.value.split('|')[0])
        },
        payload:{
          page: page,
          take: take,
          statusGroup: StatusGroupEnum.Bidded,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      })
    );
  };
  useEffect(() => {
    if (transferStatus === StatusEnum.success) {
      onClose();
    }
    const timeout = setTimeout(() => {
      const input = document.getElementById('techStack');
      if(input){
        input.style.minWidth = '465px';
        clearTimeout(timeout);
      }
    }, 100);
   

  }, [transferStatus, onClose]);
  return (
    <Modal
      size='lg'
      isCentered
      onClose={closeModalClick}
      isOpen={isOpen}
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Add client info</ModalHeader>
        <ModalCloseButton color='#5755FF' />
        <ModalBody>
          <Formik
            initialValues={{
              name: '',
              agency: '',
              rate: 0,
              techStack: ''
            }}
            onSubmit={(values, actions) => {
              handleSubmit({ ...values });
            }}
          >
            <Form>
              <Field name='name' validate={validateRequired}>
                {({ field, form }: any) => (
                  <FormControl
                    id='name'
                    isInvalid={form.errors.name && form.touched.name}
                    marginBottom='30px'
                  >
                    <FormLabel fontWeight='600'>Name</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                    </InputGroup>
                    {form.errors.name && (
                        <FormErrorMessage message={getErrorMessage('Name', form.errors.name)} />
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name='agency' validate={validateRequired}>
                {({ field, form }: any) => (
                  <FormControl
                    id='agency'
                    isInvalid={form.errors.agency && form.touched.agency}
                    marginBottom='30px'
                  >
                    <FormLabel fontWeight='600'>Agency</FormLabel>
                    <InputGroup>
                    <InputGroup>
                        <Select placeholder='Select angency' {...field}>
                          <option>Anuitex</option>
                          <option>Aixence</option>
                          <option>Acvence</option>
                          <option>Aillene</option>
                        </Select>
                    </InputGroup>
                    </InputGroup>
                    {form.errors.agency && (
                      <FormErrorMessage message={getErrorMessage('Agency', form.errors.agency)} />
                    )}
                  </FormControl>
                )}
              </Field>

              <Field name='rate' validate={validateRequired}>
                {({ field, form }: any) => (
                  <FormControl
                    id='rate'
                    isInvalid={form.errors.rate && form.touched.rate}
                    marginBottom='30px'
                  >
                    <FormLabel fontWeight='600'>Rate</FormLabel>
                    <InputGroup>
                    <NumberInput min={0}  width={'100%'}
                      value={format(field.value)}>
                      <NumberInputField  {...field} />
                    </NumberInput>
                    </InputGroup>
                    {form.errors.rate && (
                      <FormErrorMessage message={getErrorMessage('Rate', form.errors.rate)} />
                    )}
                  </FormControl>
                )}
              </Field>

             
              <Field name='techStack'>
                {({ field, form }: any) => (
                  <FormControl
                    id='techStack'
                    isInvalid={form.errors.techStack && form.touched.techStack}
                    marginBottom='30px'
                  >
                    <FormLabel fontWeight='600'>Tech stack</FormLabel>
                    <InputGroup>
                      <MultiSelect 
                          options={ techStacks.map(stack =>({value: `${stack.id}|${stack.name}`, label: stack.name})) }
                          value={value}
                          label='Choose or create items'
                          onChange={onChange!}
                        />
                    </InputGroup>
                    {form.errors.techStack && (
                      <FormErrorMessage message={getErrorMessage('Tech Stack', form.errors.techStack)} />
                    )}
                  </FormControl>
                )}
              </Field>

              <Wrap spacing='30px' justify='center' mb='25px'>
                <WrapItem>
                  <Button
                    width='172px'
                    height='48px'
                    border='1px solid #413fe3'
                    borderRadius='8px'
                    bg='transparent'
                    color='#413fe3'
                    fontSize='15px'
                    fontWeight='600'
                    onClick={closeModalClick}
                  >
                    Cancel
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    width='172px'
                    height='48px'
                    borderRadius='8px'
                    bg='#5755FF'
                    isLoading={transferStatus === StatusEnum.running}
                    loadingText='Wait...'
                    color='#ffffff'
                    fontSize='15px'
                    fontWeight='600'
                    _hover={{
                      bg: '#413fe3',
                    }}
                    _active={{
                      bg: '#5755FF',
                    }}
                    type='submit'
                  >
                    Save
                  </Button>
                </WrapItem>
              </Wrap>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
      <SelectLeadgenPopup
        sourceStatusGroup={StatusGroupEnum.Bidded}
        destinationStatusGroup={StatusGroupEnum.AnswerReceived}
        shouldRemoveFromSource={false}
        post={post}
        isOpenLeadgen={isOpenLeadgen}
        onCloseLeadgen={onCloseLeadgen}
        onCancelLeadgen={handleCancelLeadgen}
      />
    </Modal>
  );
};

export default AddAnswerRecievedClientPopup;
