class LocalStorageService {
  // tokens
  public setTokens = (access_token: string, refresh_token: string = '') => {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
  };
  public setAccessToken = (access_token: string) => {
    localStorage.setItem('access_token', access_token);
  };
  public setRefreshToken = (refresh_token: string) => {
    localStorage.setItem('refresh_token', refresh_token);
  };
  public getAccessToken = () => {
    return localStorage.getItem('access_token');
  };
  public getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
  };

  public clearTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  // user
  public setUser = (user: any) => {
    localStorage.setItem('user', JSON.stringify(user));
  };
  public getUser = () => {
    let user: any = localStorage.getItem('user');
    return JSON.parse(user);
  };
  public removeUser = () => {
    localStorage.removeItem('user');
  };

  public clearLocalStorage = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  };
  public setExpandMenu = (isExpanded: boolean) => {
    localStorage.setItem('expand-menu', JSON.stringify(isExpanded));
  };
  public getExpandMenu = (): boolean => {
    const key = localStorage.getItem('expand-menu');
    if (!key) {
      this.setExpandMenu(true);
      return true;
    }
    return !!JSON.parse(key);
  };
}

export default new LocalStorageService();
