import { SET_USER_STORE_STATUS } from '../user/types';
import {
  GetPostsAction,
  GetPostsActionPayload,
  GET_POSTS,
  SetPostsChannelAction,
  SetPostsChannelActionPayload,
  SetPostsPageAction,
  SetPostsPageActionPayload,
  SetPostsSortAction,
  SetPostsSortActionPayload,
  SetPostStoreStatusAction,
  SetPostStoreStatusActionPayload,
  SetSearchPostsAction,
  SetSearchPostsActionPayload,
  SET_POSTS_CHANNEL,
  SET_POSTS_PAGE,
  SET_POSTS_SORT,
  SET_POSTS_STORE_STATUS,
  SET_POST_DATES,
  SET_POST_TAKE,
  SET_SEARCH_POSTS,
  UPDATE_POSTS,
  SET_CLIENT_INFO,
  SetClientInfoPostPayload,
  SetClientInfoPostAction,
  SetGenerateAnswerPostStoreStatusActionPayload,
  SET_GENERATE_ANSWER_POST_STATUS,
  SetGenerateAnswerPostStoreActionPayload,
  SET_GENERATE_ANSWER_POST,
  SetGenerateAnswerStoreStatusAction,
  SetGenerateAnswerPostStoreAction,
  SetUsePostRouteActionPayload,
  SetUsePostRouteAction,
  SET_USE_POST_ROUTE_KEY,
  SetCurrentStatusGroupTypeAction,
  SetCurrentStatusGroupTypeActionPayload,
  SET_CURRENT_STATUS_GROUP_TYPE_KEY,
  GetTechStackAction,
  GetTechStackActionPayload,
  GET_TECH_STACKS,
} from './types';

export const updatePostsAction = (payload: any) => ({
  type: UPDATE_POSTS,
  payload: payload,
});

export const setPostsDatesAction = (payload: any) => ({
  type: SET_POST_DATES,
  payload: payload,
});

export const setSearchPostsAction = (
  payload: SetSearchPostsActionPayload
): SetSearchPostsAction => ({
  type: SET_SEARCH_POSTS,
  payload: payload,
});

export const setPostsSortAction = (
  payload: SetPostsSortActionPayload
): SetPostsSortAction => ({
  type: SET_POSTS_SORT,
  payload: payload,
});

export const setTakePostsAction = (payload: any) => ({
  type: SET_POST_TAKE,
  payload: payload,
});

export const setPostsStoreStatusAction = (
  payload: SetPostStoreStatusActionPayload
): SetPostStoreStatusAction => ({
  type: SET_POSTS_STORE_STATUS,
  payload: payload,
});

export const setPostsChannelAction = (
  payload: SetPostsChannelActionPayload
): SetPostsChannelAction => ({
  type: SET_POSTS_CHANNEL,
  payload: payload,
});

export const setPostsPageAction = (
  payload: SetPostsPageActionPayload
): SetPostsPageAction => ({
  type: SET_POSTS_PAGE,
  payload: payload,
});

export const getPostsAction = (
  payload: GetPostsActionPayload
): GetPostsAction => ({
  type: GET_POSTS,
  payload: payload,
});

export const getTechStacksAction = (
  payload: GetTechStackActionPayload
): GetTechStackAction => ({
  type: GET_TECH_STACKS,
  payload: payload,
});

export const setClientInfoAction = (
  payload: SetClientInfoPostPayload | null
): SetClientInfoPostAction => ({
  type: SET_CLIENT_INFO,
  payload: payload,
});

export const setGeneratedAnswerPostStoreStatusAction = (
  payload: SetGenerateAnswerPostStoreStatusActionPayload
): SetGenerateAnswerStoreStatusAction => ({
  type: SET_GENERATE_ANSWER_POST_STATUS,
  payload: payload,
});

export const setGeneratedAnswerPostStoreAction = (
  payload: SetGenerateAnswerPostStoreActionPayload
): SetGenerateAnswerPostStoreAction => ({
  type: SET_GENERATE_ANSWER_POST,
  payload: payload,
});

export const setUsePostRouteAction = (
  payload: SetUsePostRouteActionPayload
): SetUsePostRouteAction => ({
  type: SET_USE_POST_ROUTE_KEY,
  payload: payload,
});

export const setCurrentStatusGroupTypeAction = (
  payload: SetCurrentStatusGroupTypeActionPayload
): SetCurrentStatusGroupTypeAction => ({
  type: SET_CURRENT_STATUS_GROUP_TYPE_KEY,
  payload: payload,
});