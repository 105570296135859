import React, { useEffect } from 'react';
import { Wrap, WrapItem, Button, useDisclosure, Box } from '@chakra-ui/react';
import { PlusIcon } from '../icons';

import ContentLayout from '../layouts/content';
import AddNewChannelPopup from '../components/AddNewChannel';
import ChannelTable from '../components/tables/ChannelTable';
import { useDispatch, useSelector } from 'react-redux';
import { getChannelStoreSelector } from '../store/channel/selector';
import getAllChannelsThunk from '../store/channel/thunk/get-all-channels';
import useChannelToasts from '../hooks/useChannelToasts';
import useSignalR from '../hooks/useSignalR';

const SettingsChannelsContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  useChannelToasts();
  useSignalR();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { channels, status } = useSelector(getChannelStoreSelector);

  const loading = status === 'running';

  useEffect(() => {
    dispatch(getAllChannelsThunk());
  }, [dispatch]);

  return (
    <Box
      as='main'
      height='calc(100vh - 60px)'
      w='100%'
      p={{ base: '10px 2px', sm: '32px' }}
      overflow='hidden'
    >
      <ContentLayout
        title='Settings - Channels'
        headerChildren={
          <Wrap>
            <WrapItem>
              <Button
                leftIcon={<PlusIcon fill='#ffffff' />}
                borderRadius='8px'
                bg='#5755FF'
                color='#ffffff'
                fontSize='15px'
                _hover={{
                  bg: '#413fe3',
                }}
                _active={{
                  bg: '#5755FF',
                }}
                textTransform='capitalize'
                onClick={onOpen}
              >
                Add New Channel
              </Button>
            </WrapItem>
          </Wrap>
        }
      >
        <ChannelTable channels={channels} isLoading={loading} />
      </ContentLayout>
      <AddNewChannelPopup isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default SettingsChannelsContainer;
