import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import localStorageService from '../services/local-storage.service';
import CONFIG from '../config/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGroupChannelsAction,
  getGroupChannelsCountAction,
  updateDifferencesAction,
} from '../store/channel/actions';
import useAuth from '../hooks/useAuth';
import { getChannelStoreSelector } from '../store/channel/selector';
import { StatusGroupEnum } from '../core/enums';
import { getPostStoreSelector } from '../store/post/selector';
import { PostModel } from '../models/post';
import { getPostsAction } from '../store/post/actions';
import moment from 'moment';

const useSignalR = (sourceType?: StatusGroupEnum) => {
  const [connection, setConnection] = useState<null | signalR.HubConnection>(
    null
  );
  const auth = useAuth();
  const dispatch = useDispatch();

  const { differences, statusGroups } = useSelector(getChannelStoreSelector);
  const { currentStatusGroupType, channel, posts, take, total } = useSelector(getPostStoreSelector);

  const user = localStorageService.getUser();
  const access_token = localStorageService.getAccessToken();

  useEffect(() => {
    const connect = new signalR.HubConnectionBuilder()
      .withUrl(`${CONFIG.SIGNALR_URL}?userId=${user?.id}&role=${user?.role}`, {
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => access_token ?? '',
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();

    setConnection(connect);
  }, [user.id, user.role, access_token]);

  useEffect(() => {
    if (connection) {
      connection.off('ActualJobsCount');
      connection.off('ChangedJobs');
      if (auth) {
        if (connection.state === signalR.HubConnectionState.Disconnected) {
          console.log('Disconnected');

          connection
            .start()
            .then(() => {
              console.log('Connection started');
            })
            .catch((error: any) => console.log(error));
        }
        if (connection.state === signalR.HubConnectionState.Connected) {
          console.log('Connected');
        }
      }
      if (!auth) {
        if (connection.state === signalR.HubConnectionState.Connected) {
          connection
            .stop()
            .then(() => {
              console.log('Disconnecting started');
            })
            .catch((error: any) => console.log(error));
        }
        if (connection.state === signalR.HubConnectionState.Disconnected) {
          console.log('Disconnected');
        }
      }

      connection.on('ActualJobsCount', (message: any) => {
        console.log('ActualJobsCount: ', message);

        let newDifferences: number[] = [];

        statusGroups.forEach((sg: any, index: number) => {
          if (message.statusGroups[index].count > sg.count) {
            newDifferences.push(message.statusGroups[index].count - sg.count);
          } else {
            newDifferences.push(0);
          }
        });
        const sum = differences.map(function (num: number, idx: number) {
          return num + newDifferences[idx];
        });
        dispatch(updateDifferencesAction({ differences: sum }));
        if (sourceType === StatusGroupEnum.New) {
          dispatch(
            getGroupChannelsAction({
              groupChannels: message.statusGroups[1].chnnels,
            })
          );
        }
        if (sourceType === StatusGroupEnum.Old) {
          dispatch(
            getGroupChannelsAction({
              groupChannels: message.statusGroups[2].chnnels,
            })
          );
        }
        if (sourceType === StatusGroupEnum.PotentialTechnologies) {
          dispatch(
            getGroupChannelsAction({
              groupChannels: message.statusGroups[3].chnnels,
            })
          );
        }
        dispatch(
          getGroupChannelsCountAction({
            statusGroups: message.statusGroups,
          })
        );
      });
      connection.on('ChangedJobs', (model: any) => {
        console.log('ChangedJobs', model)
        if (currentStatusGroupType !== model.statusGroupType || posts.length === 0) {
          return
        }
        if (channel === null) {
          const newPosts = (model.channels as any[])
            .reduce((previous, current) => ([...previous, ...current.jobs]), []);
          if (newPosts.length === 0) {
            return;
          }
          
          const jobs: PostModel[] = getMappedJobs((newPosts as any[]));
          const postsUpdated: PostModel[] = ([...jobs, ...posts]).slice(0, take);
          dispatch(
            getPostsAction({
              posts: postsUpdated,
              total: total + jobs.length
            })
          );
        } else {
          const channelsIndex = (model.channels as any[]).findIndex(item => item.id === channel);
          if (channelsIndex !== -1) {
            const jobs: PostModel[] = getMappedJobs((model.channels as any[])[channelsIndex]);
            if (jobs.length === 0) {
              return;
            }
            const postsUpdated: PostModel[] = ([...jobs, ...posts]).slice(0, take);
            dispatch(
              getPostsAction({
                posts: postsUpdated,
                total: total + jobs.length,
              })
            );
          }

        }

      });
    }
  }, [connection, differences, statusGroups, sourceType, dispatch, auth]);

  useEffect(() => {
    //    for update differences on change page
    dispatch(
      updateDifferencesAction({ differences: [0, 0, 0, 0, 0, 0, 0, 0] })
    );

    return () => {
      if (connection) {
        if (
          connection.state === signalR.HubConnectionState.Connected ||
          connection.state === signalR.HubConnectionState.Connecting
        ) { 
          connection.off('ActualJobsCount');
          connection.off('ChangedJobs');
          connection
            .stop()
            .then(() => { })
            .catch((error: any) => console.log(error));
        }
      }
    };
  }, [connection, dispatch]);
};
const getMappedJobs = (jobs: any[]): PostModel[] => {
  return jobs
    .map((item: any) => ({
      id: item.id,
      description: item.description,
      createdDate: item.createdDate,
      title: item.title,
      leadGens: item.leadGens,
      channelTitle: item.channelTitle,
      link: item.link,
      isAnswerReceived: item.isAnswerReceived,
      channelId: item.channelId,
      declineMessage: item.declineMessage,
      declineMessageLeadgen: item.declineMessageLeadgen ?? [],
      techSupportMessageLeadgen: item.techSupportMessageLeadgen ?? []
    })).sort((first: PostModel, second: PostModel) =>  {
      if(moment(first.createdDate).utc(true).isAfter(moment(second.createdDate).utc(true))){
        return -1
      } else{
        return 0
      }
    });
}
export default useSignalR;
