import { getTechStacksAction, setPostsStoreStatusAction } from '../actions';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import TechStackService from '../../../services/tech-stack.service';
import { AxiosError } from 'axios';
import { StatusEnum } from '../../../core/enums';

const getTechStackThunk = (): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setPostsStoreStatusAction({
          status: StatusEnum.running,
          error: null,
        })
      );
      const response = await TechStackService.getTechStacks();
      if (response.status === 200 || response.status === 201) {
        dispatch(
          getTechStacksAction({
            techStacks: response.data.techStacks,
          })
        );
      }
    } catch (error: AxiosError | any) {
    }
  };
};

export default getTechStackThunk;
