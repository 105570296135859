import React from 'react';
import { Flex, VStack, Text, Button, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordSentIcon } from '../icons';
import { SignInRoute } from '../constants/routes';

interface SuccessForgotPasswordProps {
  email: string;
}

const SuccessForgotPassword = ({
  email,
}: SuccessForgotPasswordProps): JSX.Element => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(SignInRoute);
  };
  return (
    <Flex alignItems='center' justifyContent='center'>
      <Stack spacing={4} w={'full'} maxW={'md'} p={6} my={12}>
        <VStack spacing={5}>
          <ForgotPasswordSentIcon />
          <Text
            textAlign='center'
            color='#758391'
            fontSize='16px'
            lineHeight='24px'
          >
            An email with password reset instructions has been sent to
            <Text
              as='span'
              fontWeight='bold'
              color='#354052'
              fontSize='16px'
              lineHeight='24px'
            >
              {' '}
              {email}
            </Text>
          </Text>
        </VStack>
        <Button
          type='submit'
          loadingText='Submitting'
          size='lg'
          bg={'#5755FF'}
          color={'white'}
          _hover={{
            bg: '#3e3dd2',
          }}
          onClick={handleBack}
        >
          OK
        </Button>
      </Stack>
    </Flex>
  );
};

export default SuccessForgotPassword;
