import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { CloseEyeIcon, OpenEyeIcon } from '../icons';
import FormErrorMessage from './FormErrorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthStoreSelector } from '../store/auth/selector';
import { StatusEnum } from '../core/enums';
import { SuccessResetPasswordRoute } from '../constants/routes';
import resetPasswordThunk from '../store/auth/thunk/reset-password';
import { validateEmail, validatePassword } from '../validations';
import { authStatusAction } from '../store/auth/actions';

interface ResetPasswordFormProps {
  token: string;
  email: string;
}

const ResetPasswordForm = ({
  token,
  email,
}: ResetPasswordFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const { resetPasswordStatus } = useSelector(getAuthStoreSelector);

  const handleSubmit = (values: any) => {
    const body = {
      token: token,
      email: email,
      password: values.newPassword,
    };
    if (values.newPassword === values.repeatPassword) {
      dispatch(resetPasswordThunk(body));
    }
  };

  return (
    <Formik
      initialValues={{ newPassword: '', repeatPassword: '' }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      <Form>
        <Field name='newPassword' validate={validatePassword}>
          {({ field, form }: any) => (
            <FormControl
              id='newPassword'
              isInvalid={form.errors.newPassword && form.touched.newPassword}
              marginBottom='30px'
            >
              <InputGroup>
                <Input
                  type={showNewPassword ? 'text' : 'password'}
                  {...field}
                  placeholder='New Password'
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowNewPassword((showPassword) => !showPassword)
                    }
                  >
                    {showNewPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {form.errors.newPassword && (
                <FormErrorMessage message={form.errors.newPassword} />
              )}
            </FormControl>
          )}
        </Field>
        <Field name='repeatPassword' validate={validatePassword}>
          {({ field, form }: any) => (
            <FormControl
              id='repeatPassword'
              isInvalid={
                form.errors.repeatPassword && form.touched.repeatPassword
              }
              marginBottom='30px'
            >
              <InputGroup>
                <Input
                  type={showRepeatPassword ? 'text' : 'password'}
                  {...field}
                  placeholder='Repeat Password'
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowRepeatPassword((showPassword) => !showPassword)
                    }
                  >
                    {showRepeatPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {form.errors.repeatPassword && (
                <FormErrorMessage message={form.errors.repeatPassword} />
              )}
            </FormControl>
          )}
        </Field>
        <Stack spacing={10} pt={2}>
          <Button
            type='submit'
            disabled={resetPasswordStatus === StatusEnum.running}
            isLoading={resetPasswordStatus === StatusEnum.running}
            loadingText='Wait...'
            size='lg'
            bg={'#5755FF'}
            color={'white'}
            _hover={{
              bg: '#3e3dd2',
            }}
          >
            Set new password
          </Button>
        </Stack>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
