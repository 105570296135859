import {
  AddChannelAction,
  AddChannelActionPayload,
  ADD_CHANNEL,
  GetChannelsAction,
  GetChannelsActionPayload,
  GetGroupChannelsAction,
  GetGroupChannelsActionPayload,
  GET_CHANNELS,
  GET_GROUP_CHANNELS,
  GET_GROUP_CHANNELS_COUNT,
  SetChannelStoreStatusActionPayload,
  SET_CHANNEL_STORE_STATUS,
  UpdateChannelAction,
  UpdateChannelActionPayload,
  UpdateChannelStatusAction,
  UpdateChannelStatusActionPayload,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_STATUS,
  UPDATE_DIFFERENCES,
} from './types';

export const updateDifferencesAction = (payload: any) => ({
  type: UPDATE_DIFFERENCES,
  payload: payload,
});

export const setChannelStoreStatusAction = (
  payload: SetChannelStoreStatusActionPayload
): any => ({
  type: SET_CHANNEL_STORE_STATUS,
  payload: payload,
});

export const addChannelAction = (
  payload: AddChannelActionPayload
): AddChannelAction => ({
  type: ADD_CHANNEL,
  payload: payload,
});

export const getChannelsAction = (
  payload: GetChannelsActionPayload
): GetChannelsAction => ({
  type: GET_CHANNELS,
  payload: payload,
});

export const getGroupChannelsAction = (
  payload: GetGroupChannelsActionPayload
): GetGroupChannelsAction => ({
  type: GET_GROUP_CHANNELS,
  payload: payload,
});

export const getGroupChannelsCountAction = (payload: any): any => ({
  type: GET_GROUP_CHANNELS_COUNT,
  payload: payload,
});

export const updateChannelAction = (
  payload: UpdateChannelActionPayload
): UpdateChannelAction => ({
  type: UPDATE_CHANNEL,
  payload: payload,
});

export const updateChannelStatusAction = (
  payload: UpdateChannelStatusActionPayload
): UpdateChannelStatusAction => ({
  type: UPDATE_CHANNEL_STATUS,
  payload: payload,
});
