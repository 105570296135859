import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  Select,
  useColorModeValue,
  Input,
  Text,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { DeclineReasonType, StatusGroupEnum, UserRoleEnum } from '../core/enums';
import transferPostThunk from '../store/post/thunk/transfer-post';
import { useDispatch, useSelector } from 'react-redux';
import SelectLeadgenPopup from './SelectLeadgenPopup';
import localStorageService from '../services/local-storage.service';
import getAllLeadGensThunk from '../store/user/thunk/get-all-leadgens';
import { getPostStoreSelector } from '../store/post/selector';

interface SelectDeclinedReasonPopupProps {
  sourceStatusGroup: StatusGroupEnum;
  post: any;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const SelectDeclinedReasonPopup = ({
  isOpen,
  onClose,
  onCancel,
  post,
  sourceStatusGroup,
}: SelectDeclinedReasonPopupProps): JSX.Element => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<DeclineReasonType>(
    DeclineReasonType.LowBudget
  );
  const { isOpen: isOpenLeadgen, onOpen: onOpenLeadgen, onClose: onCloseLeadgen } = useDisclosure();
  const [reasonMessage, setReasonMessage] = useState('');
  const {channel,
    search,
    page,
    take,
    from,
    to, orderColumn, isAcs } = useSelector(getPostStoreSelector);
  const user = localStorageService.getUser();

  const items = [
    { title: 'Low Budget', value: DeclineReasonType.LowBudget },
    {
      title: 'No Available Developers',
      value: DeclineReasonType.NoAvailableDevelopers,
    },
    { title: 'Not Our Tech Stack', value: DeclineReasonType.NotOurTechStack },
    { title: 'Low Workload', value: DeclineReasonType.LowWorkload },
    { title: 'Other', value: DeclineReasonType.Other },
  ];

  const color = useColorModeValue('#354052', '#FFFFFF');
  const buttonColor = useColorModeValue('#413fe3', '#ffffff');

  const handleChange = (e: any) => {
    setSelected(+e.target.value);
    setReasonMessage('');
  };

  const handleChangeReasonMessage = (e: any) => {
    setReasonMessage(e.target.value);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };
  const handleCancelLeadgen = () => {

  };
  const handleCloseLeadgen = () => {
    onCloseLeadgen();
    onClose();
  };

  const handleDeclinePost = () => {
    if (user.role === UserRoleEnum.Sales) {
      dispatch(getAllLeadGensThunk());
      onOpenLeadgen();
      return;
    }
    debugger
    const body = {
      postId: post.id,
      channelId: post.channelId,
      sourceStatusGroup: sourceStatusGroup,
      destinationStatusGroup: StatusGroupEnum.Declined,
      shouldRemoveFromSource: true,
      reasonType: selected,
      reasonMessage: reasonMessage,
      payload:{
        page: page,
        take: take,
        statusGroup: sourceStatusGroup,
        channelId: channel,
        from: from,
        to: to,
        search: search,
        orderColumn: orderColumn,
        isAcs: isAcs,
      }
    };
    dispatch(transferPostThunk(body));
    onClose();
  };
  return (
    <Modal
      size='xl'
      closeOnOverlayClick={false}
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent borderRadius='20px'>
        <ModalHeader
          textAlign='center'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Text
            w='80%'
            textAlign='right'
            color={color}
            fontWeight='700'
            fontSize='24px'
            lineHeight='24px'
          >
            Select a reason for Declined
          </Text>
          <ModalCloseButton
            left='0'
            top='0'
            position='relative'
            color='#5755FF'
          />
        </ModalHeader>

        <ModalBody
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Select
            h='48px'
            w='340px'
            variant='outline'
            value={selected}
            onChange={handleChange}
          >
            {items.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </Select>

          {selected === DeclineReasonType.Other && (
            <Box mt='20px'>
              <Input
                h='48px'
                w='340px'
                placeholder='Reason'
                value={reasonMessage}
                onChange={handleChangeReasonMessage}
              />
            </Box>
          )}

          <Wrap mt='50px' spacing='30px' justify='center' mb='25px'>
            <WrapItem>
              <Button
                w='172px'
                h='48px'
                border='1px solid #413fe3'
                borderRadius='8px'
                bg='transparent'
                color={buttonColor}
                fontSize='15px'
                fontWeight='600'
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </WrapItem>
            <WrapItem>
              <Button
                w='172px'
                h='48px'
                borderRadius='8px'
                bg='#5755FF'
                color='#ffffff'
                fontSize='15px'
                fontWeight='600'
                _hover={{
                  bg: '#413fe3',
                }}
                _active={{
                  bg: '#5755FF',
                }}
                onClick={handleDeclinePost}
              >
                Save
              </Button>
            </WrapItem>
          </Wrap>
        </ModalBody>
      </ModalContent>
      <SelectLeadgenPopup
        sourceStatusGroup={sourceStatusGroup}
        destinationStatusGroup={StatusGroupEnum.Declined}
        shouldRemoveFromSource={true}
        post={post}
        isOpenLeadgen={isOpenLeadgen}
        reasonType={selected}
        reasonMessage={reasonMessage}
        onCloseLeadgen={handleCloseLeadgen}
        onCancelLeadgen={handleCancelLeadgen}
      />
    </Modal>

  );
};

export default SelectDeclinedReasonPopup;
