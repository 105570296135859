import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DashboardRoute } from '../constants/routes';
import { StatusEnum } from '../core/enums';
import { authStatusAction } from '../store/auth/actions';
import { getAuthStoreSelector } from '../store/auth/selector';
import { removeQuotesFromString } from '../utils';

const useAuthToasts = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signInStatus, error } = useSelector(getAuthStoreSelector);

  useEffect(() => {
    if (error && signInStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Auth error',
        description: removeQuotesFromString(error),
      });
    }
    if (signInStatus === StatusEnum.success) {
      navigate(DashboardRoute);
    }
  }, [error, toast, signInStatus, navigate, dispatch]);

  useEffect(() => {
    dispatch(
      authStatusAction({
        signInStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        authStatusAction({
          signInStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useAuthToasts;
