import React from 'react';
import BiddedPostsTable from '../components/tables/BiddedPostsTable';
import Pagination from '../components/Pagination';
import ContentLayout from '../layouts/content';

import usePosts from '../hooks/usePosts';
import { StatusGroupEnum } from '../core/enums';
import { Box, Flex, Select } from '@chakra-ui/react';
import useTransferToast from '../hooks/useTransferToast';
import DateCalendar from '../components/DateCalendar';
import useSignalR from '../hooks/useSignalR';

const DashboardBiddedContainer = (): JSX.Element => {
  const {
    total,
    posts,
    techStacks,
    loading,
    disabledButtons,
    page,
    setPage,
    take,
    setTake,
    from,
    to,
    setDates,
    setSort,
  } = usePosts(StatusGroupEnum.Bidded);

  useTransferToast();
  useSignalR(StatusGroupEnum.Bidded);

  return (
    <Box
      as='main'
      height='calc(100vh - 60px)'
      w='100%'
      p={{ base: '10px 2px', sm: '32px' }}
      overflow='hidden'
    >
      <ContentLayout
        title='Dashboard'
        headerChildren={
          <>
            <DateCalendar from={from} to={to} setDates={setDates} />
          </>
        }
      >
        <BiddedPostsTable
          posts={posts}
          techStacks={techStacks}
          isLoading={loading}
          setPostsSort={setSort}
        />
        <Flex justify='end' align='center' mt='20px' flexWrap='wrap'>
          <Box w='80px' mr="10px">
            <Select size='sm' value={take} onChange={setTake}>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </Select>
          </Box>

          <Pagination
            page={page}
            disabled={disabledButtons}
            total={total}
            size={take}
            onChangePage={setPage}
          />
        </Flex>
      </ContentLayout>
    </Box>
  );
};

export default DashboardBiddedContainer;
