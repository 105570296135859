import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import FormErrorMessage from './FormErrorMessage';
import { StatusEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import addChannelThunk from '../store/channel/thunk/add-channel';
import { getChannelStoreSelector } from '../store/channel/selector';
import { validateTitle, validateUrl } from '../validations';

interface AddNewChannelPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewChannelPopup = ({
  isOpen,
  onClose,
}: AddNewChannelPopupProps): JSX.Element => {
  const dispatch = useDispatch();
  const [isPotentialTechnologies, setIsPotentialTechnologies] = useState(false);

  const { addChannelStatus } = useSelector(getChannelStoreSelector);

  const handleChangePotentialTechnologies = (event: any) => {
    setIsPotentialTechnologies(event.target.checked);
  };

  const handleSubmit = (values: any) => {
    dispatch(addChannelThunk(values));
  };

  useEffect(() => {
    if (addChannelStatus === StatusEnum.success) {
      onClose();
    }
  }, [addChannelStatus, onClose]);

  return (
    <Modal
      size='lg'
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Add New Channel</ModalHeader>
        <ModalCloseButton color='#5755FF' />
        <ModalBody>
          <Formik
            initialValues={{
              title: '',
              url: '',
              isActive: true,
            }}
            onSubmit={(values, actions) => {
              handleSubmit({ ...values, isPotentialTechnologies });
            }}
          >
            <Form>
              <Field name='title' validate={validateTitle}>
                {({ field, form }: any) => (
                  <FormControl
                    id='title'
                    isInvalid={form.errors.title && form.touched.title}
                    marginBottom='30px'
                  >
                    <FormLabel fontWeight='600'>Title</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                    </InputGroup>
                    {form.errors.title && (
                      <FormErrorMessage message={form.errors.title} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='url' validate={validateUrl}>
                {({ field, form }: any) => (
                  <FormControl
                    id='url'
                    isInvalid={form.errors.url && form.touched.url}
                    marginBottom='30px'
                  >
                    <FormLabel fontWeight='600'>URL</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                    </InputGroup>
                    {form.errors.url && (
                      <FormErrorMessage message={form.errors.url} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Box mb='20px'>
                <Checkbox
                  colorScheme='purple'
                  defaultChecked={isPotentialTechnologies}
                  onChange={handleChangePotentialTechnologies}
                >
                  Is Potential Technologies
                </Checkbox>
              </Box>

              <Wrap spacing='30px' justify='center' mb='25px'>
                <WrapItem>
                  <Button
                    width='172px'
                    height='48px'
                    border='1px solid #413fe3'
                    borderRadius='8px'
                    bg='transparent'
                    color='#413fe3'
                    fontSize='15px'
                    fontWeight='600'
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    width='172px'
                    height='48px'
                    borderRadius='8px'
                    bg='#5755FF'
                    isLoading={addChannelStatus === StatusEnum.running}
                    loadingText='Wait...'
                    color='#ffffff'
                    fontSize='15px'
                    fontWeight='600'
                    _hover={{
                      bg: '#413fe3',
                    }}
                    _active={{
                      bg: '#5755FF',
                    }}
                    type='submit'
                  >
                    Save
                  </Button>
                </WrapItem>
              </Wrap>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddNewChannelPopup;
