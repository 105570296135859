import {
  Box,
  Button,
  Flex,
  VStack,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import Calendar, { Detail, OnChangeDateCallback, OnChangeDateRangeCallback } from 'react-calendar';
import { DatePickerEnum } from '../core/enums';
import DateField from './DateField';
import '../assets/styles/react-calendar.css';
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '../icons';

const DateCalendar = ({ from, to, setDates }: any): JSX.Element => {
  const now = moment();
  const toast = useToast();
  const [selected, setSelected] = useState<DatePickerEnum>(DatePickerEnum.None);

  const [dateRange, setDateRangeDate] = useState<[Date | null, Date]>(
    from ? [moment(from).toDate(), now.toDate()] : [null, now.toDate()]
  );
  
  const bg = useColorModeValue('#FFFFFF', '#252525');
  const textColor = useColorModeValue('#413fe3', '#FFFFFF');

  const onChangeDate = (values: [Date] | [Date, Date], event: ChangeEvent<HTMLInputElement>): void => {
    setSelected(DatePickerEnum.Custom);
    const value = (values as [Date, Date]);
    setDateRangeDate([value[0], value[1]]);
  };
  const handleSetToday = () => {
    setSelected(DatePickerEnum.Today);
    setDateRangeDate([now.toDate(), now.toDate()]);
  };
  const handleSet3Days = () => {
    setSelected(DatePickerEnum.ThreeDays);
    setDateRangeDate([moment().subtract(2, 'days').toDate(), now.toDate()]);
  };
  const handleSetWeek = () => {
    setSelected(DatePickerEnum.Week);
    setDateRangeDate([moment().subtract(6, 'days').toDate(), now.toDate()]);
  };
  const handleSetCustom = () => {
    setSelected(DatePickerEnum.Custom);
    setDateRangeDate([null, now.toDate()]);
  };
  const handleCancel = (onClose: () => void) => () => {
    setDateRangeDate(from ? [moment(from).toDate(), now.toDate()] : [null, now.toDate()]);
    setSelected(from ? DatePickerEnum.Today : DatePickerEnum.None);
    onClose();
  };
  const handleSubmit = () => {
      if (dateRange[0] === null || moment(dateRange[0]).isSameOrBefore(dateRange[1])) {
        setDates({
          from: moment(dateRange[0]).toISOString(),
          to: moment(dateRange[1]).toISOString(),
        });
      } else {
        toast({
          position: 'top-right',
          status: 'warning',
          title: 'Date Warning',
          description:
            'Please, select correct date range (End date needs to be after start range date)',
        });
      }
  };

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton
            size='sm'
            h='50px'
            w='340px'
            as={Button}
            bg='transparent'
            p='0px'
            borderRadius='8px'
            border='1px solid #D9E2EC;'
          >
            <DateField icon={CalendarIcon} from={dateRange[0]} to={dateRange[1]} />
          </MenuButton>
          <MenuList borderRadius='20px' p='0px'>
            <Box
              padding='20px'
              maxWidth='634px;'
              width='100%'
              height='auto'
              bg={bg}
              borderRadius='20px'
            >
              <Flex justify='space-between' flexWrap='wrap'>
                <Box mr='20px'>
                  <Flex align='center' justify='center'>
                    <VStack spacing='10px' mt='30px'>
                      <Button
                        width='172px'
                        borderRadius='8px'
                        border='1px solid #413fe3'
                        bg={
                          selected === DatePickerEnum.Today
                            ? '#5755FF'
                            : 'transparent'
                        }
                        fontSize='15px'
                        fontWeight='400'
                        _hover={{
                          bg: '#413fe3',
                          color: '#ffffff',
                        }}
                        _active={{
                          bg: '#5755FF',
                        }}
                        color={
                          selected === DatePickerEnum.Today
                            ? '#ffffff'
                            : textColor
                        }
                        onClick={handleSetToday}
                      >
                        Today
                      </Button>
                      <Button
                        width='172px'
                        border='1px solid #413fe3'
                        borderRadius='8px'
                        bg={
                          selected === DatePickerEnum.ThreeDays
                            ? '#5755FF'
                            : 'transparent'
                        }
                        _hover={{
                          bg: '#413fe3',
                          color: '#ffffff',
                        }}
                        _active={{
                          bg: '#5755FF',
                        }}
                        color={
                          selected === DatePickerEnum.ThreeDays
                            ? '#ffffff'
                            : textColor
                        }
                        fontSize='15px'
                        fontWeight='400'
                        onClick={handleSet3Days}
                      >
                        3 Days
                      </Button>
                      <Button
                        width='172px'
                        border='1px solid #413fe3'
                        borderRadius='8px'
                        bg={
                          selected === DatePickerEnum.Week
                            ? '#5755FF'
                            : 'transparent'
                        }
                        _hover={{
                          bg: '#413fe3',
                          color: '#ffffff',
                        }}
                        _active={{
                          color: '#ffffff',
                          bg: '#5755FF',
                        }}
                        color={
                          selected === DatePickerEnum.Week
                            ? '#ffffff'
                            : textColor
                        }
                        fontSize='15px'
                        fontWeight='400'
                        onClick={handleSetWeek}
                      >
                        Week
                      </Button>
                      <Button
                        width='172px'
                        border='1px solid #413fe3'
                        borderRadius='8px'
                        bg={
                          selected === DatePickerEnum.Custom
                            ? '#5755FF'
                            : 'transparent'
                        }
                        _hover={{
                          bg: '#413fe3',
                          color: '#ffffff',
                        }}
                        _active={{
                          bg: '#5755FF',
                          color: '#ffffff',
                        }}
                        color={
                          selected === DatePickerEnum.Custom
                            ? '#ffffff'
                            : textColor
                        }
                        fontSize='15px'
                        fontWeight='400'
                        onClick={handleSetCustom}
                      >
                        Custom
                      </Button>
                    </VStack>
                  </Flex>
                </Box>

                <Flex
                  boxShadow='4px 4px 10px 4px rgba(0, 0, 0, 0.3);'
                  flexDirection='column'
                >
                  <Flex
                    justify={dateRange[0] ? 'space-around' : 'center'}
                    align='center'
                  >
                    {dateRange[0]  && (
                      <Box
                        m='3px'
                        w='50%'
                        textAlign='center'
                        borderRight='2px solid #c1c1c1'
                      >
                        <Text fontWeight='600'>
                          {dateRange[0]  ? moment(dateRange[0]).format('DD MMM, YYYY') : ''}
                        </Text>
                      </Box>
                    )}
                    <Box w={dateRange[0] ? '50%' : '100%'} textAlign='center'>
                      <Text fontWeight='600'>
                        {moment(dateRange[1]).format('DD MMM, YYYY')}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex flexWrap='wrap'>
                    <Calendar
                      defaultValue={dateRange as Date[]}
                      defaultView={'month'}
                      value={dateRange}
                      onChange={onChangeDate}
                      minDate={moment('2022-01-01').toDate()}
                      maxDate={now.toDate()}
                      minDetail='month'
                      locale='En'
                      selectRange={true}
                      className='calendar-picker-container'
                      nextLabel={
                        <ArrowRightIcon
                          width='6px'
                          height='13px'
                          fill='#9abde0'
                        />
                      }
                      prevLabel={
                        <ArrowLeftIcon
                          width='6px'
                          height='13px'
                          fill='#9abde0'
                        />
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex justify='flex-end' align='end'>
                <Box mt='10px'>
                  <Button
                    size='sm'
                    bg='transparent'
                    _hover={{ background: 'transparent' }}
                    color='#413fe3'
                    onClick={handleCancel(onClose)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size='sm'
                    bg='transparent'
                    _hover={{ background: 'transparent' }}
                    color='#413fe3'
                    onClick={handleSubmit}
                  >
                    OK
                  </Button>
                </Box>
              </Flex>
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DateCalendar;
