import React from 'react';
import { Icon } from '@chakra-ui/react';

export const ArrowRightIcon = (props: any) => (
  <Icon
    width='10px'
    height='18px'
    viewBox='0 0 10 18'
    fill='#758391'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.20548 9L0 1.46179L1.39726 0L10 9L1.39726 18L0 16.5382L7.20548 9Z'
    />
  </Icon>
);
