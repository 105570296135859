import {
  authStatusAction,
  resetPasswordAction,
  signInAction,
} from '../actions';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import { AxiosError } from 'axios';
import { StatusEnum } from '../../../core/enums';
import AuthService from '../../../services/auth.service';

interface ResetPasswordThunkPayload {
  token: string;
  email: string;
  password: string;
}

const resetPasswordThunk = (payload: ResetPasswordThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        authStatusAction({
          resetPasswordStatus: StatusEnum.running,
          error: null,
        })
      );
      const response = await AuthService.resetPassword(payload);
      if (response.status === 200 || response.status === 201) {
        dispatch(authStatusAction({ resetPasswordStatus: StatusEnum.success }));
      }
    } catch (error: AxiosError | any) {
      dispatch(
        authStatusAction({
          resetPasswordStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default resetPasswordThunk;
