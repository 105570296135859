import {
  ActivateUserAction,
  ActivateUserActionPayload,
  ACTIVATE_USER,
  AddUserAction,
  AddUserActionPayload,
  ADD_USER,
  GetAllLeadGensAction,
  GetAllLeadGensActionPayload,
  GetUsersAction,
  GetUsersActionPayload,
  GET_ALL_LEADGENS,
  GET_USERS,
  GET_USER_BY_ID,
  InactivateUserAction,
  InactivateUserActionPayload,
  INACTIVATE_USER,
  SetLeadGenAction,
  SetLeadGenActionPayload,
  SetUserStoreStatusActionPayload,
  SET_LEADGEN,
  SET_USER_STORE_STATUS,
  UpdateUserAction,
  UpdateUserActionPayload,
  UPDATE_PROFILE,
  UPDATE_USER,
} from './types';

export const updateProfileAction = (payload: any): any => ({
  type: UPDATE_PROFILE,
  payload: payload,
});

export const setUserStoreStatusAction = (
  payload: SetUserStoreStatusActionPayload
): any => ({
  type: SET_USER_STORE_STATUS,
  payload: payload,
});

export const addUserAction = (
  payload: AddUserActionPayload
): AddUserAction => ({
  type: ADD_USER,
  payload: payload,
});

export const getUserByIdAction = (payload: any): any => ({
  type: GET_USER_BY_ID,
  payload: payload,
});

export const getUsersAction = (
  payload: GetUsersActionPayload
): GetUsersAction => ({
  type: GET_USERS,
  payload: payload,
});

export const getAllLeadGensAction = (
  payload: GetAllLeadGensActionPayload
): GetAllLeadGensAction => ({
  type: GET_ALL_LEADGENS,
  payload: payload,
});

export const setLeadGenAction = (
  payload: SetLeadGenActionPayload
): SetLeadGenAction => ({
  type: SET_LEADGEN,
  payload: payload,
});

export const updateUserAction = (
  payload: UpdateUserActionPayload
): UpdateUserAction => ({
  type: UPDATE_USER,
  payload: payload,
});

export const inactivateUserAction = (
  payload: InactivateUserActionPayload
): InactivateUserAction => ({
  type: INACTIVATE_USER,
  payload: payload,
});


export const activateUserAction = (
  payload: ActivateUserActionPayload
): ActivateUserAction => ({
  type: ACTIVATE_USER,
  payload: payload,
});