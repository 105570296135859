import moment from 'moment';
import { StatusEnum, StatusGroupEnum } from '../../core/enums';

export const initialState = {
  status: StatusEnum.initial,
  transferStatus: StatusEnum.initial,
  from: null,
  clientInfo: null,
  to: moment().utc(true).toISOString(),
  posts: [],
  techStacks:[],
  isAcs: true,
  orderColumn: 0,
  search: '',
  take: 100,
  page: 1,
  total: 0,
  channel: null,
  error: null,
  usePostRouteKey: null,
  currentStatusGroupType: StatusGroupEnum.None
};
