import { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  useColorModeValue,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  useDisclosure,
  Box,
  Badge,
  Tfoot,
  Text,
} from '@chakra-ui/react';
import {
  EditIcon,
  VerticalDotsIcon,
} from '../../icons';
import { UserModel } from '../../models/user';
import EditUserPopup from '../EditUserPopup';
import DialogPopup from '../DialogPopup';
import { useDispatch, useSelector } from 'react-redux';
import inactivateUserThunk from '../../store/user/thunk/inactivate-user';
import { getRole } from '../../utils';
import { setUserStoreStatusAction } from '../../store/user/actions';
import { StatusEnum, UserStatusEnum } from '../../core/enums';
import { getUserStoreSelector } from '../../store/user/selector';
import Loading from '../Loading';
import SortArrowButton from '../SortArrowButton';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import activateUserThunk from '../../store/user/thunk/activate-user';

interface UsersTableProps {
  users: UserModel[];
  isLoading: boolean;
}

const UsersTable = ({ users, isLoading }: UsersTableProps): JSX.Element => {
  const {
    isOpen: isOpenInactivateDialog,
    onOpen: onOpenInactivateDialog,
    onClose: onCloseInactivateDialog,
  } = useDisclosure();

  const {
    isOpen: isOpenActivateDialog,
    onOpen: onOpenActivateDialog,
    onClose: onCloseActivateDialog,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState<UserModel | any>(null);
  const [filters, setFilters] = useState<any>({
    FirstName: 'asc',
    LastName: 'asc',
    Email: 'asc',
    Role: 'desc',
  });

  const { inactivateUserStatus, updateUserStatus } =
    useSelector(getUserStoreSelector);

  const bg = useColorModeValue('#FFFFFF', '#252525');
  const headBg = useColorModeValue('#F5F5FF', '#222222');
  const otherColor = useColorModeValue('#5755FF', '#9b99ff');
  const textColor = useColorModeValue('#354052', '#FFFFFF');
  const borderColor = useColorModeValue('#E6EAEE', '#1A202C');
  
  const handleInactivateUser = (user: any) => (event: any) => {
    event.stopPropagation();
    onOpenInactivateDialog();
    setUser(user);
  };
  const handleActivateUser = (user: any) => (event: any) => {
    event.stopPropagation();
    onOpenActivateDialog();
    setUser(user);
  };
  const handleEditUser = (user: any) => (event: any) => {
    event.stopPropagation();
    dispatch(
      setUserStoreStatusAction({ updateUserStatus: StatusEnum.initial })
    );
    onOpen();
    setUser(user);
  };

  const handleCancelEdit = () => {
    onClose();
    setUser(null);
  };

  const handleSubmitInactivate = () => {
    dispatch(inactivateUserThunk(user.id!));
    onCloseInactivateDialog();
    user.status = UserStatusEnum.Inactive;
    setUser(user);
  };

  const handleSubmitActivate = () => {
    dispatch(activateUserThunk(user.id!));
    onCloseActivateDialog();
    user.status = UserStatusEnum.Active;
    setUser(user);
  };

  const loading =
    inactivateUserStatus === StatusEnum.running ||
    updateUserStatus === StatusEnum.running;

  const handleChangeFilter = (name: string) => () => {
    const sortValue: any = filters[name];
    let newValue;
    if (sortValue === 'asc') {
      newValue = 'desc';
    }
    if (sortValue === 'desc') {
      newValue = '';
    }
    if (sortValue === '') {
      newValue = 'asc';
    }
    setFilters({
      ...filters,
      [name]: newValue,
    });
  };

  const [sort, setSort] = useState(0);
  const [isAcs, setAcs] = useState(true);

  const handleChangeSortType = (
    flag: boolean,
    currentIndex: number,
    index: number
  ) => {
    if (currentIndex !== index) {
      setAcs(true);
      setSort(index);
    } else {
      if (flag) {
        setAcs(!isAcs);
      }
      if (!flag) {
        setAcs(!isAcs);
        setSort(0);
      }
    }
  };

  useEffect(() => {
    console.log({ isAcs, sort });
  }, [isAcs, sort]);

  return (
    <Box position='relative'>
      <Box h='calc(100vh - 60px - 120px)' overflowY='auto'>
        <TableContainer>
          <Table size='sm' variant='unstyled' backgroundColor={bg}>
            <Thead backgroundColor={headBg} height='56px'>
              <Tr color={otherColor}>
                <Th>No</Th>
                <Th>
                  Title
                  <SortArrowButton
                    currentIndex={sort}
                    isShow={sort === 1}
                    index={1}
                    onChange={handleChangeSortType}
                    isAcs={isAcs}
                  />
                </Th>
                <Th>
                  Last name
                  <SortArrowButton
                    currentIndex={sort}
                    isShow={sort === 2}
                    index={2}
                    isAcs={isAcs}
                    onChange={handleChangeSortType}
                  />
                </Th>
                <Th>
                  Email
                  <SortArrowButton
                    currentIndex={sort}
                    isShow={sort === 3}
                    index={3}
                    isAcs={isAcs}
                    onChange={handleChangeSortType}
                  />
                </Th>
                <Th>
                  Role
                  <SortArrowButton
                    currentIndex={sort}
                    isShow={sort === 4}
                    index={4}
                    isAcs={isAcs}
                    onChange={handleChangeSortType}
                  />
                </Th>
                <Th textAlign='center'>Status</Th>
                <Th textAlign='right'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && (
                <Tr>
                  <Td colSpan={7}>
                    <Loading />
                  </Td>
                </Tr>
              )}
              {!isLoading && (
                <>
                  {users.map((user, index) => {
                    return (
                      <Tr
                        key={user.id}
                        fontSize='14px'
                        lineHeight='18px'
                        fontWeight='400'
                        color={textColor}
                        borderBottom={`2px solid ${borderColor}`}
                        cursor='pointer'
                      >
                        <Td>{index + 1}</Td>
                        <Td>{user.firstName ?? ' - '}</Td>
                        <Td>{user.lastName ?? ' - '}</Td>
                        <Td>{user.email}</Td>
                        <Td>{getRole(user.role)}</Td>
                        <Td textAlign='center'>
                          <Badge
                            borderRadius='10px'
                            p='2px 8px'
                            colorScheme={
                              user.status === UserStatusEnum.Active
                                ? 'green'
                                : 'red'
                            }
                          >
                            {user.status === UserStatusEnum.Active
                              ? 'Active'
                              : 'Inactive'}
                          </Badge>
                        </Td>
                        <Td textAlign='right'>
                          <Menu>
                            <MenuButton
                              size='sm'
                              as={IconButton}
                              icon={<VerticalDotsIcon />}
                              bg='transparent'
                              onClick={(event: any) => {
                                event.stopPropagation();
                              }}
                              disabled={loading}
                            />
                            <MenuList>
                              <MenuItem
                                icon={<EditIcon />}
                                onClick={handleEditUser(user)}
                                disabled={loading}
                              >
                                Edit
                              </MenuItem>
                              {
                                user.status === UserStatusEnum.Inactive ?
                                  <>
                                    <MenuItem
                                      icon={<UnlockIcon color={'green.500'} fontSize={'20px'} />}
                                      onClick={handleActivateUser(user)}
                                      hidden={
                                        loading
                                      }
                                    >
                                      Activate
                                    </MenuItem>
                                  </> :
                                  <>
                                    <MenuItem
                                      icon={<LockIcon color={'red.500'} fontSize={'20px'} />}
                                      onClick={handleInactivateUser(user)}
                                      hidden={
                                        loading
                                      }
                                    >
                                      Inactivate
                                    </MenuItem>
                                  </>
                              }
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </Tbody>
            {!isLoading && users.length === 0 && (
              <Tfoot>
                <Tr>
                  <Td colSpan={7} textAlign='center'>
                    <Text>Not found</Text>
                  </Td>
                </Tr>
              </Tfoot>
            )}
          </Table>
        </TableContainer>
      </Box>
      <DialogPopup
        title='Are you sure?'
        isOpen={isOpenInactivateDialog}
        onClose={() => {
          setUser(null);
          onCloseInactivateDialog();
        }}
        onSubmit={handleSubmitInactivate}
      />
       <DialogPopup
        title='Are you sure?'
        isOpen={isOpenActivateDialog}
        onClose={() => {
          setUser(null);
          onCloseActivateDialog();
        }}
        onSubmit={handleSubmitActivate}
      />
      <EditUserPopup isOpen={isOpen} onClose={handleCancelEdit} user={user} />
    </Box>
  );
};

export default UsersTable;
