import { StatusEnum } from '../../core/enums';
import { ChannelModel, CreateChannelModel } from '../../models/channel';

export const SET_CHANNEL_STORE_STATUS: string = 'SET_CHANNEL_STORE_STATUS';
export const ADD_CHANNEL: string = 'ADD_CHANNEL';
export const GET_CHANNELS: string = 'GET_CHANNELS';
export const GET_GROUP_CHANNELS: string = 'GET_GROUP_CHANNELS';
export const GET_GROUP_CHANNELS_COUNT: string = 'GET_GROUP_CHANNELS_COUNT';
export const UPDATE_CHANNEL: string = 'UPDATE_CHANNEL';
export const UPDATE_CHANNEL_STATUS: string = 'UPDATE_CHANNEL_STATUS';
export const UPDATE_DIFFERENCES: string = 'UPDATE_DIFFERENCES';

export interface ChannelState {
  status: any;
  channels: ChannelModel[];
  groupChannels: any[];
  groupChannelsStatus: string;
  groupChannelsTotal: number;
  statusGroups: any[];
  differences: number[];
  addChannelStatus: StatusEnum;
  updateChannelStatus: StatusEnum;
  error: any | null;
}

export interface SetChannelStoreStatusAction {
  type: typeof SET_CHANNEL_STORE_STATUS;
  payload: SetChannelStoreStatusActionPayload;
}

export interface SetChannelStoreStatusActionPayload {
  status?: string;
  addChannelStatus?: StatusEnum;
  updateChannelStatus?: StatusEnum;
  groupChannelsStatus?: string;
  error?: any;
}

export interface GetGroupChannelsAction {
  type: typeof GET_GROUP_CHANNELS;
  payload: GetGroupChannelsActionPayload;
}
export interface GetGroupChannelsActionPayload {
  groupChannels?: any[];
  groupChannelsTotal?: number;
}

export interface AddChannelAction {
  type: typeof ADD_CHANNEL;
  payload: AddChannelActionPayload;
}
export interface AddChannelActionPayload {
  channel: CreateChannelModel;
}

export interface GetChannelsActionPayload {
  channels: ChannelModel[];
}
export interface GetChannelsAction {
  type: typeof GET_CHANNELS;
  payload: GetChannelsActionPayload;
}

export interface UpdateChannelActionPayload {
  channelId: string;
  channel: any;
}
export interface UpdateChannelAction {
  type: typeof UPDATE_CHANNEL;
  payload: UpdateChannelActionPayload;
}

export interface UpdateChannelStatusAction {
  type: typeof UPDATE_CHANNEL_STATUS;
  payload: UpdateChannelStatusActionPayload;
}
export interface UpdateChannelStatusActionPayload {
  channelId: string;
  status: boolean;
}
