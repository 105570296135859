import axiosInstance from '../api';
import {
  ForgotPasswordAPI,
  ResetPasswordAPI,
  SignInAPI,
} from '../constants/api';

interface AuthSignInModel {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface AuthResetPasswordModel {
  token: string;
  email: string;
  password: string;
}

class AuthService {
  signIn = async (signInModel: AuthSignInModel) => {
    return axiosInstance.post(SignInAPI(), signInModel);
  };
  forgotPassword = async (email: string) => {
    return await axiosInstance.post(ForgotPasswordAPI(email), {});
  };
  resetPassword = async (resetModel: AuthResetPasswordModel) => {
    return await axiosInstance.post(ResetPasswordAPI(), resetModel);
  };
}

export default new AuthService();
