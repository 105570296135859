import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { SignInRoute } from '../constants/routes';
import { UserRoleEnum } from '../core/enums';
import useAuth from '../hooks/useAuth';
import localStorageService from '../services/local-storage.service';

const AdminRoute = () => {
  const auth = useAuth();
  const user = localStorageService.getUser();
  if (!user || !auth) {
    return <Navigate to={SignInRoute} />;
  }

  return auth ? (
    user.role === UserRoleEnum.Admin || user.role === UserRoleEnum.Sales ? (
      <Outlet />
    ) : (
      <Navigate to={SignInRoute} />
    )
  ) : (
    <Navigate to={SignInRoute} />
  );
};

export default AdminRoute;
