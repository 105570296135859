import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Text,
  Flex,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { StatusEnum, StatusGroupEnum, UserRoleEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getPostStoreSelector } from '../store/post/selector';
import transferPostThunk from '../store/post/thunk/transfer-post';
import localStorageService from '../services/local-storage.service';
import PostDetailsTabs from './PostDetailsTabs';
import { PostModel } from '../models/post';
import { getPostDetailsDate } from '../utils';
import SelectDeclinedReasonPopup from './SelectDeclinedReasonPopup';
import getCommentsByIdThunk from '../store/comment/thunk/get-comments-by-id';
import getAllLeadGensThunk from '../store/user/thunk/get-all-leadgens';
import SelectLeadgenPopup from './SelectLeadgenPopup';
import { setClientInfoAction } from '../store/post/actions';
import AddAnswerRecievedAdditionPopup from './AddAnswerRecievedClientPopup';

interface PostDetailsProps {
  isShow: boolean;
  post: PostModel | null;
  sourceStatusGroup: StatusGroupEnum;
  onClose: () => void;
}
const PostDetailsModal = ({
  isShow,
  post,
  sourceStatusGroup,
  onClose,
}: PostDetailsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose: onCloseDeclinePopup } = useDisclosure();

  const bg = useColorModeValue('#FFFFFF', '#252525');
  const headColor = useColorModeValue('#354052', '#FFFFFF');
  const textColor = useColorModeValue('#413fe3', '#FFFFFF');
  const { isOpen: isOpenLeadgen, onOpen: onOpenLeadgen, onClose: onCloseLeadgen } = useDisclosure();
  const { transferStatus, channel,
    search,
    page,
    take,
    techStacks,
    from,
    to, orderColumn, isAcs } = useSelector(getPostStoreSelector);
  const [destinationStatusGroupState, setDestinationStatusStateGroup] = useState<StatusGroupEnum | null>(null);
  const [shouldRemoveFromSource, setShouldRemoveFromSource] = useState<boolean>(true);

  const handleCloseDetails = () => {
    onCloseDeclinePopup();
  };
  const handleCancel = () => {
    // setSelected(null);
    onCloseDeclinePopup();
  };

  const handleCloseAllModals = () => {
    onCloseDeclinePopup();
    onClose();
  };

  const handleCancelLeadgen = () => {
    setDestinationStatusStateGroup(null);
    onCloseLeadgen();
  };

  const {
    isOpen: isOpenAnswerRecievedAddition,
    onOpen: onOpenAnswerRecievedAddition,
    onClose: onCloseAnswerRecievedAddition,
  } = useDisclosure();

  const handleCloseAnswerRecievedAdditionPopup = () => {
    onCloseAnswerRecievedAddition();
    dispatch(
      setClientInfoAction(null)
    );
  };

  const handleGoToUpworkPost = () => {
    window.open(post?.link, '_blank');
  };

  const handleTechSupportPost = (post: any) => (event: any) => {
    event.stopPropagation();

    if (user.role === UserRoleEnum.Sales) {
      setShouldRemoveFromSource(true);
      setDestinationStatusStateGroup(StatusGroupEnum.TechSupport.valueOf());
      dispatch(getAllLeadGensThunk());
      onOpenLeadgen();
      return;
    }

    dispatch(
      transferPostThunk({
        postId: post.id,
        channelId: post.channelId,
        sourceStatusGroup: sourceStatusGroup,
        destinationStatusGroup: StatusGroupEnum.TechSupport,
        shouldRemoveFromSource: true,
        payload:{
          page: page,
          take: take,
          statusGroup: sourceStatusGroup,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      })
    );
  };
  const handleBidPost = (post: any) => (event: any) => {
    event.stopPropagation();

    if (user.role === UserRoleEnum.Sales) {
      setShouldRemoveFromSource(true);
      setDestinationStatusStateGroup(StatusGroupEnum.Bidded.valueOf());
      dispatch(getAllLeadGensThunk());
      onOpenLeadgen();
      return;
    }

    dispatch(
      transferPostThunk({
        postId: post.id,
        channelId: post.channelId,
        sourceStatusGroup: sourceStatusGroup,
        destinationStatusGroup: StatusGroupEnum.Bidded,
        shouldRemoveFromSource: true,
        payload:{
          page: page,
          take: take,
          statusGroup: sourceStatusGroup,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      })
    );
  };
  const handleSqlPost = (post: any) => (event: any) => {
    event.stopPropagation();

    if (user.role === UserRoleEnum.Sales) {
      setShouldRemoveFromSource(false);
      setDestinationStatusStateGroup(StatusGroupEnum.Sql.valueOf());
      dispatch(getAllLeadGensThunk());
      onOpenLeadgen();
      return;
    }

    dispatch(
      transferPostThunk({
        postId: post.id,
        channelId: post.channelId,
        sourceStatusGroup: StatusGroupEnum.AnswerReceived,
        destinationStatusGroup: StatusGroupEnum.Sql,
        shouldRemoveFromSource: false,
        payload:{
          page: page,
          take: take,
          statusGroup: StatusGroupEnum.AnswerReceived,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      })
    );
  };
  const handleAnswerReceivedPost = (post: any) => (event: any) => {
    event.stopPropagation();

    if (user.role === UserRoleEnum.Sales) {
      setShouldRemoveFromSource(false);
      setDestinationStatusStateGroup(StatusGroupEnum.AnswerReceived.valueOf());
      dispatch(getAllLeadGensThunk());
      onOpenLeadgen();
      return;
    }

    dispatch(
      setClientInfoAction({
        postId: post.id,
        channelId: post.channelId,
      })
    );
    onOpenAnswerRecievedAddition();
  };

  const user = localStorageService.getUser();

  useEffect(() => {
    if (transferStatus === StatusEnum.success) {
      handleCloseAllModals();
    }
  }, [transferStatus, handleCloseAllModals]);

  useEffect(() => {
    if (post) {
      dispatch(getCommentsByIdThunk(post.id));
    }
  }, [dispatch, post]);

  return (
    <Modal
      size='xxl'
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={onClose}
      isOpen={isShow}
      scrollBehavior='outside'
    >
      <ModalOverlay />
      <ModalContent h='600px' w='1300px' borderRadius='20px'>
        <ModalCloseButton />
        <ModalBody padding='20px'>
          <Flex
            height='100%'
            width='100%'
            flexDirection='column'
            flexWrap='wrap'
          >
            <Box className='post-details-header'>
              <Flex
                justifyContent={{ base: 'space-between', sm: 'space-between' }}
                w='100%'
                flexWrap='wrap'
                align='center'
              >
                <Box w='65%'>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    lineHeight='24px'
                    fontWeight='700'
                    textTransform='capitalize'
                    color={headColor}
                  >
                    {post?.title}
                  </Text>
                </Box>
                <Box w='30%'>
                  <Flex justifyContent='space-between' align='center'>
                    <Text
                      fontSize='16px'
                      lineHeight='24px'
                      fontWeight='700'
                      textTransform='capitalize'
                      color={headColor}
                    >
                      {getPostDetailsDate(post?.createdDate)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>

            <Box className='post-details-tabs' mt='10px'>
              <Flex justify='space-between' flexWrap='wrap'>
                <Box w='70%'>
                  <PostDetailsTabs details={post?.description} post={post} />
                </Box>
                <Box w='30%' className='post-details-buttons'>
                  <Flex align='center' justify='center'>
                    {user && user.role === UserRoleEnum.Admin ? (
                      <VStack spacing='10px' mt='30px'>
                        <Button
                          width='172px'
                          border='1px solid #413fe3'
                          borderRadius='8px'
                          bg='transparent'
                          color={textColor}
                          fontSize='15px'
                          fontWeight='400'
                          onClick={handleGoToUpworkPost}
                        >
                          Go to Upwork
                        </Button>
                        {sourceStatusGroup ===
                          StatusGroupEnum.AnswerReceived && (
                          <Button
                            width='172px'
                            borderRadius='8px'
                            bg='#5755FF'
                            color='#ffffff'
                            fontSize='15px'
                            fontWeight='400'
                            _hover={{
                              bg: '#413fe3',
                            }}
                            _active={{
                              bg: '#5755FF',
                            }}
                            onClick={handleSqlPost(post)}
                            disabled={transferStatus === StatusEnum.running}
                          >
                            SQL
                          </Button>
                        )}
                      </VStack>
                    ) : (
                      <>
                        {(sourceStatusGroup === StatusGroupEnum.New ||
                          sourceStatusGroup === StatusGroupEnum.Old ||
                          sourceStatusGroup ===
                            StatusGroupEnum.PotentialTechnologies) && (
                          <VStack spacing='10px' mt='30px'>
                            <Button
                              width='172px'
                              borderRadius='8px'
                              bg='#5755FF'
                              color='#ffffff'
                              fontSize='15px'
                              fontWeight='400'
                              _hover={{
                                bg: '#413fe3',
                              }}
                              _active={{
                                bg: '#5755FF',
                              }}
                              onClick={handleBidPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Bid Sent
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleTechSupportPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Tech Support
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleGoToUpworkPost}
                            >
                              Go to Upwork
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #F14336'
                              borderRadius='8px'
                              bg='transparent'
                              fontSize='15px'
                              fontWeight='400'
                              color='#F14336'
                              onClick={() => {
                                onOpen();
                              }}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Declined
                            </Button>
                          </VStack>
                        )}
                        {sourceStatusGroup === StatusGroupEnum.Bidded && (
                          <VStack spacing='10px' mt='30px'>
                            <Button
                              width='172px'
                              borderRadius='8px'
                              bg='#5755FF'
                              color='#ffffff'
                              fontSize='15px'
                              fontWeight='400'
                              hidden={post?.isAnswerReceived}
                              _hover={{
                                bg: '#413fe3',
                              }}
                              _active={{
                                bg: '#5755FF',
                              }}
                              onClick={handleAnswerReceivedPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Answer Received
                            </Button>

                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleGoToUpworkPost}
                            >
                              Go to Upwork
                            </Button>
                          </VStack>
                        )}
                        {sourceStatusGroup === StatusGroupEnum.Declined && (
                          <VStack spacing='10px' mt='30px'>
                            <Button
                              width='172px'
                              borderRadius='8px'
                              bg='#5755FF'
                              color='#ffffff'
                              fontSize='15px'
                              fontWeight='400'
                              _hover={{
                                bg: '#413fe3',
                              }}
                              _active={{
                                bg: '#5755FF',
                              }}
                              onClick={handleBidPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Bid Sent
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleTechSupportPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Tech Support
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleGoToUpworkPost}
                            >
                              Go to Upwork
                            </Button>
                          </VStack>
                        )}
                        {sourceStatusGroup === StatusGroupEnum.TechSupport && (
                          <VStack spacing='10px' mt='30px'>
                            <Button
                              width='172px'
                              borderRadius='8px'
                              bg='#5755FF'
                              color='#ffffff'
                              fontSize='15px'
                              fontWeight='400'
                              _hover={{
                                bg: '#413fe3',
                              }}
                              _active={{
                                bg: '#5755FF',
                              }}
                              onClick={handleBidPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Bid Sent
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleGoToUpworkPost}
                            >
                              Go to Upwork
                            </Button>
                            <Button
                              width='172px'
                              border='1px solid #F14336'
                              borderRadius='8px'
                              bg='transparent'
                              fontSize='15px'
                              fontWeight='400'
                              color='#F14336'
                              onClick={() => {
                                onOpen();
                              }}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              Declined
                            </Button>
                          </VStack>
                        )}
                        {sourceStatusGroup ===
                          StatusGroupEnum.AnswerReceived && (
                          <VStack spacing='10px' mt='30px'>
                            <Button
                              width='172px'
                              borderRadius='8px'
                              bg='#5755FF'
                              color='#ffffff'
                              fontSize='15px'
                              fontWeight='400'
                              _hover={{
                                bg: '#413fe3',
                              }}
                              _active={{
                                bg: '#5755FF',
                              }}
                              onClick={handleSqlPost(post)}
                              disabled={transferStatus === StatusEnum.running}
                            >
                              SQL
                            </Button>

                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleGoToUpworkPost}
                            >
                              Go to Upwork
                            </Button>
                          </VStack>
                        )}
                        {sourceStatusGroup === StatusGroupEnum.Sql && (
                          <VStack spacing='10px' mt='30px'>
                            <Button
                              width='172px'
                              border='1px solid #413fe3'
                              borderRadius='8px'
                              bg='transparent'
                              color={textColor}
                              fontSize='15px'
                              fontWeight='400'
                              onClick={handleGoToUpworkPost}
                            >
                              Go to Upwork
                            </Button>
                          </VStack>
                        )}
                      </>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
      <SelectDeclinedReasonPopup
        sourceStatusGroup={sourceStatusGroup}
        isOpen={isOpen}
        onClose={onCloseDeclinePopup}
        onCancel={handleCancel}
        post={post}
      />
       <SelectLeadgenPopup
        sourceStatusGroup={sourceStatusGroup}
        destinationStatusGroup={destinationStatusGroupState}
        shouldRemoveFromSource={shouldRemoveFromSource}
        post={post}
        isOpenLeadgen={isOpenLeadgen}
        onCloseLeadgen={onCloseLeadgen}
        onCancelLeadgen={handleCancelLeadgen}
      />
      <AddAnswerRecievedAdditionPopup isOpen={isOpenAnswerRecievedAddition} onClose={handleCloseAnswerRecievedAdditionPopup} post={post} techStacks={techStacks} />  
    </Modal>
  );
};

export default PostDetailsModal;
