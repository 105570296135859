import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../core/enums';
import { setChannelStoreStatusAction } from '../store/channel/actions';
import { getChannelStoreSelector } from '../store/channel/selector';
import { removeQuotesFromString } from '../utils';

const useChannelToasts = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { addChannelStatus, updateChannelStatus, error } = useSelector(
    getChannelStoreSelector
  );

  useEffect(() => {
    if (error && addChannelStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Action error',
        description: removeQuotesFromString(error),
      });
    }
    if (addChannelStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Added New Channel!',
      });
    }
    return () => {
      dispatch(
        setChannelStoreStatusAction({
          addChannelStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [error, toast, addChannelStatus, dispatch]);

  useEffect(() => {
    if (error && updateChannelStatus === StatusEnum.error) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Action error',
        description: removeQuotesFromString(error),
      });
    }
    if (updateChannelStatus === StatusEnum.success) {
      toast({
        position: 'top-right',
        status: 'success',
        description: 'Channel Updated!',
      });
    }
    return () => {
      dispatch(
        setChannelStoreStatusAction({
          updateChannelStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [error, toast, updateChannelStatus, dispatch]);

  useEffect(() => {
    dispatch(
      setChannelStoreStatusAction({
        addChannelStatus: StatusEnum.initial,
        updateChannelStatus: StatusEnum.initial,
        error: null,
      })
    );
    return () => {
      dispatch(
        setChannelStoreStatusAction({
          addChannelStatus: StatusEnum.initial,
          updateChannelStatus: StatusEnum.initial,
          error: null,
        })
      );
    };
  }, [dispatch]);
};

export default useChannelToasts;
