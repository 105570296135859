import {
  GET_POSTS_STATISTIC,
  GET_RECEIVED_STATISTIC,
  SET_POSTS_STATISTIC_DATES,
  SET_RECEIVED_STATISTIC_DATES,
  SET_STATISTIC_STORE_STATUS,
  StatisticState,
} from './types';
import { initialState } from './store';
import { ActionType } from '../index';

const statisticReducer = (
  state: StatisticState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case SET_STATISTIC_STORE_STATUS:
      return { ...state, ...action.payload };

    case SET_POSTS_STATISTIC_DATES: {
      return { ...state, ...action.payload };
    }
    case SET_RECEIVED_STATISTIC_DATES: {
      return { ...state, ...action.payload };
    }
    case GET_RECEIVED_STATISTIC:
      return { ...state, receivedStatistic: action.payload.receivedStatistic };
    case GET_POSTS_STATISTIC:
      return { ...state, postsStatistic: action.payload.postsStatistic };

    default:
      return state;
  }
};

export default statisticReducer;
