import React from 'react';
import { Icon } from '@chakra-ui/react';

export const ActiveIcon = (props: any) => (
  <Icon
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    fill='#758391'
    {...props}
  >
    <circle cx='12' cy='12' r='5' />
  </Icon>
);
