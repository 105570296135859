import { getPostsAction, setPostsStoreStatusAction } from '../actions';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import PostService from '../../../services/post.service';
import { AxiosError } from 'axios';
import { StatusEnum, StatusGroupEnum } from '../../../core/enums';
import moment from 'moment';

interface getPostsThunkPayload {
  page: number;
  take: number;
  statusGroup: StatusGroupEnum;
  channelId: string | null;
  from?: string;
  to?: string;
  search: string;
  orderColumn: number;
  isAcs: boolean;
}

const getPostsThunk = ({
  page,
  take,
  channelId,
  statusGroup,
  from,
  to,
  search,
  isAcs,
  orderColumn,
}: getPostsThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setPostsStoreStatusAction({
          status: StatusEnum.running,
          error: null,
        })
      );
      let options;

      if (statusGroup === StatusGroupEnum.New) {
        options = {
          to: moment(to).utc(true).endOf('day').toISOString(),
          page: page,
          take: take,
          statusGroup: statusGroup,
          channelId: channelId ?? undefined,
          orderColumn: orderColumn,
          isAcs: isAcs,
          search: search,
        };
      } else {
        options = {
          page: page,
          take: take,
          statusGroup: statusGroup,
          channelId: channelId ?? undefined,
          orderColumn: orderColumn,
          isAcs: isAcs,
          from: from
            ? moment(from).utc(true).startOf('day').toISOString()
            : undefined,
          to: moment(to).utc(true).endOf('day').toISOString(),
          search: search,
        };
      }

      // await sleep(1000);
      // const response = { status: 200, data: { total: 100, jobs: posts } };
      const response = await PostService.getPosts(options);
      if (response.status === 200 || response.status === 201) {
        dispatch(setPostsStoreStatusAction({ status: StatusEnum.success, transferStatus: StatusEnum.initial}));
        dispatch(
          getPostsAction({
            posts: response.data.jobs,
            total: response.data.total,
          })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setPostsStoreStatusAction({
          status: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getPostsThunk;
