import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputRightElement,
  CheckboxGroup,
  Checkbox,
  Stack,
  Box,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { AccountIcon, EmailIcon } from '../icons';
import FormErrorMessage from './FormErrorMessage';
import { StatusEnum, UserRoleEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import updateUserThunk from '../store/user/thunk/update-user';
import { getUserStoreSelector } from '../store/user/selector';
import { getChannelStoreSelector } from '../store/channel/selector';
import getAllChannelsThunk from '../store/channel/thunk/get-all-channels';
import getUserByIdThunk from '../store/user/thunk/get-user-by-id';
import Loading from './Loading';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from '../validations';

interface EditUserPopupProps {
  isOpen: boolean;
  onClose: () => void;
  user?: any;
}

const EditUserPopup = ({
  isOpen,
  onClose,
  user,
}: EditUserPopupProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    updateUserStatus,
    getUserByIdStatus,
    user: userById,
    error,
  } = useSelector(getUserStoreSelector);

  const { channels, status } = useSelector(getChannelStoreSelector);
  const [accessChannels, setAccessChannels] = useState<any[]>([]);
  const [role, setRole] = useState<UserRoleEnum>(UserRoleEnum.Admin);

  const handleEditSubmit = (values: any) => {
    if (+role === UserRoleEnum.Admin) {
      dispatch(
        updateUserThunk({
          ...values,
          id: user.id,
          accessChannelsIds: channels.map((ch: any) => ch.id),
          role: +role,
        })
      );
    } else {
      dispatch(
        updateUserThunk({
          ...values,
          id: user.id,
          accessChannelsIds: accessChannels,
          role: +role,
        })
      );
    }
  };

  const handleChangeRole = (event: any) => {
    setRole(event.target.value);
  };

  useEffect(() => {
    if (updateUserStatus === StatusEnum.success) {
      onClose();
    }
  }, [onClose, updateUserStatus]);

  useEffect(() => {
    const ids = userById?.accessChannels?.map((channel: any) => channel.id);
    setAccessChannels(ids);
  }, [userById]);

  useEffect(() => {}, [channels]);

  useEffect(() => {
    dispatch(getAllChannelsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setRole(user.role);
      dispatch(getUserByIdThunk(user.id));
    }
  }, [dispatch, user]);

  return (
    <Modal
      size='lg'
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              firstName: user?.firstName ?? '',
              lastName: user?.lastName ?? '',
              email: user?.email ?? '',
              status: user?.status ?? '',
            }}
            onSubmit={(values, actions) => {
              handleEditSubmit({
                ...values,
              });
            }}
          >
            <Form>
              <Field name='firstName' validate={validateFirstName}>
                {({ field, form }: any) => (
                  <FormControl
                    id='firstName'
                    isInvalid={form.errors.firstName && form.touched.firstName}
                    marginBottom='30px'
                  >
                    <FormLabel>Name</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                      <InputRightElement h={'full'}>
                        <AccountIcon />
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.firstName && (
                      <FormErrorMessage message={form.errors.firstName} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='lastName' validate={validateLastName}>
                {({ field, form }: any) => (
                  <FormControl
                    id='lastName'
                    isInvalid={form.errors.lastName && form.touched.lastName}
                    marginBottom='30px'
                  >
                    <FormLabel>Last Name</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                      <InputRightElement h={'full'}>
                        <AccountIcon />
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.lastName && (
                      <FormErrorMessage message={form.errors.lastName} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='email' validate={validateEmail}>
                {({ field, form }: any) => (
                  <FormControl
                    id='email'
                    isInvalid={form.errors.email && form.touched.email}
                    marginBottom='30px'
                  >
                    <FormLabel>Email</FormLabel>
                    <InputGroup>
                      <Input type='text' {...field} />
                      <InputRightElement h={'full'}>
                        <EmailIcon />
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.email && (
                      <FormErrorMessage message={form.errors.email} />
                    )}
                  </FormControl>
                )}
              </Field>
              <FormControl id='role' marginBottom='30px'>
                <FormLabel>Role</FormLabel>
                <Select value={role} onChange={handleChangeRole}>
                  <option value={UserRoleEnum.Admin}>Admin</option>
                  <option value={UserRoleEnum.LeadGen}>LeadGen</option>
                  <option value={UserRoleEnum.Sales}>Sales</option>
                </Select>
              </FormControl>
              {+role === UserRoleEnum.LeadGen && (
                <Box width='100%'>
                  <Field type='checkbox' name='accessChannels'>
                    {({ field, form }: any) => (
                      <Box mb='20px'>
                        <Text>Access</Text>
                        {channels.length > 0 && status !== 'running' && (
                          <CheckboxGroup
                            colorScheme='green'
                            defaultValue={accessChannels}
                            onChange={setAccessChannels}
                          >
                            <SimpleGrid columns={2} spacing={4}>
                              {channels.map((ch: any) => {
                                return (
                                  <Checkbox key={ch.id} value={ch.id}>
                                    {ch.title}
                                  </Checkbox>
                                );
                              })}
                            </SimpleGrid>
                          </CheckboxGroup>
                        )}
                      </Box>
                    )}
                  </Field>
                  {status === 'running' && <Loading />}
                </Box>
              )}

              <Wrap spacing='30px' justify='center' mb='25px'>
                <WrapItem>
                  <Button
                    border='1px solid #413fe3'
                    borderRadius='8px'
                    bg='transparent'
                    color='#413fe3'
                    fontSize='15px'
                    fontWeight='400'
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    isLoading={updateUserStatus === StatusEnum.running}
                    disabled={updateUserStatus === StatusEnum.running}
                    loadingText='Wait...'
                    borderRadius='8px'
                    bg='#5755FF'
                    color='#ffffff'
                    fontSize='15px'
                    fontWeight='400'
                    _hover={{
                      bg: '#413fe3',
                    }}
                    _active={{
                      bg: '#5755FF',
                    }}
                    type='submit'
                  >
                    Save
                  </Button>
                </WrapItem>
              </Wrap>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditUserPopup;
