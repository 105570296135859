import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  Select,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { StatusGroupEnum } from '../core/enums';
import transferPostThunk from '../store/post/thunk/transfer-post';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStoreSelector } from '../store/user/selector';
import { getPostStoreSelector } from '../store/post/selector';

interface SelectLeadgenPopupProps {
  sourceStatusGroup: StatusGroupEnum;
  destinationStatusGroup: StatusGroupEnum | null;
  shouldRemoveFromSource?: boolean;
  reasonType?: number;
  reasonMessage?: string;
  post: any;
  isOpenLeadgen: boolean;
  onCloseLeadgen: () => void;
  onCancelLeadgen: () => void;
}

const SelectLeadgenPopup = ({
  isOpenLeadgen,
  onCloseLeadgen,
  onCancelLeadgen,
  post,
  sourceStatusGroup,
  shouldRemoveFromSource,
  destinationStatusGroup,
  reasonMessage,
  reasonType
}: SelectLeadgenPopupProps): JSX.Element => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { leadgens } = useSelector(getUserStoreSelector);
  const color = useColorModeValue('#354052', '#FFFFFF');
  const buttonColor = useColorModeValue('#413fe3', '#ffffff');
  const {
    posts,
    status,
    channel,
    search,
    total,
    page,
    take,
    from,
    to,
    orderColumn,
    isAcs,
    clientInfo,
    usePostRouteKey
  } = useSelector(getPostStoreSelector);
  useEffect(() => {
    if(leadgens[0]) {
      setSelected(leadgens[0].id);
    }

  }, [leadgens]);

  const handleChange = (e: any) => {
    setSelected(e.target.value);
  };

  const handleCancel = () => {
    if (onCancelLeadgen) {
      onCancelLeadgen();
    }
    onCloseLeadgen();
  };

  const handleTransferPost = () => {
    if(destinationStatusGroup){
      const body = {
        postId: post.id,
        channelId: post.channelId,
        sourceStatusGroup: sourceStatusGroup,
        destinationStatusGroup: destinationStatusGroup,
        shouldRemoveFromSource: shouldRemoveFromSource,
        reasonMessage: reasonMessage,
        reasonType: reasonType,
        userId: selected,
        clientInfo: clientInfo,
        payload:{
          page: page,
          take: take,
          statusGroup: sourceStatusGroup,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      };
      dispatch(transferPostThunk(body));
      onCloseLeadgen();
    }

  };
  return (
    <Modal
      size='xl'
      closeOnOverlayClick={false}
      isCentered
      onClose={onCloseLeadgen}
      isOpen={isOpenLeadgen}
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent borderRadius='20px'>
        <ModalHeader
          textAlign='center'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Text
            w='80%'
            textAlign='right'
            color={color}
            fontWeight='700'
            fontSize='24px'
            lineHeight='24px'
          >
            Select leadgen for transfer 
          </Text>
          <ModalCloseButton
            left='0'
            top='0'
            position='relative'
            color='#5755FF'
          />
        </ModalHeader>

        <ModalBody
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Select
            h='48px'
            w='340px'
            variant='outline'
            value={selected}
            onChange={handleChange}
          >
            {leadgens.map((leaden: any) => {
              return (
                <option key={leaden.id} value={leaden.id}>
                  {leaden.fullName}
                </option>
              );
            })}
          </Select>

          <Wrap mt='50px' spacing='30px' justify='center' mb='25px'>
            <WrapItem>
              <Button
                w='172px'
                h='48px'
                border='1px solid #413fe3'
                borderRadius='8px'
                bg='transparent'
                color={buttonColor}
                fontSize='15px'
                fontWeight='600'
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </WrapItem>
            <WrapItem>
              <Button
                w='172px'
                h='48px'
                borderRadius='8px'
                bg='#5755FF'
                color='#ffffff'
                fontSize='15px'
                fontWeight='600'
                _hover={{
                  bg: '#413fe3',
                }}
                _active={{
                  bg: '#5755FF',
                }}
                onClick={handleTransferPost}
              >
                Submit
              </Button>
            </WrapItem>
          </Wrap>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectLeadgenPopup;
