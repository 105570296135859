import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { EmptyBoxIcon } from '../icons';

interface EmptyStateProps {
  text?: string;
}

const EmptyState = ({ text }: EmptyStateProps): JSX.Element => {
  return (
    <Flex
      w='100%'
      h='calc(100vh - 60px - 130px)'
      justify='center'
      align='center'
      flexDirection='column'
    >
      <Box>
        <EmptyBoxIcon width='125px' height='125px' />
      </Box>
      <Text mt='20px' fontSize='1.3em'>
        {text}
      </Text>
    </Flex>
  );
};

export default EmptyState;
