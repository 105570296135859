import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, HStack, Icon, Link, Text, FlexProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';

import { ChevronDownIcon } from '../../icons';
import { UserRoleEnum } from '../../core/enums';

interface NavItemProps extends FlexProps {
  path: string;
  activePath: string;
  icon: IconType;
  children: string;
  isExpandable: boolean;
  expandedMenu: boolean;
}
const NavItem = ({
  path,
  activePath,
  icon,
  children,
  isExpandable,
  expandedMenu,

  ...rest
}: NavItemProps) => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    if (!expandedMenu) {
      navigate(path);
    } else {
      if (!isExpandable) {
        navigate(path);
      }
    }
  };

  useEffect(() => {
    if (location.pathname.includes(activePath)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname, activePath]);

  return (
    <Link
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={handleNavigate}
    >
      <Flex
        align='center'
        justify='space-between'
        p='4'
        role='group'
        cursor='pointer'
        borderLeft={isActive ? '6px solid #5755FF' : '6px solid transparent'}
        _groupHover={{
          color: '#5755FF',
        }}
        _hover={{
          borderLeft: '6px solid #5755FF',
        }}
        {...rest}
      >
        {!expandedMenu ? (
          icon && (
            <Icon
              mr='4'
              fontSize='16'
              fill={isActive ? '#5755FF' : '#758391'}
              _groupHover={{
                fill: '#5755FF',
              }}
              as={icon}
            />
          )
        ) : (
          <>
            <HStack>
              {icon && (
                <Icon
                  mr='4'
                  fontSize='16'
                  fill={isActive ? '#5755FF' : '#758391'}
                  _groupHover={{
                    fill: '#5755FF',
                  }}
                  as={icon}
                />
              )}
              <Text
                color={isActive ? '#5755FF' : '#758391'}
                _groupHover={{
                  color: '#5755FF',
                }}
                fontSize='15px'
              >
                {children}
              </Text>
            </HStack>
            {isExpandable && (
              <Icon
                ml='80px'
                fontSize='16'
                fill={isActive ? '#5755FF' : '#758391'}
                _groupHover={{
                  fill: '#5755FF',
                }}
                as={ChevronDownIcon}
              />
            )}
          </>
        )}
      </Flex>
    </Link>
  );
};

export default NavItem;
