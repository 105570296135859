import React from 'react';
import { Icon } from '@chakra-ui/react';

export const PlusIcon = (props: any) => (
  <Icon
    width='11px'
    height='11px'
    viewBox='0 0 11 11'
    fill='#758391'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 0.812744H4.5V4.81274H0.5V6.81274H4.5V10.8127H6.5V6.81274H10.5V4.81274H6.5V0.812744Z'
    //   fill='white'
    />
  </Icon>
);
