import {
  UserState,
  GET_USERS,
  SET_USER_STORE_STATUS,
  UPDATE_USER,
  INACTIVATE_USER,
  ACTIVATE_USER,
  GET_USER_BY_ID,
  UPDATE_PROFILE,
  GET_ALL_LEADGENS,
  SET_LEADGEN,
} from './types';
import { initialState } from './store';
import { ActionType } from '../index';

const userReducer = (state: UserState = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_USER_STORE_STATUS:
      return { ...state, ...action.payload };
    case UPDATE_PROFILE:
      return { ...state };
    case GET_USERS:
      return { ...state, users: action.payload.users };
    case SET_LEADGEN:
      return { ...state, leadgenIds: action.payload.leadgenIds };
    case GET_ALL_LEADGENS:
      return { ...state, leadgens: action.payload.leadgens };
    case GET_USER_BY_ID:
      return { ...state, user: action.payload.user };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.userId) {
            return { ...user, ...action.payload.user };
          }
          return user;
        }),
      };

    case INACTIVATE_USER:
      return {
        ...state,
        users: action.payload.users,
      };

    case ACTIVATE_USER:
      return {
        ...state,
        users: action.payload.users,
      };
    default:
      return state;
  }
};

export default userReducer;
