import { setPostsStoreStatusAction } from '../actions';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import PostService from '../../../services/post.service';
import { AxiosError } from 'axios';
import { StatusEnum, StatusGroupEnum } from '../../../core/enums';
import localStorageService from '../../../services/local-storage.service';
import getPostsThunk from './get-posts';

interface TransferPostThunkPayload {
  sourceStatusGroup: StatusGroupEnum;
  destinationStatusGroup: StatusGroupEnum;
  postId: string;
  channelId: string;
  shouldRemoveFromSource?: boolean;
  reasonType?: number;
  reasonMessage?: string;
  userId?: string;
  payload?: GetPostsThunkPayload;
  clientInfo?: ClientInfoTransferPostThunkPayload;
}
interface ClientInfoTransferPostThunkPayload {
  name: string;
  agency: string;
  rate: number;
  techStacks: string[];
  userId?: string;
  payload?: GetPostsThunkPayload 
}
interface GetPostsThunkPayload {
  page: number;
  take: number;
  statusGroup: StatusGroupEnum;
  channelId: string | null;
  from?: string;
  to?: string;
  search: string;
  orderColumn: number;
  isAcs: boolean;

}

const transferPostThunk = ({
  postId,
  channelId,
  sourceStatusGroup,
  destinationStatusGroup,
  shouldRemoveFromSource,
  reasonType,
  reasonMessage,
  clientInfo,
  userId,
  payload
}: TransferPostThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    const user = localStorageService.getUser();
  
    try {
      dispatch(
        setPostsStoreStatusAction({
          transferStatus: StatusEnum.running,
          error: null,
        })
      );
      const options = {
        sourceStatusGroup: sourceStatusGroup,
        channelId: channelId,
        jobId: postId,
        userId: userId,
        destinationStatusGroup: destinationStatusGroup,
        shouldRemoveFromSource: shouldRemoveFromSource,
        reasonType: reasonType,
        reasonMessage: reasonMessage,
        clientInfo: clientInfo
      };
      const response = await PostService.transferPost(options);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          setPostsStoreStatusAction({ transferStatus: StatusEnum.success })
        );
        dispatch(
          getPostsThunk(payload!)
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setPostsStoreStatusAction({
          transferStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default transferPostThunk;
