import { Box, Flex } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import EmptyState from '../components/EmptyState';
import NoInternetConnectionState from '../components/NoInternetConnectionState';

interface InternetConnectionWrapperProps {
  children: React.ReactNode;
}

const InternetConnectionWrapper = ({
  children,
}: InternetConnectionWrapperProps): JSX.Element => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return <>{children}</>;
  } else {
    return (
      <>
        <NoInternetConnectionState text='No Interner Connection. Please try again later.' />
      </>
    );
  }
};

export default InternetConnectionWrapper;
