import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { posts } from '../../../lib/posts';
import PostService from '../../../services/post.service';
import { AxiosError } from 'axios';
import { StatusEnum, StatusGroupEnum } from '../../../core/enums';
import moment from 'moment';
import {
  setStatisticStoreStatusAction,
  getReceivedStatisticAction,
} from '../actions';

interface getReceivedThunkPayload {
  from: string;
  to: string;
  leadGenIds: string[];
}

const getReceivedStatisticThunk = ({
  from,
  to,
  leadGenIds,
}: getReceivedThunkPayload): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setStatisticStoreStatusAction({
          getReceivedStatus: StatusEnum.running,
          error: null,
        })
      );

      const options = {
        from: moment(from).utc(true).startOf('day').toISOString(),
        to: moment(to).utc(true).endOf('day').toISOString(),
        leadGenIds: leadGenIds,
      };

      const response = await PostService.getReceivedStatisticByFilter(options);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          setStatisticStoreStatusAction({
            getReceivedStatus: StatusEnum.success,
          })
        );
        dispatch(
          getReceivedStatisticAction({
            receivedStatistic: response.data.items,
          })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setStatisticStoreStatusAction({
          getReceivedStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default getReceivedStatisticThunk;
