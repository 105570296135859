import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Checkbox,
  Link,
  FormLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { DashboardRoute, ForgotPasswordRoute } from '../constants/routes';
import { AccountIcon, CloseEyeIcon, EmailIcon, OpenEyeIcon } from '../icons';
import FormErrorMessage from './FormErrorMessage';
import LocalStorageService from '../services/local-storage.service';
import { StatusEnum, UserRoleEnum } from '../core/enums';
import localStorageService from '../services/local-storage.service';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStoreSelector } from '../store/user/selector';
import updateProfileThunk from '../store/user/thunk/update-profile';
import { setUserStoreStatusAction } from '../store/user/actions';
import Loading from './Loading';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from '../validations';

interface ProfileFormProps {
  userById: any;
  getUserByIdStatus: StatusEnum;
  updateProfileStatus: StatusEnum;
}

const ProfileForm = ({
  userById,
  getUserByIdStatus,
  updateProfileStatus,
}: ProfileFormProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const bg = useColorModeValue('#FFFFFF', '#252525');

  const handleSubmit = (values: any) => {
    const body = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };

    dispatch(updateProfileThunk(body));
  };

  return (
    <>
      {getUserByIdStatus === StatusEnum.running && <Loading />}
      {getUserByIdStatus !== StatusEnum.running && (
        <Formik
          initialValues={{
            email: userById?.email ?? '',
            password: '',
            firstName: userById?.firstName ?? '',
            lastName: userById?.lastName ?? '',
          }}
          onSubmit={(values, actions) => {
            handleSubmit({ ...values, id: userById.id, role: userById.role });
          }}
        >
          <Form>
            <Field name='email' validate={validateEmail}>
              {({ field, form }: any) => (
                <FormControl
                  id='email'
                  isInvalid={form.errors.email && form.touched.email}
                  marginBottom='30px'
                >
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <Input type='email' {...field} bg={bg} />
                    <InputRightElement h={'full'}>
                      <EmailIcon />
                    </InputRightElement>
                  </InputGroup>
                  {form.errors.email && (
                    <FormErrorMessage message={form.errors.email} />
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='password'>
              {({ field, form }: any) => (
                <FormControl
                  id='Password'
                  isInvalid={form.errors.password && form.touched.password}
                  marginBottom='30px'
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      {...field}
                      bg={bg}
                    />
                    <InputRightElement h={'full'}>
                      <Button
                        variant={'ghost'}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {form.errors.password && (
                    <FormErrorMessage message={form.errors.password} />
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='firstName' validate={validateFirstName}>
              {({ field, form }: any) => (
                <FormControl
                  id='firstName'
                  isInvalid={form.errors.firstName && form.touched.firstName}
                  marginBottom='30px'
                >
                  <FormLabel>First Name</FormLabel>
                  <InputGroup>
                    <Input type='text' {...field} bg={bg} />
                    <InputRightElement h={'full'}>
                      <AccountIcon />
                    </InputRightElement>
                  </InputGroup>
                  {form.errors.firstName && (
                    <FormErrorMessage message={form.errors.firstName} />
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='lastName' validate={validateLastName}>
              {({ field, form }: any) => (
                <FormControl
                  id='lastName'
                  isInvalid={form.errors.lastName && form.touched.lastName}
                  marginBottom='30px'
                >
                  <FormLabel>Last Name</FormLabel>
                  <InputGroup>
                    <Input type='text' {...field} bg={bg} />
                    <InputRightElement h={'full'}>
                      <AccountIcon />
                    </InputRightElement>
                  </InputGroup>
                  {form.errors.lastName && (
                    <FormErrorMessage message={form.errors.lastName} />
                  )}
                </FormControl>
              )}
            </Field>
            <Button
              type='submit'
              isLoading={updateProfileStatus === StatusEnum.running}
              loadingText='Wait..'
              size='lg'
              bg={'#5755FF'}
              color={'white'}
              _hover={{
                bg: '#3e3dd2',
              }}
            >
              Save
            </Button>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ProfileForm;
