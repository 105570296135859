import {
  GET_POSTS_STATISTIC,
  GET_RECEIVED_STATISTIC,
  SetPostsDatesAction,
  SetPostsDatesActionPayload,
  SetReceivedDatesAction,
  SetReceivedDatesActionPayload,
  SET_POSTS_STATISTIC_DATES,
  SET_RECEIVED_STATISTIC_DATES,
  SET_STATISTIC_STORE_STATUS,
} from './types';

export const getReceivedStatisticAction = (payload: any) => ({
  type: GET_RECEIVED_STATISTIC,
  payload: payload,
});
export const getPostsStatisticAction = (payload: any) => ({
  type: GET_POSTS_STATISTIC,
  payload: payload,
});

export const setStatisticStoreStatusAction = (payload: any): any => ({
  type: SET_STATISTIC_STORE_STATUS,
  payload: payload,
});

export const setReceivedDatesAction = (
  payload: SetReceivedDatesActionPayload
): SetReceivedDatesAction => ({
  type: SET_RECEIVED_STATISTIC_DATES,
  payload: payload,
});

export const setPostsDatesAction = (
  payload: SetPostsDatesActionPayload
): SetPostsDatesAction => ({
  type: SET_POSTS_STATISTIC_DATES,
  payload: payload,
});
