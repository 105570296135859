import {
  getUsersAction,
  setUserStoreStatusAction,
  updateUserAction,
} from '../actions';
import ChannelService from '../../../services/channel.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';
import userService from '../../../services/user.service';
import localStorageService from '../../../services/local-storage.service';

const updateProfileThunk = (update: any): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({ updateProfileStatus: StatusEnum.running })
      );
      const user = localStorageService.getUser();
      const body = { id: user.id, ...update };
      const response = await userService.updateProfile(body);
      if (response.status === 200 || response.status === 201) {
        localStorageService.setUser({ ...user, ...body });
        dispatch(
          setUserStoreStatusAction({ updateProfileStatus: StatusEnum.success })
        );
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          updateProfileStatus: StatusEnum.error,
          error: error,
        })
      );
    }
  };
};

export default updateProfileThunk;
