import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Link,
  Text,
  FlexProps,
  SkeletonCircle,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ChannelModel } from '../../models/channel';

interface SecondNavItemProps extends FlexProps {
  path: string;
  activePath: string;
  children: string;
  isNavigate?: boolean;
  isCount?: boolean;
  total?: number;
  difference: number;
  channels?: ChannelModel[];
}

const SecondNavItem = ({
  path,
  activePath,
  children,
  isCount,
  isNavigate,
  total,
  difference,
  channels,
  ...rest
}: SecondNavItemProps) => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const color = useColorModeValue('#111111', '#ffffff');

  const handleNavigate = () => {
    if (isNavigate) {
      navigate(path);
    }
  };

  useEffect(() => {
    if (location.pathname.includes(activePath)) {
      setActive(true);
    } else {
      setActive(false);
    }
    return () => {};
  }, [location, activePath]);

  return (
    <Link
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={handleNavigate}
    >
      <Flex
        align='center'
        p='2'
        pl={isCount ? '35px' : '80px'}
        role='group'
        cursor='pointer'
        borderLeft={isActive ? '6px solid #5755FF' : '6px solid transparent'}
        _groupHover={{
          color: '#5755FF',
        }}
        _hover={{
          borderLeft: '6px solid #5755FF',
        }}
        {...rest}
      >
        {isCount && (
          <>
            {total != null || total !== undefined ? (
              <Box
                position='relative'
                padding='3px'
                width='35px'
                height='35px'
                borderRadius='50%'
                backgroundColor='#5755FF'
                display='flex'
                alignItems='center'
                justifyContent='center'
                mr='14px'
              >
                <Text fontSize='10px' lineHeight='14px' color='#ffffff'>
                  {total}
                </Text>
                {difference > 0 && (
                  <Box
                    minWidth='21px'
                    minHeight='21px'
                    position='absolute'
                    bottom='-12px'
                    right='-12px'
                    backgroundColor='red'
                    borderRadius='50%'
                    padding='1'
                    // w='25px'
                    // h='25px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Text fontSize='10px' lineHeight='14px' color='#ffffff'>
                      +{difference}
                    </Text>
                  </Box>
                )}
              </Box>
            ) : (
              <SkeletonCircle size='7' mr='14px' />
            )}
          </>
        )}
        <Text
          color={isActive ? '#5755FF' : '#758391'}
          _groupHover={{
            color: '#5755FF',
          }}
          fontSize='15px'
          // data-count='30'
          // _before={{
          //   width: '30px',
          //   height: '30px',
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   textAlign: 'center',
          //   content: 'attr(data-count)',
          //   borderRadius: '50%',
          //   padding: '2px',
          //   backgroundColor: 'red',
          //   position: 'absolute',
          //   left: '10px',
          //   top: '50%',
          //   transform: 'translateY(-50%)'
          // }}
        >
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

export default SecondNavItem;
