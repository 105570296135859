import React from 'react';
import { Icon } from '@chakra-ui/react';

export const ArrowLeftIcon = (props: any) => (
  <Icon
    width='10px'
    height='18px'
    viewBox='0 0 10 18'
    fill='#758391'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.79452 9L10 16.5382L8.60274 18L0 9L8.60274 0L10 1.46179L2.79452 9Z'
    />
  </Icon>
);
