import React from 'react';
import { Icon } from '@chakra-ui/react';

export const ForgotPasswordSentIcon = (props: any) => (
  <Icon
    width='100px'
    height='101px'
    viewBox='0 0 100 101'
    fill='#394452'
    {...props}
  >
    <circle cx='50' cy='50.3638' r='50' fill='#F1F1FF' />
    <path
      d='M67 34.3638L35 34.3638C32.8 34.3638 31.02 36.1638 31.02 38.3638L31 62.3638C31 64.5638 32.8 66.3638 35 66.3638H67C69.2 66.3638 71 64.5638 71 62.3638V38.3638C71 36.1638 69.2 34.3638 67 34.3638ZM66.2 42.8638L52.06 51.7038C51.42 52.1038 50.58 52.1038 49.94 51.7038L35.8 42.8638C35.3 42.5438 35 42.0038 35 41.4238C35 40.0838 36.46 39.2838 37.6 39.9838L51 48.3638L64.4 39.9838C65.54 39.2838 67 40.0838 67 41.4238C67 42.0038 66.7 42.5438 66.2 42.8638Z'
      fill='#5755FF'
    />
  </Icon>
);
