import React from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import StatisticTabs from '../components/StatisticTabs';
import { StatusEnum } from '../core/enums';
import useStatistics from '../hooks/useStatistics';
import ContentLayout from '../layouts/content';
import EmptyState from '../components/EmptyState';
import useSignalR from '../hooks/useSignalR';

const StatisticContainer = (): JSX.Element => {
  const location = useLocation();
  useSignalR();
  const params = new URLSearchParams(location.search);

  const openedTab = params.get('tab');
  const {
    receivedStatistic,
    postsStatistic,
    getReceivedStatus,
    getPostsStatus,
    fromReceivedDate,
    toReceivedDate,
    fromPostDate,
    toPostDate,
    leadgens,
    leadgenIds,
    setReceivedDates,
    setPostsDates,
    setLeadGen,
  } = useStatistics();

  const loading =
    getPostsStatus === StatusEnum.running ||
    getReceivedStatus === StatusEnum.running;

  const error =
    getPostsStatus === StatusEnum.error &&
    getReceivedStatus === StatusEnum.error;

  return (
    <Box
      as='main'
      height='calc(100vh - 60px)'
      w='100%'
      p={{ base: '10px 2px', sm: '32px' }}
      overflowY='auto'
    >
      <ContentLayout title='Statistic'>
        {loading && <Loading />}
        {!loading && (
          <>
            {error ? (
              <EmptyState text='Empty State' />
            ) : (
              <StatisticTabs
                receivedStatistic={receivedStatistic}
                postsStatistic={postsStatistic}
                fromReceivedDate={fromReceivedDate}
                toReceivedDate={toReceivedDate}
                fromPostDate={fromPostDate}
                toPostDate={toPostDate}
                setReceivedDates={setReceivedDates}
                setPostsDates={setPostsDates}
                opened={openedTab ? +openedTab : 0}
                leadgens={leadgens}
                leadgenIds={leadgenIds}
                setLeadGen={setLeadGen}
              />
            )}
          </>
        )}
      </ContentLayout>
    </Box>
  );
};

export default StatisticContainer;
