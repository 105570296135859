import React from 'react';
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MoonIcon, SunIcon, HamburgerIcon } from '@chakra-ui/icons';

import { ChevronDownIcon } from '../../icons';
import { ProfileRoute, SignInRoute } from '../../constants/routes';
import LocalStorageService from '../../services/local-storage.service';
import { getRole } from '../../utils';
import signOutThunk from '../../store/auth/thunk/sign-out';
import { useDispatch } from 'react-redux';

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  const handleNavigate = () => {
    navigate(ProfileRoute);
  };
  const user = LocalStorageService.getUser();

  const handleSignOut = () => {
    dispatch(signOutThunk());
    navigate(SignInRoute);
  };

  return (
    <Flex
      px={{ base: 4, md: 4 }}
      height='60px'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        size={{ base: 'sm', md: 'md' }}
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<HamburgerIcon />}
      />
      <HStack spacing={{ base: '2', md: '6' }}>
        <IconButton
          size={{ base: 'sm' }}
          variant='ghost'
          aria-label='open menu'
          icon={colorMode === 'light' ? <SunIcon /> : <MoonIcon />}
          onClick={toggleColorMode}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition='all 0.3s'
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  name={user && `${user.firstName} ${user.lastName}`}
                  backgroundColor='#cfceff'
                  fontWeight='bold'
                  color='#5755FF'
                  // src={
                  //   'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  // }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>{user && getRole(user.role)}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <ChevronDownIcon />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              zIndex={10}
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={handleNavigate}>Profile</MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default MobileNav;
