import React from 'react';
import { Icon } from '@chakra-ui/react';

export const CloseEyeIcon = (props: any) => (
  <Icon
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    fill='#758391'
    {...props}
  >
    <g id='Action / visibility off'>
      <mask
        id='mask0_1_9606'
        maskUnits='userSpaceOnUse'
        x='1'
        y='2'
        width='22'
        height='20'
      >
        <g id='Icon Mask'>
          <path
            id='Round'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.71 4.54002C2.32 4.15002 2.32 3.52002 2.71 3.13002C3.1 2.74002 3.74 2.74002 4.13 3.13002L20.44 19.46C20.83 19.85 20.83 20.48 20.44 20.87C20.05 21.26 19.42 21.26 19.03 20.87L16.31 18.15C14.97 18.67 13.52 18.97 12 18.97C7 18.97 2.73 15.86 1 11.47C1.77 9.50002 3.06 7.80002 4.68 6.51002L2.71 4.54002ZM17 11.47C17 8.71002 14.76 6.47002 12 6.47002C11.49 6.47002 11 6.57002 10.53 6.71002L8.36 4.54002C9.51 4.17002 10.73 3.97002 12 3.97002C17 3.97002 21.27 7.08002 23 11.46C22.31 13.22 21.21 14.76 19.82 15.99L16.76 12.93C16.9 12.47 17 11.98 17 11.47ZM12 16.47C9.24 16.47 7 14.23 7 11.47C7 10.7 7.18 9.97002 7.49 9.33002L9.06 10.9C9.03 11.08 9 11.27 9 11.47C9 13.13 10.34 14.47 12 14.47C12.2 14.47 12.38 14.44 12.57 14.4L14.14 15.97C13.49 16.29 12.77 16.47 12 16.47ZM14.97 11.14C14.82 9.74002 13.72 8.65002 12.33 8.50002L14.97 11.14Z'
          />
        </g>
      </mask>
      <g mask='url(#mask0_1_9606)'>
        <rect id='Color Fill' width='24' height='24' fill='#858C94' />
      </g>
    </g>
  </Icon>
);
