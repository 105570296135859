import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Flex,
  IconButton,
  useColorModeValue,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Box,
  useDisclosure,
  Tooltip,
  Text,
  Tfoot,
  AvatarBadge,
} from '@chakra-ui/react';
import { ReportIcon } from '../../icons/ReportIcon';
import { CheckIcon, DeleteIcon, EditIcon, VerticalDotsIcon } from '../../icons';
import { PostModel } from '../../models/post';
import { getTimeOrDate, removeHtmlTagsFromString } from '../../utils';
import transferPostThunk from '../../store/post/thunk/transfer-post';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum, StatusGroupEnum, UserRoleEnum } from '../../core/enums';
import { getPostStoreSelector } from '../../store/post/selector';
import localStorageService from '../../services/local-storage.service';
import SelectDeclinedReasonPopup from '../SelectDeclinedReasonPopup';
import PostDetailsModal from '../PostDetailsModal';
import SearchPopup from '../SearchPopup';
import Loading from '../Loading';
import SortArrowButton from '../SortArrowButton';
import { setUsePostRouteAction } from '../../store/post/actions';
import getAllLeadGensThunk from '../../store/user/thunk/get-all-leadgens';
import SelectLeadgenPopup from '../SelectLeadgenPopup';

interface SortPayload {
  orderColumn: number;
  isAcs: boolean;
}

interface PotentialTechnologiesPostsTableProps {
  posts: PostModel[];
  isLoading: boolean;
  setPostsSort: (payload: SortPayload) => void;
}

const PotentialTechnologiesPostsTable = ({
  posts,
  isLoading,
  setPostsSort,
}: PotentialTechnologiesPostsTableProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails,
  } = useDisclosure();
  const [selected, setSelected] = useState<PostModel | null>(null);
  const bg = useColorModeValue('#FFFFFF', '#252525');
  const otherColor = useColorModeValue('#5755FF', '#9b99ff');
  const headBg = useColorModeValue('#F5F5FF', '#222222');
  const textColor = useColorModeValue('#354052', '#FFFFFF');
  const borderColor = useColorModeValue('#E6EAEE', '#1A202C');

  const { isOpen: isOpenLeadgen, onOpen: onOpenLeadgen, onClose: onCloseLeadgen } = useDisclosure();
  const [destinationStatusGroupState, setDestinationStatusStateGroup] = useState<StatusGroupEnum | null>(null);

  const { transferStatus,  channel,
    search,
    page,
    take,
    from,
    to, orderColumn, isAcs } = useSelector(getPostStoreSelector);

  const user = localStorageService.getUser();

  const handleCloseDetails = () => {
    setSelected(null);
    onCloseDetails();
  };
  const handleCancel = () => {
    setSelected(null);
  };

  const handleClickItem = (post: any) => (event: any) => {
    if (!selected) {
      setSelected(post);
      onOpenDetails();
    }
  };

  const handleDeclinePost = (post: any) => (event: any) => {
    event.stopPropagation();
    setSelected(post);
    onOpen();
  };

  const handleCancelLeadgen = () => {
    setSelected(null);
    setDestinationStatusStateGroup(null);
    onCloseLeadgen();
  };

  const openLeadGenSelect = (post: any, destination: StatusGroupEnum) => {
    setDestinationStatusStateGroup(destination.valueOf());
    setSelected(post);
    dispatch(getAllLeadGensThunk());
    onOpenLeadgen();
  }

  const handleTechSupportPost = (post: any) => (event: any) => {
    event.stopPropagation();

    if (user.role === UserRoleEnum.Sales) {
      openLeadGenSelect(post, StatusGroupEnum.TechSupport)
      return;
    }

    dispatch(
      transferPostThunk({
        postId: post.id,
        channelId: post.channelId,
        sourceStatusGroup: StatusGroupEnum.PotentialTechnologies,
        destinationStatusGroup: StatusGroupEnum.TechSupport,
        shouldRemoveFromSource: true,
        payload:{
          page: page,
          take: take,
          statusGroup: StatusGroupEnum.PotentialTechnologies,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      })
    );
  };
  const handleBidPost = (post: any) => (event: any) => {
    event.stopPropagation();

    if (user.role === UserRoleEnum.Sales) {
      openLeadGenSelect(post, StatusGroupEnum.Bidded)
      return;
    }

    dispatch(
      transferPostThunk({
        postId: post.id,
        channelId: post.channelId,
        sourceStatusGroup: StatusGroupEnum.PotentialTechnologies,
        destinationStatusGroup: StatusGroupEnum.Bidded,
        shouldRemoveFromSource: true,
        payload:{
          page: page,
          take: take,
          statusGroup: StatusGroupEnum.PotentialTechnologies,
          channelId: channel,
          from: from,
          to: to,
          search: search,
          orderColumn: orderColumn,
          isAcs: isAcs,
        }
      })
    );
  };
  const handleGoToUpworkPost = (link?: string) => (event: any) => {
    event.stopPropagation();
    window.open(link, '_blank');
  };

  const [orderColumnValue, setOrderColumn] = useState(0);
  const [isAcsValue, setAcs] = useState(true);

  const handleChangeSortType = (
    flag: boolean,
    currentIndex: number,
    index: number
  ) => {
    if (currentIndex !== index) {
      setAcs(true);
      setOrderColumn(index);
    } else {
      if (flag) {
        setAcs(!isAcsValue);
      }
      if (!flag) {
        setAcs(!isAcsValue);
        setOrderColumn(0);
      }
    }
    dispatch(setUsePostRouteAction({usePostRouteKey: ''}));
  };

  useEffect(() => {
    setPostsSort({ isAcs: isAcsValue, orderColumn: orderColumnValue });
  }, [isAcsValue, orderColumnValue]);

  return (
    <Box position='relative'>
      <Box h='calc(100vh - 60px - 40px - 130px)' overflowY='auto'>
        <TableContainer>
          <Table size='sm' variant='unstyled' backgroundColor={bg}>
            <Thead backgroundColor={headBg} height='56px'>
              <Tr color={otherColor} fontSize='16px' fontWeight='400'>
                <Th textAlign='left'></Th>
                <Th>
                  <Flex align='center'>
                    <SearchPopup>
                      <Text>Title</Text>
                    </SearchPopup>
                    <SortArrowButton
                      currentIndex={orderColumnValue}
                      isShow={orderColumnValue === 1}
                      index={1}
                      isAcs={isAcsValue}
                      onChange={handleChangeSortType}
                    />
                  </Flex>
                </Th>
                <Th>
                  Details
                  <SortArrowButton
                    currentIndex={orderColumnValue}
                    isShow={orderColumnValue === 2}
                    index={2}
                    isAcs={isAcsValue}
                    onChange={handleChangeSortType}
                  />
                </Th>
                <Th>
                  Filter
                  <SortArrowButton
                    currentIndex={orderColumnValue}
                    isShow={orderColumnValue === 3}
                    index={3}
                    isAcs={isAcsValue}
                    onChange={handleChangeSortType}
                  />
                </Th>
                <Th>
                  Time
                  <SortArrowButton
                    currentIndex={orderColumnValue}
                    isShow={orderColumnValue === 4}
                    index={4}
                    isAcs={isAcsValue}
                    onChange={handleChangeSortType}
                  />
                </Th>
                <Th textAlign='right' w='130px'>
                  LeadGen
                </Th>
                <Th textAlign='right'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && (
                <Tr>
                  <Td colSpan={7}>
                    <Loading />
                  </Td>
                </Tr>
              )}
              {!isLoading && (
                <>
                  {posts.map((post: any) => {
                    return (
                      <Tr
                        key={post.id}
                        fontSize='14px'
                        lineHeight='18px'
                        fontWeight='400'
                        color={textColor}
                        onClick={handleClickItem(post)}
                        borderBottom={`2px solid ${borderColor}`}
                        cursor='pointer'
                      >
                        <Td textAlign='left'>
                          {/* {post?.declineMessageLeadgen && (
                            <Tooltip
                              hasArrow
                              label={post?.declineMessageLeadgen}
                              fontSize='1.3em'
                              placement='auto'
                            >
                              <Box>
                                <ReportIcon fill='#feb2b2' />
                              </Box>
                            </Tooltip>
                          )}
                          {post?.techSupportMessageLeadgen && (
                            <Tooltip
                              hasArrow
                              label={post?.techSupportMessageLeadgen}
                              fontSize='1.3em'
                              placement='auto'
                            >
                              <Box>
                                <ReportIcon fill='#3182ce' />
                              </Box>
                            </Tooltip>
                          )} */}
                          <Flex
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            {post?.declineMessageLeadgen.map(
                              (message: any, index: number) => (
                                <Tooltip
                                  hasArrow
                                  key={index}
                                  label={message}
                                  fontSize='1.3em'
                                  placement='auto'
                                >
                                  <Box>
                                    <ReportIcon fill='#feb2b2' />
                                  </Box>
                                </Tooltip>
                              )
                            )}
                            {post?.techSupportMessageLeadgen.map(
                              (message: any, index: number) => (
                                <Tooltip
                                  hasArrow
                                  key={index}
                                  label={message}
                                  fontSize='1.3em'
                                  placement='auto'
                                >
                                  <Box>
                                    <ReportIcon fill='#3182ce' />
                                  </Box>
                                </Tooltip>
                              )
                            )}
                          </Flex>
                        </Td>
                        <Td>
                          <Tooltip
                            hasArrow
                            label={removeHtmlTagsFromString(post?.title)}
                            placement='auto'
                            fontSize='1.3em'
                            openDelay={500}
                          >
                            <Text
                              maxWidth='400px'
                              overflow='hidden'
                              textOverflow='ellipsis'
                            >
                              {removeHtmlTagsFromString(post?.title)}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td>
                          <Tooltip
                            hasArrow
                            label={removeHtmlTagsFromString(
                              post?.description.slice(0, 700) + '...'
                            )}
                            fontSize='1.3em'
                            placement='auto'
                            openDelay={500}
                          >
                            <Text
                              maxWidth='300px'
                              overflow='hidden'
                              textOverflow='ellipsis'
                            >
                              {removeHtmlTagsFromString(post?.description)}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td>{post?.channelTitle}</Td>
                        <Td>{getTimeOrDate(post?.createdDate)}</Td>
                        <Td textAlign='right'>
                          <Flex
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            {post?.leadGens.map((leadgen: any) => {
                              return (
                                <Avatar
                                  key={leadgen.id}
                                  size={'sm'}
                                  name={leadgen.firstName}
                                  backgroundColor='#cfceff'
                                  fontWeight='bold'
                                  color='#5755FF'
                                  >
                                  {
                                    leadgen.isTransferredBySales ? 
                                    <AvatarBadge boxSize='1.25em' borderColor='papayawhip' bg='gold' /> 
                                    : <></>
                                  }
                                </Avatar>
                              );
                            })}
                          </Flex>
                        </Td>
                        <Td textAlign='right'>
                          <Menu>
                            <MenuButton
                              size='sm'
                              as={IconButton}
                              icon={<VerticalDotsIcon />}
                              bg='transparent'
                              onClick={(event: any) => {
                                event.stopPropagation();
                              }}
                              disabled={transferStatus === StatusEnum.running}
                            />
                            {user && (user.role === UserRoleEnum.LeadGen || user.role === UserRoleEnum.Sales) ? (
                              <MenuList>
                                <MenuItem
                                  icon={<CheckIcon />}
                                  onClick={handleBidPost(post)}
                                  disabled={
                                    transferStatus === StatusEnum.running
                                  }
                                >
                                  Bid sent
                                </MenuItem>
                                <MenuItem
                                  icon={<CheckIcon fill='#27AE60' />}
                                  onClick={handleGoToUpworkPost(post.link)}
                                  disabled={
                                    transferStatus === StatusEnum.running
                                  }
                                >
                                  Go to Upwork
                                </MenuItem>
                                <MenuItem
                                  icon={<EditIcon />}
                                  onClick={handleTechSupportPost(post)}
                                  disabled={
                                    transferStatus === StatusEnum.running
                                  }
                                >
                                  Tech support
                                </MenuItem>
                                <MenuItem
                                  icon={<DeleteIcon />}
                                  onClick={handleDeclinePost(post)}
                                  disabled={
                                    transferStatus === StatusEnum.running
                                  }
                                >
                                  Declined
                                </MenuItem>
                              </MenuList>
                            ) : (
                              <MenuList>
                                <MenuItem
                                  icon={<CheckIcon fill='#27AE60' />}
                                  onClick={handleGoToUpworkPost(post.link)}
                                  disabled={
                                    transferStatus === StatusEnum.running
                                  }
                                >
                                  Go to Upwork
                                </MenuItem>
                              </MenuList>
                            )}
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </Tbody>
            {!isLoading && posts.length === 0 && (
              <Tfoot>
                <Tr>
                  <Td colSpan={7} textAlign='center'>
                    <Text>Not found</Text>
                  </Td>
                </Tr>
              </Tfoot>
            )}
          </Table>
        </TableContainer>
      </Box>
      <PostDetailsModal
        isShow={isOpenDetails && !!selected}
        onClose={handleCloseDetails}
        post={selected}
        sourceStatusGroup={StatusGroupEnum.PotentialTechnologies}
      />
      <SelectDeclinedReasonPopup
        sourceStatusGroup={StatusGroupEnum.PotentialTechnologies}
        isOpen={isOpen}
        onClose={onClose}
        onCancel={handleCancel}
        post={selected}
      />
      <SelectLeadgenPopup
        sourceStatusGroup={StatusGroupEnum.PotentialTechnologies}
        destinationStatusGroup={destinationStatusGroupState}
        shouldRemoveFromSource={true}
        post={selected}
        isOpenLeadgen={isOpenLeadgen}
        onCloseLeadgen={onCloseLeadgen}
        onCancelLeadgen={handleCancelLeadgen}
      />
    </Box>
  );
};

export default PotentialTechnologiesPostsTable;
