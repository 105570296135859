import { StatusEnum } from '../../core/enums';

export const GET_POSTS_STATISTIC: string = 'GET_POSTS_STATISTIC';
export const GET_RECEIVED_STATISTIC: string = 'GET_RECEIVED_STATISTIC';
export const SET_STATISTIC_STORE_STATUS: string = 'SET_STATISTIC_STORE_STATUS';
export const SET_RECEIVED_STATISTIC_DATES: string =
  'SET_RECEIVED_STATISTIC_DATES';
export const SET_POSTS_STATISTIC_DATES: string = 'SET_POSTS_STATISTIC_DATES';

export interface StatisticState {
  getReceivedStatus: StatusEnum;
  getPostsStatus: StatusEnum;
  receivedStatistic: any[];
  fromReceivedDate: string | null;
  toReceivedDate: string | null;
  fromPostDate: string | null;
  toPostDate: string | null;
  postsStatistic: {
    leadgens: any[];
    statusGroups: any[];
  };
  error: any | null;
}

export interface SetReceivedDatesAction {
  type: typeof SET_RECEIVED_STATISTIC_DATES;
  payload: SetReceivedDatesActionPayload;
}
export interface SetReceivedDatesActionPayload {
  fromReceivedDate: string | null;
  toReceivedDate: string | null;
}

export interface SetPostsDatesAction {
  type: typeof SET_POSTS_STATISTIC_DATES;
  payload: SetPostsDatesActionPayload;
}
export interface SetPostsDatesActionPayload {
  fromPostDate: string | null;
  toPostDate: string | null;
}
