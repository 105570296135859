import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FilterDownIcon, FilterUpIcon } from '../icons';

const SortArrowButton = ({
  isShow,
  isAcs,
  onChange,
  index,
  currentIndex,
}: any) => {
  const handleChange = (event: any) => {
    event.stopPropagation();

    onChange(isAcs, currentIndex, index);
  };
  return isShow ? (
    <IconButton
      size='sm'
      bg='transparent'
      aria-label='sort-icon'
      onClick={handleChange}
      icon={
        isAcs ? (
          <FilterUpIcon fill='#5755FF' />
        ) : (
          <FilterDownIcon fill='#5755FF' />
        )
      }
    />
  ) : (
    <IconButton
      onClick={handleChange}
      size='sm'
      bg='transparent'
      aria-label='sort-icon'
    />
  );
};

export default SortArrowButton;
