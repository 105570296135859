import {
  getCommentsAction,
  setCommentStoreStatusAction,
} from '../actions';
import CommentService from '../../../services/comment.service';
import { Dispatch } from 'redux';
import { RootStore } from '../..';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';
import localStorageService from '../../../services/local-storage.service';
import moment from 'moment';

const addCommentThunk = (comment: any): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setCommentStoreStatusAction({
          addCommentStatus: StatusEnum.running,
          error: null,
        })
      );
      const response = await CommentService.addComment(comment);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          setCommentStoreStatusAction({ addCommentStatus: StatusEnum.success })
        );
        const user = localStorageService.getUser();
        const dateCreated = moment.utc().local().toISOString();
        let newComments = getState().commentStore.comments;
        newComments.push({
          id: response.data,
          userId: user.id,
          userName: user.firstName,
          jobId: comment.jobId,
          message: comment.message,
          messageType: comment.messageType ?? 0,
          dateCreated: dateCreated,
        });
        dispatch(getCommentsAction({ comments: newComments }));
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setCommentStoreStatusAction({
          addCommentStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default addCommentThunk;
