import React from 'react';
import { Flex, Stack, Link } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import { SignInRoute } from '../constants/routes';
import Footer from '../components/Footer';
import SuccessForgotPassword from '../components/SuccessForgotPassword';

const SuccessForgotPasswordContainer = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const email = params.email as string;
  return (
    <>
      <Flex width='100%' height='40px' align={'center'} justify={'end'}>
        <Link
          pr='25px'
          color={'#5755FF'}
          onClick={() => {
            navigate(SignInRoute);
          }}
        >
          Sign in
        </Link>
      </Flex>
      <Flex
        minH={'calc(100vh - 40px - 96px)'}
        align={'center'}
        justify={'center'}
      >
        <Stack spacing={4} w={'full'} maxW={'md'} p={6} my={12}>
          <SuccessForgotPassword email={email} />
        </Stack>
      </Flex>

      <Footer />
    </>
  );
};

export default SuccessForgotPasswordContainer;
