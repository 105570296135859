import React, { useEffect, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  SkeletonCircle,
  Skeleton,
  Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { getChannelStoreSelector } from '../../store/channel/selector';
import NavItem from '../../components/sidebar/NavItem';
import localStorageService from '../../services/local-storage.service';
import { UserRoleEnum } from '../../core/enums';
import ChannelNavItem from './ChannelNavItem';
import SecondNavItem from './SecondNavItem';
import getGroupChannelsThunk from '../../store/channel/thunk/get-by-status-group';
import getGroupCountThunk from '../../store/channel/thunk/get-group-count';
import { useLocation } from 'react-router-dom';
import { KanbanIcon } from '../../icons/KanbanIcon';
import CONFIG from '../../config/config';

// const differences = [12, 2, 0, 222, 1, 1, 0];

const MenuList = ({ expanded, items, differences }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    channels,
    status,
    groupChannels,
    groupChannelsTotal,
    statusGroups,
    groupChannelsStatus,
  } = useSelector(getChannelStoreSelector);

  const [defaultOpenedType, setOpenedDashboardType] = useState(
    location.pathname.includes('new') ? 0 : 1
  );

  const handleGetChannels = (isExpanded: boolean, type: number) => {
    dispatch(getGroupChannelsThunk(type));
  };
  const loading = status === 'running';
  const groupCountLoading = groupChannelsStatus === 'running';
  const user = localStorageService.getUser();
  const redirectToCanban = () =>{
    window.open(CONFIG.KANBAN_URL, '_blank');
  }
  useEffect(() => {
    dispatch(getGroupCountThunk());
  }, [dispatch]);

  useEffect(() => {
    if (defaultOpenedType === 0) {
      handleGetChannels(true, 1);
    }
    if (defaultOpenedType === 1) {
      handleGetChannels(true, 2);
    }
  }, [defaultOpenedType]);

  return (
    <Accordion allowToggle allowMultiple defaultIndex={[0]}>
      {items.map((link: any, index: any) => {
        if (link.roles.some((role: UserRoleEnum) => role === user?.role) 
            || link.roles.some((role: UserRoleEnum) => role === UserRoleEnum.None)) {
          return (
            <AccordionItem border='none' key={link.id}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton padding='0px'>
                    <Box flex='1' textAlign='left'>
                      <NavItem
                        path={link.path}
                        activePath={link.activePath}
                        icon={link.icon}
                        isExpandable={link.links.length > 0}
                        expandedMenu={expanded}
                      >
                        {link.name}
                      </NavItem>
                    </Box>
                  </AccordionButton>

                  <AccordionPanel pb={4} padding='0px'>
                    <Accordion
                      allowToggle
                      allowMultiple
                      defaultIndex={
                        location.pathname.includes('new') ||
                        location.pathname.includes('old')
                          ? defaultOpenedType
                          : undefined
                      }
                    >
                      {expanded
                        ? link.links.map((secondLink: any, index: any) => {
                            if (secondLink.isExpandable) {
                              return (
                                <AccordionItem
                                  key={secondLink.id}
                                  border='none'
                                >
                                  {({ isExpanded }) => (
                                    <>
                                      <AccordionButton
                                        padding='0px'
                                        onClick={() => {
                                          handleGetChannels(
                                            isExpanded &&
                                              secondLink.isExpandable,
                                            index + 1
                                          );
                                        }}
                                      >
                                        <SecondNavItem
                                          path={secondLink.path}
                                          activePath={secondLink.activePath}
                                          isCount={secondLink.isCount}
                                          total={
                                            !groupCountLoading &&
                                            statusGroups[index + 1]?.count
                                          }
                                          difference={differences[index + 1]}
                                          isNavigate={secondLink.isNavigate}
                                          channels={channels}
                                        >
                                          {secondLink.name}
                                        </SecondNavItem>
                                      </AccordionButton>
                                      <AccordionPanel pb={4} padding='0px'>
                                        {secondLink.isExpandable && (
                                          <ChannelNavItem
                                            pl='60px'
                                            key={'All'}
                                            beforePath={secondLink.path}
                                            path={secondLink.path + '?page=1'}
                                            activePath={`${secondLink.path}`}
                                            isCount={true}
                                            isNavigate
                                            total={
                                              !groupCountLoading &&
                                              statusGroups[index + 1]?.count
                                            }
                                            channel={null}
                                          >
                                            All
                                          </ChannelNavItem>
                                        )}

                                        {loading && <ChannelsSkeleton />}
                                        {!loading && (
                                          <>
                                            {secondLink.isNavigate === false &&
                                              secondLink.isCount === true &&
                                              groupChannels?.map(
                                                (channel: any) => {
                                                  return (
                                                    <ChannelNavItem
                                                      pl='60px'
                                                      channel={channel}
                                                      key={
                                                        channel.id + Date.now()
                                                      }
                                                      beforePath={
                                                        secondLink.path
                                                      }
                                                      path={`${secondLink.path}?channel=${channel.id}`}
                                                      activePath={`${secondLink.path}?channel=${channel.id}`}
                                                      isCount={true}
                                                      isNavigate
                                                      total={channel.count}
                                                    >
                                                      {channel.title}
                                                    </ChannelNavItem>
                                                  );
                                                }
                                              )}
                                          </>
                                        )}
                                      </AccordionPanel>
                                    </>
                                  )}
                                </AccordionItem>
                              );
                            } else {
                              return (
                                <SecondNavItem
                                  key={secondLink.id}
                                  path={secondLink.path}
                                  activePath={secondLink.activePath}
                                  isCount={secondLink.isCount}
                                  total={
                                    !loading && statusGroups[index + 1]?.count
                                  }
                                  difference={differences[index + 1]}
                                  isNavigate={secondLink.isNavigate}
                                  channels={channels}
                                >
                                  {secondLink.name}
                                </SecondNavItem>
                              );
                            }
                          })
                        : null}
                    </Accordion>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        }
      })}
      <AccordionItem>
        <AccordionButton padding='0px'>
                      <Box flex='1' textAlign='left'>
                        <NavItem
                          path={'link.path'}
                          activePath={'link.activePath'}
                          icon={KanbanIcon}
                          isExpandable={false}
                          expandedMenu={expanded}
                          onClick={redirectToCanban}
                        >
                          {'Kanban'}
                        </NavItem>
                      </Box>
                    </AccordionButton>
        </AccordionItem>
    </Accordion>
  );
};

const ChannelsSkeleton = (): JSX.Element => {
  return (
    <Box padding='2px' pl='7px'>
      <Flex align='center' p='5px' pl={'60px'} role='group'>
        <SkeletonCircle size='9' mr='18px' />
        <Skeleton height='15px' width='80px' />
      </Flex>
      <Flex align='center' p='5px' pl={'60px'} role='group'>
        <SkeletonCircle size='9' mr='18px' />
        <Skeleton height='15px' width='80px' />
      </Flex>
      <Flex align='center' p='5px' pl={'60px'} role='group'>
        <SkeletonCircle size='9' mr='18px' />
        <Skeleton height='15px' width='80px' />
      </Flex>
    </Box>
  );
};

export default MenuList;
