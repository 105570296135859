import {
  Box,
  VStack,
  Textarea,
  Button,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../core/enums';
import Loading from './Loading';
import { getPostStoreSelector } from '../store/post/selector';
import generateAnswerThunk from '../store/post/thunk/generate-answer';
import useGenerateAnswerToast from '../hooks/useGenerateAnswerToasts';
import { PostModel } from '../models/post';
import { setGeneratedAnswerPostStoreAction } from '../store/post/actions';
import { useEffect, useState } from 'react';
interface GeneratedAnswerProps {
  postId?: string;
}

const GeneratedAnswer = ({ postId }: GeneratedAnswerProps): JSX.Element => {
  const dispatch = useDispatch();
  useGenerateAnswerToast();

  const { posts, generateAnswerStatus } = useSelector(
    getPostStoreSelector
  );
  const [answer, setAnswer] = useState('');
  useEffect(() => {
    const generatedAnswer: string = (posts as PostModel[]).find(post => post.id === postId)?.generatedAnswer ?? '';
    setAnswer(generatedAnswer);
  }, [postId, posts, generateAnswerStatus]);
  
  const handleChangeComment = (event: any) => {
    setAnswer(event.target.value);
    const postIndex = posts.findIndex((post: PostModel) => post.id === postId);
    if (postIndex !== -1) {
        posts[postIndex].generatedAnswer = event.target.value;
        dispatch(
            setGeneratedAnswerPostStoreAction({
                posts: posts
            })
        )
    }
  };

  const handleSubmit = () => {
    if(postId){
      dispatch(
        generateAnswerThunk(postId)
      );
    }
    const postIndex = posts.findIndex((post: PostModel) => post.id === postId);
    if (postIndex !== -1) {
        posts[postIndex].generatedAnswer = '';
        dispatch(
            setGeneratedAnswerPostStoreAction({
                posts: posts
            })
        )
    }
  };
  
  return (
    <Box padding='10px'>
      {generateAnswerStatus === StatusEnum.running && <Loading />}
      {generateAnswerStatus !== StatusEnum.running && (
        <>
          <VStack align='start'>
            <Textarea
              placeholder='Edit message...'
              minHeight={'300px'}
              value={answer}
              onChange={handleChangeComment}
              disabled={ generateAnswerStatus === StatusEnum.running ||
                answer.trim().length === 0}
            />
            <Button
              width='172px'
              isLoading={generateAnswerStatus === StatusEnum.running}
              disabled={
                generateAnswerStatus === StatusEnum.running
              }
              loadingText='Wait...'
              borderRadius='8px'
              bg='#5755FF'
              color='#ffffff'
              fontSize='15px'
              fontWeight='400'
              _hover={{
                bg: '#413fe3',
              }}
              _active={{
                bg: '#5755FF',
              }}
              onClick={handleSubmit}
            >
              { answer.trim().length === 0 ? 'Generate' : 'Re-Generate' }
            </Button>
          </VStack>
        </>
      )}
    </Box>
  );
};
export default GeneratedAnswer;
