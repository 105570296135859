import { RootStore } from '../index';

export const getAuthStoreSelector = (store: RootStore) => store.authStore;

export const getSignInStatus = (store: RootStore) =>
  store.authStore.signInStatus;
export const getForgotPasswordStatus = (store: RootStore) =>
  store.authStore.forgotPasswordStatus;
export const getResetPasswordStatus = (store: RootStore) =>
  store.authStore.resetPasswordStatus;
export const getErrorAuthStatus = (store: RootStore) => store.authStore.error;
