import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputRightElement,
  CheckboxGroup,
  Checkbox,
  Stack,
  Box,
  Text,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { AccountIcon, CloseEyeIcon, EmailIcon, OpenEyeIcon } from '../icons';
import FormErrorMessage from './FormErrorMessage';
import { StatusEnum, UserRoleEnum } from '../core/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStoreSelector } from '../store/user/selector';
import addUserThunk from '../store/user/thunk/add-user';
import { getChannelStoreSelector } from '../store/channel/selector';
import getAllChannelsThunk from '../store/channel/thunk/get-all-channels';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from '../validations';

interface AddUserPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddUserPopup = ({ isOpen, onClose }: AddUserPopupProps): JSX.Element => {
  const dispatch = useDispatch();
  const [accessChannels, setAccessChannels] = useState<any[]>([]);
  const [role, setRole] = useState<UserRoleEnum>(UserRoleEnum.Admin);

  const [showPassword, setShowPassword] = useState(false);

  const { addUserStatus, error } = useSelector(getUserStoreSelector);
  const { channels, status } = useSelector(getChannelStoreSelector);

  const handleChangeRole = (event: any) => {
    setRole(event.target.value);
  };

  const handleSubmit = (values: any) => {
    dispatch(addUserThunk(values));
  };

  useEffect(() => {
    if (addUserStatus === StatusEnum.success) {
      onClose();
    }
  }, [addUserStatus, onClose]);

  useEffect(() => {
    if (channels) {
      setAccessChannels(channels.map((channel: any) => channel.id));
    }
  }, [channels]);

  useEffect(() => {
    dispatch(getAllChannelsThunk());
  }, [dispatch]);

  return (
    <Modal size='lg' onClose={onClose} isOpen={isOpen} scrollBehavior='outside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Add New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
            }}
            onSubmit={(values, actions) => {
              handleSubmit({
                ...values,
                role: Number(role),
                accessChannelsIds: accessChannels,
              });
              actions.setSubmitting(false);
            }}
          >
            <Form>
              <Field name='firstName' validate={validateFirstName}>
                {({ field, form }: any) => (
                  <FormControl
                    id='firstName'
                    isInvalid={form.errors.firstName && form.touched.firstName}
                    marginBottom='30px'
                  >
                    <FormLabel>First Name</FormLabel>
                    <InputGroup>
                      <Input type='textr' {...field} />
                      <InputRightElement h={'full'}>
                        <AccountIcon />
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.firstName && (
                      <FormErrorMessage message={form.errors.firstName} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='lastName' validate={validateLastName}>
                {({ field, form }: any) => (
                  <FormControl
                    id='lastName'
                    isInvalid={form.errors.lastName && form.touched.lastName}
                    marginBottom='30px'
                  >
                    <FormLabel>Last Name</FormLabel>
                    <InputGroup>
                      <Input type='textr' {...field} />
                      <InputRightElement h={'full'}>
                        <AccountIcon />
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.lastName && (
                      <FormErrorMessage message={form.errors.lastName} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='email' validate={validateEmail}>
                {({ field, form }: any) => (
                  <FormControl
                    id='email'
                    isInvalid={form.errors.email && form.touched.email}
                    marginBottom='30px'
                  >
                    <FormLabel>Email</FormLabel>
                    <InputGroup>
                      <Input type='email' {...field} />
                      <InputRightElement h={'full'}>
                        <EmailIcon />
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.email && (
                      <FormErrorMessage message={form.errors.email} />
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name='password' validate={validatePassword}>
                {({ field, form }: any) => (
                  <FormControl
                    id='password'
                    isInvalid={form.errors.password && form.touched.password}
                    marginBottom='30px'
                  >
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        {...field}
                      />
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {form.errors.password && (
                      <FormErrorMessage message={form.errors.password} />
                    )}
                  </FormControl>
                )}
              </Field>

              <FormControl id='role' marginBottom='30px'>
                <FormLabel>Role</FormLabel>
                <Select value={role} onChange={handleChangeRole}>
                  <option value={UserRoleEnum.Admin}>Admin</option>
                  <option value={UserRoleEnum.LeadGen}>LeadGen</option>
                  <option value={UserRoleEnum.Sales}>Sales</option>
                </Select>
              </FormControl>

              {+role === UserRoleEnum.LeadGen && (
                <Field type='checkbox' name='accessChannels'>
                  {({ field, form }: any) => (
                    <Box mb='20px'>
                      <Text>Access</Text>
                      <CheckboxGroup
                        colorScheme='green'
                        defaultValue={accessChannels}
                        onChange={setAccessChannels}
                      >
                        <SimpleGrid columns={2} spacing={4}>
                          {channels.map((ch: any) => {
                            return (
                              <Checkbox key={ch.id} value={ch.id}>
                                {ch.title}
                              </Checkbox>
                            );
                          })}
                        </SimpleGrid>
                      </CheckboxGroup>
                    </Box>
                  )}
                </Field>
              )}

              <Wrap spacing='30px' justify='center' mb='25px'>
                <WrapItem>
                  <Button
                    border='1px solid #413fe3'
                    borderRadius='8px'
                    bg='transparent'
                    color='#413fe3'
                    fontSize='15px'
                    fontWeight='400'
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    borderRadius='8px'
                    isLoading={addUserStatus === StatusEnum.running}
                    loadingText='Wait...'
                    bg='#5755FF'
                    color='#ffffff'
                    fontSize='15px'
                    fontWeight='400'
                    _hover={{
                      bg: '#413fe3',
                    }}
                    _active={{
                      bg: '#5755FF',
                    }}
                    type='submit'
                  >
                    Save
                  </Button>
                </WrapItem>
              </Wrap>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddUserPopup;
