import { StatusGroupEnum } from '../core/enums';
import { apiUrl } from '../utils';

// customers
export const CustomersAPI = (): string => apiUrl('/customers');
export const CustomerAPI = (id: string): string => apiUrl(`/customers/${id}`);
export const CreateCustomerAPI = (): string => apiUrl('/customers');

// auth
export const SignInAPI = (): string => apiUrl('/Account/SignIn');
export const ForgotPasswordAPI = (email: string): string =>
  apiUrl(`/Account/ForgotPassword?email=${email}`);
export const ResetPasswordAPI = (): string => apiUrl('/Account/ResetPassword');

export const makeAPIWithQueries = (base: string, queries: string) => {
  return `${base}?${queries}`;
};

// post
export const PostsApi = (): string => apiUrl('/Job/GetByFilter');
export const GetPostsStatisticByFilter = (): string =>
  apiUrl('/Job/GetPostsStatisticByFilter');
export const GetReceivedStatisticByFilter = (): string =>
  apiUrl('/Job/GetRecievedStatisticByFilter');
export const TransferPostApi = (): string => apiUrl(`/Job/TransferJob`);
export const DeclinePostApi = (postId: string): string =>
  apiUrl(`/Post/${postId}/Decline`);
export const TechSupportPostApi = (postId: string): string =>
  apiUrl(`/Post/${postId}/TechSupport`);
export const AnswerReceivedPostApi = (postId: string): string =>
  apiUrl(`/Post/${postId}/AnswerReceived`);
export const SqlPostApi = (postId: string): string =>
  apiUrl(`/Post/${postId}/Sql`);
export const AddCommentToPostApi = (postId: string): string =>
  apiUrl(`/Post/${postId}`);
export const GetJobResponseMessageApi = (postId: string): string =>
  apiUrl(`/Job/GetJobResponseMessage?id=${postId}`);
// User
export const UserCreateApi = (): string => apiUrl('/User/Create');
export const UsersApi = (): string => apiUrl('/User/GetAll');
export const UsersGetAllLeadGensApi = (): string => apiUrl('/User/GetAllLeadGens');
export const UserByIdApi = (userId: string): string =>
  apiUrl(`/User/GetById?id=${userId}`);
export const UpdateUserApi = (): string => apiUrl(`/User/Update`);
export const UpdateProfileApi = (): string => apiUrl(`/User/UpdateProfile`);
export const InactivateUserApi = (userId: string): string =>
  apiUrl(`/User/Inactivate?id=${userId}`);

export const ActivateUserApi = (userId: string): string =>
  apiUrl(`/User/Activate?id=${userId}`);
// Channel
export const ChannelGetAllApi = (): string => apiUrl('/Channel/GetAll');
export const AddChannelApi = (): string => apiUrl('/Channel/Create');
export const UpdateChannelApi = (): string => apiUrl(`/Channel/Update`);
export const ChannelGroupsCountApi = (): string =>
  apiUrl('/Channel/GetGroupsCount');
export const ChannelsApi = (type: StatusGroupEnum): string =>
  apiUrl(`/Channel/Get?type=${type}`);

export const GetByStatusGroupApi = (type: StatusGroupEnum): string =>
  apiUrl(`/Channel/GetByStatusGroup?type=${type}`);

// Comment
export const GetCommentsById = (postId: string): string =>
  apiUrl(`/Comment/GetAllById?id=${postId}`);
export const AddCommentApi = (): string => apiUrl('/Comment/Create');

// TechStack
export const GetTechStacks = (): string =>
  apiUrl(`/TechStack/GetAll`);
