import axiosInstance from '../api';
import {
  ActivateUserApi,
  InactivateUserApi,
  UpdateProfileApi,
  UpdateUserApi,
  UserByIdApi,
  UserCreateApi,
  UsersApi,
  UsersGetAllLeadGensApi,
} from '../constants/api';

class UserService {
  getUsers = async () => {
    return await axiosInstance.get(UsersApi(), {});
  };
  getUserById = async (userId: string) => {
    return await axiosInstance.get(UserByIdApi(userId), {});
  };
  getAllLeadGens = async () => {
    return await axiosInstance.get(UsersGetAllLeadGensApi(), {});
  };
  addUser = async (payload: any) => {
    return await axiosInstance.post(UserCreateApi(), payload);
  };
  updateUser = async (user: any) => {
    return await axiosInstance.put(UpdateUserApi(), user);
  };
  updateProfile = async (user: any) => {
    return await axiosInstance.put(UpdateProfileApi(), user);
  };
  inactivateUser = async (userId: string) => {
    return await axiosInstance.delete(InactivateUserApi(userId), {});
  };
  activateUser = async (userId: string) => {
    return await axiosInstance.post(ActivateUserApi(userId), {});
  };
}

export default new UserService();
