import moment from 'moment';
import { escape, unescape } from 'querystring';

import { StatusGroupEnum, UserRoleEnum } from '../core/enums';

export function apiUrl(path: string): string {
  if (!path) {
    throw new Error('Url is not defined');
  }

  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  return `/api/${path}`;
}

export function getTimeOrDate(date: string): string {
  var post = moment.utc(date).local(); //now
  var now = moment().utc();

  const min = now.diff(post, 'minutes');
  const hours = now.diff(post, 'hours');
  if (min <= 59) {
    return `${min} min`;
  } else if (min > 59 && hours < 2) {
    return `${hours} hours`;
  } else if (hours >= 2 && hours <= 5) {
    return post.format('HH:mm');
  } else {
    return post.format('DD-MM-YYYY,T HH:mm');
  }
}

export function getFormatedDate(date: string): string {
  let stat = moment.utc(date).local().format('ddd DD/MM/YYYY');
  return stat.toUpperCase();
}

export function getPostDetailsDate(date?: string): string {
  const postDate = moment.utc(date).local().format('ddd, MMM DD YYYY ');

  const postTime = moment.utc(date).local().format(' HH:mm');
  return postDate + '-' + postTime;
}
export function getTimeColor(date: string): string {
  const timeColors = [
    'rgba(19, 191, 50, 0.3)',
    'rgba(19, 191, 50, 0.15)',
    'rgba(255, 127, 0, 0.05)',
    'rgba(255, 127, 0, 0.15)',
  ];

  var post = moment.utc(date).local(); //now
  var now = moment();
  const min = now.diff(post, 'minutes');
  if (min <= 3 && min >= 0) {
    return timeColors[0];
  }
  if (min > 3 && min < 30) {
    return timeColors[1];
  }
  if (min >= 30 && min <= 59) {
    return timeColors[2];
  }
  if (min > 59) {
    return timeColors[3];
  } else {
    return timeColors[3];
  }
}

export function getRole(role: UserRoleEnum): string {
  if (role === UserRoleEnum.Admin) {
    return 'Admin';
  }
  if (role === UserRoleEnum.LeadGen) {
    return 'LeadGen';
  }
  if (role === UserRoleEnum.Sales) {
    return 'Sales';
  } else {
    return 'User';
  }
}

export function getCommentTime(date: string) {
  const commentDate = moment.utc(date).local().format('DD MMM, YYYY ');

  const commentTime = moment.utc(date).local().format(' HH:mm');
  return commentDate + 'at' + commentTime;
}

export function removeHtmlTagsFromString(value: string) {
  // return value.replace(/<[^>]*>?/gm, '');
  const newString = decodeEntity(value);
  return newString.replace(/<\/?[^>]+(>|$)/g, '');
}
export function sleep(timeout: number) {
  return new Promise((res: any) =>
    setTimeout(() => {
      res();
    }, timeout)
  );
}

export function isValidUrl(url: string) {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}
function decodeEntity(inputStr: string) {
  var textarea = document.createElement('textarea');
  textarea.innerHTML = inputStr;
  return textarea.value;
}

export function isValidEmail(email: string) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
export function removeQuotesFromString(value: string) {
  return value.replace(/"/g, '');
}
