import React, { useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  BoxProps,
  useMediaQuery,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconType } from 'react-icons';

import {
  DashboardIcon,
  SettingsIcon,
  StatisticIcon,
  UserManagementIcon,
} from '../../icons';
import MenuList from './MenuList';
import { UserRoleEnum } from '../../core/enums';
import {
  DashboardAnswersRoute,
  DashboardBiddedRoute,
  DashboardDeclinedRoute,
  DashboardNewRoute,
  DashboardOldRoute,
  DashboardTechSupportRoute,
  SettingsChannelsRoute,
  StatisticRoute,
  UserManagementRoute,
  DashboardPotentialTechnologiesRoute,
} from '../../constants/routes';
import localStorageService from '../../services/local-storage.service';
import { useSelector } from 'react-redux';
import { getChannelStoreSelector } from '../../store/channel/selector';

interface LinkItemProps {
  id: string;
  name: string;
  icon: IconType;
  path: string;
  activePath: string;
  links: Array<InsideLinkItemProps>;
  roles: UserRoleEnum[];
}

interface InsideLinkItemProps {
  id: string;
  name: string;
  path: string;
  activePath: string;
  isNavigate: boolean;
  isExpandable: boolean;
  isCount: boolean;
}

const LinkItems: Array<LinkItemProps> = [
  {
    id: 'dashboard-link',
    name: 'Dashboard',
    icon: DashboardIcon,
    path: DashboardNewRoute,
    activePath: '/dashboard',
    roles: [UserRoleEnum.None],
    links: [
      {
        id: 'dashboard-new-link',
        name: 'New Posts',
        path: DashboardNewRoute,
        activePath: '/dashboard/new',
        isNavigate: false,
        isExpandable: true,
        isCount: true,
      },
      {
        id: 'dashboard-old-link',
        name: 'Old Posts',
        path: DashboardOldRoute,
        activePath: '/dashboard/old',
        isNavigate: false,
        isExpandable: true,
        isCount: true,
      },
      {
        id: 'dashboard-potential-technologies-link',
        name: 'Potential Technologies',
        path: DashboardPotentialTechnologiesRoute,
        activePath: '/dashboard/potential-technologies',
        isNavigate: false,
        isExpandable: true,
        isCount: true,
      },
      {
        id: 'dashboard-bidded-link',
        name: 'Bidded Posts',
        path: DashboardBiddedRoute,
        activePath: '/dashboard/bidded',
        isNavigate: true,
        isExpandable: false,
        isCount: true,
      },
      {
        id: 'dashboard-declined-link',
        name: 'Declined Posts',
        path: DashboardDeclinedRoute,
        activePath: '/dashboard/declined',
        isNavigate: true,
        isExpandable: false,
        isCount: true,
      },
      {
        id: 'dashboard-tech-support-required-link',
        name: 'Tech Support Required',
        path: DashboardTechSupportRoute,
        activePath: '/dashboard/tech-support',
        isNavigate: true,
        isExpandable: false,
        isCount: true,
      },
      {
        id: 'dashboard-answer-received-link',
        name: 'Answer Received',
        path: DashboardAnswersRoute,
        activePath: '/dashboard/answer-received',
        isNavigate: true,
        isExpandable: false,
        isCount: true,
      },
      {
        id: 'dashboard-sql-link',
        name: 'SQL',
        path: '/dashboard/sql',
        activePath: '/dashboard/sql',
        isNavigate: true,
        isExpandable: false,
        isCount: true,
      },
    ],
  },
  {
    id: 'user-management-link',
    name: 'User Management',
    icon: UserManagementIcon,
    path: UserManagementRoute,
    activePath: '/user-management',
    roles: [UserRoleEnum.Admin, UserRoleEnum.Sales],
    links: [],
  },
  {
    id: 'statistic-link',
    name: 'Statistic',
    icon: StatisticIcon,
    path: StatisticRoute,
    activePath: '/statistic',
    roles: [UserRoleEnum.None],
    links: [],
  },
  {
    id: 'settings-link',
    name: 'Settings',
    icon: SettingsIcon,
    path: SettingsChannelsRoute,
    activePath: '/settings',
    roles: [UserRoleEnum.Admin, UserRoleEnum.Sales],
    links: [
      {
        id: 'settings-channels-link',
        name: 'Channels',
        path: SettingsChannelsRoute,
        activePath: '/settings/channels',
        isNavigate: true,
        isExpandable: false,
        isCount: false,
      },
    ],
  },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const [expanded, setExpanded] = useState<boolean>(
    localStorageService.getExpandMenu()
  );
  const [isSmallThen768] = useMediaQuery('(max-width: 767px)');

  const { differences } = useSelector(getChannelStoreSelector);

  const handleExpanded = () => {
    setExpanded(!expanded);
    localStorageService.setExpandMenu(!expanded);
  };

  return (
    <Box
      transition='width 1s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: expanded ? '300px' : '60px' }}
      h={{ base: '100vh', md: 'calc(100vh - 60px)' }}
      {...rest}
    >
      <Flex
        h='20'
        alignItems='center'
        mx='8'
        justifyContent='end'
        display={{ base: 'flex', md: 'none' }}
      >
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Flex
        flexDirection='column'
        justifyContent='space-between'
        h='calc(100vh - 60px)'
      >
        <Box overflow='hidden' overflowY='auto'>
          <MenuList
            expanded={isSmallThen768 ? true : expanded}
            items={LinkItems}
            differences={differences}
          />
        </Box>
        <Flex
          alignItems='center'
          justifyContent='flex-end'
          display={{ base: 'none', md: 'flex' }}
          onClick={handleExpanded}
        >
          <IconButton
            bg='transparent'
            aria-label='minimize sidebar button'
            icon={
              expanded ? (
                <ChevronLeftIcon fontSize='22px' />
              ) : (
                <ChevronRightIcon fontSize='22px' />
              )
            }
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default SidebarContent;
