import {
  ChannelState,
  GET_CHANNELS,
  GET_GROUP_CHANNELS,
  GET_GROUP_CHANNELS_COUNT,
  SET_CHANNEL_STORE_STATUS,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_STATUS,
  UPDATE_DIFFERENCES,
} from './types';
import { initialState } from './store';
import { ActionType } from '../index';

const channelReducer = (
  state: ChannelState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case SET_CHANNEL_STORE_STATUS:
      return { ...state, ...action.payload };
    case GET_CHANNELS:
      return { ...state, channels: action.payload.channels };
    case GET_GROUP_CHANNELS:
      return {
        ...state,
        groupChannels: action.payload.groupChannels,
        groupChannelsTotal: action.payload.groupChannelsTotal,
      };
    case UPDATE_DIFFERENCES:
      return { ...state, differences: action.payload.differences };
    case GET_GROUP_CHANNELS_COUNT:
      return {
        ...state,
        statusGroups: action.payload.statusGroups,
      };
    case UPDATE_CHANNEL:
      return {
        ...state,
        channels: state.channels.map((channel) => {
          if (channel.id === action.payload.channelId) {
            return { ...channel, ...action.payload.channel };
          }
          return channel;
        }),
      };
    case UPDATE_CHANNEL_STATUS:
      return {
        ...state,
        channels: state.channels.map((channel) => {
          if (channel.id === action.payload.channelId) {
            return { ...channel, isActive: action.payload.status };
          }
          return channel;
        }),
      };
    default:
      return state;
  }
};

export default channelReducer;
