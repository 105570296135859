import React from 'react';
import SidebarWithHeader from '../layouts/sidebar';
import DashboardOldContainer from '../containers/dashboard-old';
import InternetConnectionWrapper from '../layouts/internet-connection-wrapper';

const DashboardOldPage = (): JSX.Element => {
  return (
    <SidebarWithHeader>
      <InternetConnectionWrapper>
        <DashboardOldContainer />
      </InternetConnectionWrapper>
    </SidebarWithHeader>
  );
};

export default DashboardOldPage;
