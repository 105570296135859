import axiosInstance from '../api';
import {
  AddCommentToPostApi,
  GetJobResponseMessageApi,
  GetPostsStatisticByFilter,
  GetReceivedStatisticByFilter,
  PostsApi,
  TransferPostApi,
} from '../constants/api';
import { StatusGroupEnum } from '../core/enums';

interface getPostsProps {
  from?: string;
  to?: string;
  page: number;
  take: number;
  orderColumn: number;
  statusGroup: StatusGroupEnum;
  channelId?: string | null;
  isAcs: boolean;
  search: string;
}

interface TransferPostProps {
  sourceStatusGroup: StatusGroupEnum;
  destinationStatusGroup: StatusGroupEnum;
  jobId: string;
  channelId: string;
  shouldRemoveFromSource?: boolean;
  reasonType?: number;
  reasonMessage?: string;
  clientInfo?: ClientInfoTransferPostProps;
}

interface ClientInfoTransferPostProps {
  name: string;
  agency: string;
  rate: number;
  techStacks: string[];
}

interface GetReceivedStatisticByFilterProps {
  from: string;
  to: string;
  leadGenIds: string[];
}

interface GetPostsStatisticByFilterProps {
  from: string;
  to: string;
}

class PostService {
  getPosts = async (options: getPostsProps) => {
    return await axiosInstance.post(PostsApi(), options);
  };
  getReceivedStatisticByFilter = async (
    options: GetReceivedStatisticByFilterProps
  ) => {
    return await axiosInstance.post(GetReceivedStatisticByFilter(), options);
  };
  getPostsStatisticByFilter = async (
    options: GetPostsStatisticByFilterProps
  ) => {
    return await axiosInstance.post(GetPostsStatisticByFilter(), options);
  };
  transferPost = async (options: TransferPostProps) => {
    return await axiosInstance.post(TransferPostApi(), options);
  };
  addCommentToPost = async (postId: string, comment: string) => {
    return await axiosInstance.post(AddCommentToPostApi(postId), comment);
  };
  getJobResponseMessage = async (postId: string) => {
    return await axiosInstance.get(GetJobResponseMessageApi(postId));
  };
  
}

export default new PostService();
