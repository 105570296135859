import React from 'react';
import { Icon } from '@chakra-ui/react';

export const ChevronDownIcon = (props: any) => (
  <Icon width='14px' height='8px' viewBox='0 0 14 8' fill='#758391' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8631 0L14 1.11781L7 8L0 1.11781L1.13694 0L7 5.76438L12.8631 0Z'
    />
  </Icon>
);
