import axiosInstance from '../api';
import {
  AddChannelApi,
  ChannelsApi,
  UpdateChannelApi,
  ChannelGetAllApi,
  GetByStatusGroupApi,
  ChannelGroupsCountApi,
} from '../constants/api';
import { StatusGroupEnum } from '../core/enums';
import { ChannelModel, CreateChannelModel } from '../models/channel';

class ChannelService {
  addChannel = async (channel: CreateChannelModel) => {
    return axiosInstance.post(AddChannelApi(), channel);
  };
  getAllChannels = async () => {
    return await axiosInstance.get(ChannelGetAllApi(), {});
  };
  getChannels = async (channelType: StatusGroupEnum) => {
    return await axiosInstance.get(ChannelsApi(channelType), {});
  };
  getStatusGroupCount = async () => {
    return await axiosInstance.get(ChannelGroupsCountApi(), {});
  };
  getByStatusGroup = async (type: StatusGroupEnum) => {
    return await axiosInstance.get(GetByStatusGroupApi(type), {});
  };

  updateChannel = async (channel: ChannelModel) => {
    return await axiosInstance.put(UpdateChannelApi(), channel);
  };
}

export default new ChannelService();
