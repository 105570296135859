import React from 'react';
import { useColorModeValue, Text, Flex, Icon } from '@chakra-ui/react';

import { CalendarIcon, ChevronDownIcon } from '../icons';
import moment from 'moment';
import { IconType } from 'react-icons';

interface Props {
  from: Date | null;
  to: Date;
  icon?: IconType;
}

const DateField = ({ icon, from, to }: Props) => {
  const color = useColorModeValue('#09101D', '#ffffff');
  const bg = useColorModeValue('#FFFFFF', '#252525');

  const fromDate = from ? moment(from).format('DD/MM/YYYY') : null;
  const toDate = moment(to).format('DD/MM/YYYY');
  return (
    <Flex
      bg={bg}
      borderRadius='8px'
      padding='12px 16px'
      justify='space-between'
      align='center'
    >
      <Flex align='center'>
        {icon && <Icon mr='4' fontSize='16' as={icon} />}
        <Text color={color} fontSize='16px' fontWeight='400' lineHeight='24px'>
          {from != null ? `${fromDate} - ${toDate}` : `${toDate}`}
        </Text>
      </Flex>
      <ChevronDownIcon />
    </Flex>
  );
};

export default DateField;
