import {
  setUserStoreStatusAction,
} from '../actions';
import UserService from '../../../services/user.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';

const inactivateUserThunk = (userId: string): any => {
  console.log(userId);
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({ inactivateUserStatus: StatusEnum.running })
      );

      const response = await UserService.inactivateUser(userId);
      if (response.status === 200 || response.status === 201) {
        dispatch(
          setUserStoreStatusAction({ inactivateUserStatus: StatusEnum.success })
        );
      }
      dispatch(setUserStoreStatusAction({ status: StatusEnum.success }));
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          inactivateUserStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default inactivateUserThunk;
