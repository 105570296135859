import {
  getUsersAction,
  setUserStoreStatusAction,
  updateUserAction,
} from '../actions';
import ChannelService from '../../../services/channel.service';

import { RootStore } from '../..';
import { Dispatch } from 'redux';
import { StatusEnum } from '../../../core/enums';
import { AxiosError } from 'axios';
import { sleep } from '../../../utils';
import userService from '../../../services/user.service';

const updateUserThunk = (user: any): any => {
  return async (dispatch: Dispatch, getState: () => RootStore) => {
    try {
      dispatch(
        setUserStoreStatusAction({ updateUserStatus: StatusEnum.running })
      );

      const channels = getState().channelStore.channels;

      let removeAccessChannelsIds: any = [];
      channels.forEach((ch: any) => {
        if (!user.accessChannelsIds.includes(ch.id)) {
          removeAccessChannelsIds.push(ch.id);
        }
      });
      console.log('accessChannelsIds: ', user.accessChannelsIds);
      console.log('removeAccessChannelsIds: ', removeAccessChannelsIds);

      const response = await userService.updateUser({
        ...user,
        removeAccessChannelsIds: removeAccessChannelsIds,
      });
      if (response.status === 200 || response.status === 201) {
        const newUsers = getState().userStore.users.map((u: any) => {
          if (u.id === user.id) {
            return { ...u, ...user };
          }
          return u;
        });
        dispatch(
          setUserStoreStatusAction({ updateUserStatus: StatusEnum.success })
        );
        dispatch(getUsersAction({ users: newUsers }));
      }
    } catch (error: AxiosError | any) {
      dispatch(
        setUserStoreStatusAction({
          updateUserStatus: StatusEnum.error,
          error: error.response.data,
        })
      );
    }
  };
};

export default updateUserThunk;
