import React from 'react';
import { Flex, Heading, Stack, Text, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { SignInRoute } from '../constants/routes';
import Footer from '../components/Footer';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import useForgotPasswordToasts from '../hooks/useForgotPasswordToast';

const ForgotPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();

  const { setEmail } = useForgotPasswordToasts();
  return (
    <>
      <Flex width='100%' height='40px' align={'center'} justify={'end'}>
        <Link
          pr='25px'
          color={'#5755FF'}
          onClick={() => {
            navigate(SignInRoute);
          }}
        >
          Sign in
        </Link>
      </Flex>
      <Flex
        minH={'calc(100vh - 40px - 96px)'}
        align={'center'}
        justify={'center'}
      >
        <Stack spacing={4} w={'full'} maxW={'md'} p={6} my={12}>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: '2xl', md: '3xl' }}
            textAlign='center'
          >
            Forgot password?
          </Heading>
          <Text color='#758391' fontSize='16px' lineHeight='24px'>
            Enter your email and we will send you a recovery link.
          </Text>
          <ForgotPasswordForm setForgotPasswordEmail={setEmail} />
        </Stack>
      </Flex>
      <Footer />
    </>
  );
};

export default ForgotPasswordContainer;
