import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface ContentLayoutProps {
  title: string;
  headerChildren?: React.ReactNode;
  children?: React.ReactNode;
}

const ContentLayout = ({
  title,
  headerChildren,
  children,
}: ContentLayoutProps): JSX.Element => {
  return (
    <Box>
      <Box mb='30px'>
        <Flex
          alignItems='center'
          justifyContent='space-between'
          flexWrap='wrap'
        >
          <Text fontSize='24px' lineHeight='30px' fontWeight='bold'>
            {title}
          </Text>
          <Box>{headerChildren}</Box>
        </Flex>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default ContentLayout;
